import _get from "lodash/get";
import _isPlainObject from "lodash/isPlainObject";
import _map from "lodash/map";
import React, { useEffect, useMemo } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components";
import AuCommodities from "../components/AuCommodities";
import AuCreditSpreadCfds from "../components/AuCreditSpreadCfds";
import AuForex from "../components/AuForex";
import AuIndices from "../components/AuIndices";
import AuPreciousMetals from "../components/AuPreciousMetals";
import AuShareCfds from "../components/AuShareCfds";
import BackgroundBelt from "../components/BackgroundBelt";
import BullionPreciousMetals from "../components/BullionPreciousMetals";
import Layout from "../components/Layout";
import Text from "../components/Text";
import useResponsive from "../hooks/useResponsive";
import useSubsidiary from "../hooks/useSubsidiary";
import useTranslate from "../hooks/useTranslate";

const auTabMap = new Map();
auTabMap.set("forex", <AuForex />);
auTabMap.set("precious_metals", <AuPreciousMetals />);
auTabMap.set("commodities", <AuCommodities />);
auTabMap.set("indices", <AuIndices />);
auTabMap.set("share_cfds", <AuShareCfds />);
auTabMap.set("credit_spread_cfds", <AuCreditSpreadCfds />);

const bullionTabMap = new Map();
bullionTabMap.set("precious_metals", <BullionPreciousMetals />);

const subsidiaryTabMap = new Map();
subsidiaryTabMap.set("au", auTabMap);
subsidiaryTabMap.set("bullion", bullionTabMap);

const StyledNavLink = styled(Nav.Link)`
  color: initial;
  &.active,
  &:hover {
    color: ${({ activecolor = "info", theme }) =>
      theme[activecolor]} !important;
  }
`;

const ProductPage = ({ className = "", ...props }) => {
  const { push } = useHistory();
  const { tab } = useParams();
  const { subsidiary } = useSubsidiary();
  const { config, language } = useTranslate({ configKey: "product" });
  const { isDesktopOrLaptop } = useResponsive();

  const {
    banner: bannerProps,
    belt: beltProps,
    header: headerProps,
    helmet: helmetProps,
    tabs: tabsConfig,
  } = config || {};

  const tabSet = useMemo(
    () =>
      new Set(
        _map(tabsConfig, ({ value }) => {
          const { eventKey } = _isPlainObject(value)
            ? value
            : { eventKey: value };
          return eventKey;
        })
      ),
    [tabsConfig]
  );

  useEffect(() => {
    if (!tabSet.has(tab)) {
      push(
        `/${language}/${subsidiary}/product/${_get(
          tabSet.values().next(),
          "value",
          "forex"
        )}`
      );
    }
  }, [language, push, subsidiary, tab, tabSet]);

  return (
    <Layout
      bannerProps={{
        variant: "left-bottom",
        ...bannerProps,
      }}
      headerProps={headerProps}
      helmetProps={helmetProps}
    >
      <Tab.Container
        {...(tabSet.has(tab) ? { activeKey: tab } : {})}
        mountOnEnter
        unmountOnExit
        onSelect={(eventKey) => {
          push(`/${language}/${subsidiary}/product/${eventKey}`);
        }}
      >
        <Container fluid>
          <Row className="bg-light">
            <Container>
              <Row>
                <Nav
                  className={`flex-column flex-lg-row py-2 text-center ${
                    isDesktopOrLaptop ? "w-auto" : "w-100"
                  }`}
                >
                  {_map(tabsConfig, ({ label, value }, key) => {
                    const { eventKey } = _isPlainObject(value)
                      ? value
                      : { eventKey: value };
                    return (
                      <Nav.Item key={key}>
                        <StyledNavLink
                          className={`${eventKey}-tab-nav-link`}
                          {...value}
                        >
                          <Text>{label}</Text>
                        </StyledNavLink>
                      </Nav.Item>
                    );
                  })}
                </Nav>
              </Row>
            </Container>
          </Row>
        </Container>
        <Container
          fluid
          className={`${subsidiary}-product-page ${className}`}
          {...props}
        >
          <Row>
            <Tab.Content className="ml-auto mr-auto w-100">
              {_map(tabsConfig, ({ value }, key) => {
                const { eventKey } = _isPlainObject(value)
                  ? value
                  : { eventKey: value };
                return (
                  <Tab.Pane eventKey={eventKey} key={key}>
                    {(subsidiaryTabMap.get(subsidiary) || new Map()).get(
                      eventKey
                    )}
                  </Tab.Pane>
                );
              })}
            </Tab.Content>
          </Row>
        </Container>
      </Tab.Container>
      {beltProps && (
        <BackgroundBelt
          fluid
          className="text-white"
          height={450}
          variant="dark"
          {...beltProps}
        />
      )}
    </Layout>
  );
};

export default ProductPage;
