/** @format */

import $ from "jquery";
import _head from "lodash/head";
import _isString from "lodash/isString";
import _map from "lodash/map";
import React, { useEffect, useRef, useState } from "react";
import Nav from "react-bootstrap/Nav";
import BsNavDropdown from "react-bootstrap/NavDropdown";
import { LinkContainer } from "react-router-bootstrap";
import styled, { css } from "styled-components";
import useHeaderHeight from "../hooks/useHeaderHeight";
import useLink from "../hooks/useLink";
import useResponsive from "../hooks/useResponsive";
import useSubsidiary from "../hooks/useSubsidiary";
import useTranslate from "../hooks/useTranslate";
import Curtain from "./Curtain";

const headerLinkStyle = css`
  border-bottom: 3px solid transparent;
  border-top: 3px solid transparent;
  color: ${({ theme: { white } }) => `${white} !important`};
  font-family: ${({ theme: { "font-family-link": fontFamily } }) => fontFamily};
  &:hover {
    border-bottom: 3px solid ${({ theme: { info } }) => info};
  }
`;

const StyledNavLink = styled(Nav.Link)`
  ${headerLinkStyle}
`;

const StyledLinkContainer = styled(LinkContainer)`
  ${headerLinkStyle}
`;

const StyledNavDropdown = styled(BsNavDropdown)`
  a.nav-link {
    ${headerLinkStyle}
  }
  .dropdown-toggle {
    &:after {
      display: none;
    }
  }
  .dropdown-menu {
    background-color: unset;
    border: unset;
    @media (min-width: 768px) {
      margin-top: 2rem !important;
    }
    .dropdown-item {
      font-family: ${({ theme: { "font-family-link": fontFamily } }) =>
        fontFamily};
      &.active,
      &:active {
        background-color: unset;
      }
      @media (min-width: 768px) {
        color: white;
        padding-left: 0.5rem;
        &:hover {
          background-color: unset;
          color: ${({ theme: { info } }) => info};
        }
      }
    }
  }
`;

const NavLink = ({
  className = "",
  children = "",
  scrollToTop,
  to = "",
  ...props
}) => {
  const { isExternal } = useLink();
  const { subsidiary } = useSubsidiary();
  const { language } = useTranslate();
  return isExternal(to) ? (
    <StyledNavLink
      className={`external-nav-link mb-0 text-white ${className}`}
      href={to}
      {...props}
    >
      {children}
    </StyledNavLink>
  ) : (
    <StyledLinkContainer
      className={`${to}-nav-link mb-0 text-white ${className}`}
      to={`/${language}/${subsidiary}/${to}${scrollToTop ? "?top=0" : ""}`}
      {...props}
    >
      <Nav.Link>{children}</Nav.Link>
    </StyledLinkContainer>
  );
};

const NavDropdownItem = ({
  className = "",
  children = "",
  scrollToTop,
  to = "",
  ...props
}) => {
  const { isExternal } = useLink();
  const { isDesktopOrLaptop } = useResponsive();
  const { subsidiary } = useSubsidiary();
  const { language } = useTranslate();

  const sharedClassName = `${
    isDesktopOrLaptop ? "text-left" : "text-center"
  } ${className}`;

  return isExternal(to) ? (
    <BsNavDropdown.Item
      className={`external-link-nav-dropdown-item h6 text-nowrap ${sharedClassName}`}
      href={to}
      rel="noopener noreferrer"
      target="_blank"
      {...props}
    >
      {children}
    </BsNavDropdown.Item>
  ) : (
    <LinkContainer
      className={`${to}-nav-dropdown-item ${sharedClassName}`}
      to={`/${language}/${subsidiary}/${to}${scrollToTop ? "?top=0" : ""}`}
      {...props}
    >
      <BsNavDropdown.Item className="h6 text-white">
        {children}
      </BsNavDropdown.Item>
    </LinkContainer>
  );
};

const NavDropdown = ({ className = "", data = [], ...props }) => (
  <StyledNavDropdown
    className={`nav-dropdown h6 mb-0 ${className}`}
    rootCloseEvent="mousedown"
    {...props}
  >
    {_map(data, ({ label, value }, key) => (
      <NavDropdownItem scrollToTop key={key} to={value}>
        {label}
      </NavDropdownItem>
    ))}
  </StyledNavDropdown>
);

const HeaderNav = ({ className = "", ...props }) => {
  const [headerHeight] = useHeaderHeight();
  const { isDesktopOrLaptop } = useResponsive();
  const { config } = useTranslate({ configKey: "nav" });
  const [show, setShow] = useState("");
  const [dropdownMenuHeight, setDropdownMenuHeight] = useState(0);
  const navRef = useRef();

  useEffect(() => {
    if (show) {
      const dropdownMenu = _head($(navRef.current).find(".dropdown-menu.show"));
      if (dropdownMenu) {
        setDropdownMenuHeight($(dropdownMenu).outerHeight());
      }
    } else {
      setDropdownMenuHeight(0);
    }
  }, [show]);

  return (
    <>
      <Nav
        className={`header-nav align-items-${
          isDesktopOrLaptop ? "baseline" : "center"
        } text-nowrap ${className}`}
        ref={navRef}
        {...props}
      >
        {_map(config, ({ label, value }, key) =>
          _isString(value) ? (
            <NavLink key={key} to={value}>
              {label}
            </NavLink>
          ) : (
            <NavDropdown
              data={value}
              key={key}
              show={show === label}
              title={label}
              onClick={() => {
                setShow(label);
              }}
              onMouseEnter={() => {
                setShow(label);
              }}
              onToggle={(isOpen) => {
                if (!isOpen) setShow("");
              }}
            />
          )
        )}
      </Nav>
      <Curtain
        height={show ? headerHeight + dropdownMenuHeight : 0}
        onMouseLeave={() => {
          setShow("");
        }}
      />
    </>
  );
};

export default HeaderNav;
