import React from "react";
import Image from "react-bootstrap/Image";
import styled from "styled-components";

const StyledImage = styled(Image)`
  transform: scale(${({ scale = 1 }) => scale});
`;

const ScaleImage = ({ className = "", ...props }) => (
  <StyledImage className={`scale-image ${className}`} {...props} />
);

export default ScaleImage;
