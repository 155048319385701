import React, { useState } from 'react';
import { useTranslation, Trans } from "react-i18next";
import { Tab, Nav, Row, Col } from 'react-bootstrap'
import { Helmet } from "react-helmet";

import BoardInfo from '../components/aboutUs/BoardInfo'
import Milestone from '../components/aboutUs/Milestone'
import Accreditation from '../components/aboutUs/Accreditation'
import Banner from '../components/Banner'

export default function PlatformPage({match}) {
  // Declare a new state variable, which we'll call "count"
  const [lang, setLang] = useState('English');
  const { t, i18n } = useTranslation();
  const subsidiary = match.params.id

  const i18nOptions = {
    keySeparator: false
  };

  return (
    <div className='content'>
      <Helmet>
        <title>
          {t("platform_title", {
            param: t(
              subsidiary === "au"
                ? "australia"
                : subsidiary === "nz"
                ? "new_zealand"
                : null
            )
          })}
        </title>
        {subsidiary === "au" && (
          <meta name="description" content={t("platform_description_au")}></meta>
        )}
      </Helmet>
      <Banner title={`MT5 ${t('platform')}`} bg={'platform'} />
      <section className='container platform'>
        <Tab.Container id="about_us" defaultActiveKey={match.params.tab}>
          <div className='intro'>
            <h2>{t('mt5_title')}</h2>
            <div className='para'>{t('mt5_intro')}</div>
            {subsidiary === 'au' && <div className='para'>{t('mt5_intro_au')}</div>}
            <img src='/Mockup_MT5.png' />
            <div className='row'>
              <div className='col-md-4 download'>
                <a href="https://download.mql5.com/cdn/web/clsa.premium.new/mt5/clsapremium5setup.exe" className="btn ">{t('platform_desktop')}</a>
              </div>
              <div className='col-md-4 download'>
                <a href="https://download.mql5.com/cdn/mobile/mt5/ios?server=KVBKunlun-Demo,KVBKunlun-Production" className="btn" target="_blank">{t('platform_ios')}</a>
              </div>
              <div className='col-md-4 download'>
                <a href="https://download.mql5.com/cdn/mobile/mt5/android?server=KVBKunlun-Demo,KVBKunlun-Production" className="btn" target="_blank">{t('platform_android')}</a>
              </div>
            </div>
          </div>

        </Tab.Container>
      </section>
      <section className='why-mt5'>
        <div className='container'>
            <h2>{t('why_choose_mt5')}</h2>
            <Row>
              <Col md={4}>
                <div>
                  <h5 className='title'>{t('why_mt5_1')}</h5>
                  <ul>
                    <li>{t('why_mt5_1.1', i18nOptions)}</li>
                    <li>{t('why_mt5_1.2', i18nOptions)}</li>
                    <li>{t('why_mt5_1.3', i18nOptions)}</li>
                  </ul>
                </div>
              </Col>
              <Col md={4}>
                <div>
                  <h5 className='title'>{t('why_mt5_6')}</h5>
                  <ul>
                    <li>{t('why_mt5_6.1', i18nOptions)}</li>
                    <li>{t('why_mt5_6.2', i18nOptions)}</li>
                  </ul>
                </div>
              </Col>
              <Col md={4}>
                <div>
                  <h5 className='title'>{t('why_mt5_3')}</h5>
                  <ul>
                    <li>{t('why_mt5_3.1', i18nOptions)}</li>
                    <li>{t('why_mt5_3.2', i18nOptions)}</li>
                  </ul>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <div>
                  <h5 className='title'>{t('why_mt5_4')}</h5>
                  <ul>
                    <li>{t('why_mt5_4.1', i18nOptions)}</li>
                    <li>{t('why_mt5_4.2', i18nOptions)}</li>
                    <li>{t('why_mt5_4.3', i18nOptions)}</li>
                  </ul>
                </div>
              </Col>
              <Col md={4}>
                <div>
                  <h5 className='title'>{t('why_mt5_2')}</h5>
                  <ul>
                    <li>{t('why_mt5_2.1', i18nOptions)}</li>
                    <li>{t('why_mt5_2.2', i18nOptions)}</li>
                    <li>{t('why_mt5_2.3', i18nOptions)}</li>
                  </ul>
                </div>
              </Col>
            </Row>
            {/*</Row>

            <Row>
              <Col sm={3}>
                <Nav variant="pills" className="flex-column">
                  <Nav.Item>
                    <Nav.Link eventKey="mt5">{t('mt5')}</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="why_mt5">{t('why_mt5')}</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="download_mt5">{t('download_mt5')}</Nav.Link>
                  </Nav.Item>
                </Nav>
              </Col>
              <Col sm={9}>
                <Tab.Content>
                  <Tab.Pane eventKey="download_mt5">
                    <div className='pc-version'>
                      <h5>{t('pc_version')}</h5>
                      <div>{t('pc_version1')}</div>
                      <div>{t('pc_version2')}</div>
                      <div>{t('pc_version3')}</div>
                      <div>{t('pc_version4')}</div>
                      <div>{t('pc_version5')}</div>
                      <ul>
                        <li><a href='https://download.mql5.com/cdn/web/clsa.premium.new/mt5/clsapremium5setup.exe'>{t('platform_desktop')}</a></li>
                        <li><a href='https://download.mql5.com/cdn/mobile/mt5/ios?server=KVBKunlun-Demo,KVBKunlun-Production'>{t('platform_ios')}</a></li>
                        <li><a href='https://download.mql5.com/cdn/mobile/mt5/android?server=KVBKunlun-Demo,KVBKunlun-Production'>{t('platform_android')}</a></li>
                      </ul>
                    </div>
                  </Tab.Pane>
                </Tab.Content>
              </Col>*/}

          </div>
      </section>
      <section className='platform-belt'>
        <div className='container'>
          <h2>{t('platform-belt-1')}</h2>
          <h4>{t('platform-belt-2')}</h4>
          { subsidiary === 'au' && <div><a href="https://client.clsapremium.com/CLSA_CRM_Client/Account/registration" className="btn new-account" target="_blank">{t('open_new_account')}</a></div> }
        </div>
      </section>
    </div>
  );
}