/** @format */

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PopupBox from "./PopupBox";

const ApiPopupBox = (props) => {
  const { i18n } = useTranslation();
  const { subsidiary } = props;
  const [status, setStatus] = useState("off");
  const [text, setText] = useState();

  useEffect(() => {
    fetch(`/api/config?key=materialFailure&subsidiary=${subsidiary}`)
      .then((res) => res.json())
      .then((body) => {
        setText(body[i18n.language]);
        setStatus(body.status);
      });
  }, [i18n.language, subsidiary]);

  return (
    <PopupBox status={status} onClose={() => setStatus("off")}>
      <p className="popup-text">{text}</p>
    </PopupBox>
  );
};

export default ApiPopupBox;
