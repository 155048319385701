import React, { useState } from 'react';
import { useTranslation, Trans } from "react-i18next";
import { Tab, Nav, Row, Col } from 'react-bootstrap'
import { Helmet } from "react-helmet";
import { useHistory, useParams } from 'react-router-dom';

import Banner from '../components/Banner'
import BoardInfo from '../components/aboutUs/BoardInfo'
import Milestone from '../components/aboutUs/Milestone'
import Accreditation from '../components/aboutUs/Accreditation'


export default function AboutUsPage({ match }) {
  const { push } = useHistory();
  const { id: tab } = useParams();

  // Declare a new state variable, which we'll call "count"
  const [lang, setLang] = useState('English');
  const { t, i18n } = useTranslation();

  return (
    <div className='content'>
      <Helmet>
        <title>{`${t("about_us")} | CLSA Premium`}</title>
      </Helmet>
      <Banner title={t('about_us')} bg={'about_us'}/>
      <section className='container about-us'>
        <Tab.Container
          activeKey={tab}
          id="about_us"
          onSelect={(eventKey) => {
            push(`/${i18n.language}/about_us/${eventKey}`);
          }}
        >
          <Row>
            <Col sm={3}>
              <Nav variant="pills" className="flex-column">
                <Nav.Item>
                  <Nav.Link eventKey="about_clsap">{t('tab_about_kvb')}</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="board_info">{t('board_info')}</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="milestone">{t('milestone')}</Nav.Link>
                </Nav.Item>
                {/* <Nav.Item>
                  <Nav.Link eventKey="accreditation">{t('accreditation')}</Nav.Link>
                </Nav.Item> */}
              </Nav>
            </Col>
            <Col sm={9}>
              <Tab.Content>
                <Tab.Pane eventKey="about_clsap">
                  <div>
                    <div>{t('about_kvb_1')}</div>
                    <br />
                    <div>{t('about_kvb_2')}</div>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="board_info">
                  <BoardInfo />
                </Tab.Pane>
                <Tab.Pane eventKey="milestone">
                  <Milestone />
                </Tab.Pane>
                {/* <Tab.Pane eventKey="accreditation">
                  <Accreditation />
                </Tab.Pane> */}
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </section>
    </div>
  );
}