import React from "react";
import BackgroundBelt from "./BackgroundBelt";
import Belt from "./Belt";

const TopBelt = ({
  className = "",
  backgroundimage,
  backgroundProps = {},
  ...props
}) => (
  <div className={`top-belt ${className}`}>
    <BackgroundBelt
      backgroundimage={backgroundimage}
      className={`top-belt-background ${className}`}
      {...backgroundProps}
    />
    <Belt {...props} />
  </div>
);

export default TopBelt;
