import _ from "lodash";
import _fill from "lodash/fill";
import _get from "lodash/get";
import _has from "lodash/has";
import _head from "lodash/head";
import _isArray from "lodash/isArray";
import _isNumber from "lodash/isNumber";
import _isPlainObject from "lodash/isPlainObject";
import _map from "lodash/map";
import _size from "lodash/size";
import numeral from "numeral";
import React, { useMemo, useState } from "react";
import ListGroup from "react-bootstrap/ListGroup";
import styled from "styled-components";
import useResponsive from "../hooks/useResponsive";
import useTranslate from "../hooks/useTranslate";
import Text from "./Text";

const StyledListGroupItem = styled(ListGroup.Item)`
  border-color: ${({ active }) =>
    active && "rgba(255, 255, 255, 0.125) !important"};
  &:last-child {
    border-bottom-width: 1px !important;
  }
`;

const DetailList = ({
  className = "",
  data = [],
  labelProps: {
    className: labelClassName,
    textProps: labelTextProps,
    ...labelProps
  } = {},
  valueProps: {
    className: valueClassName,
    textProps: valueTextProps,
    ...valueProps
  } = {},
  showBottomGutter = false,
  ...props
}) => {
  const { isNotMobile } = useResponsive();
  const { translate } = useTranslate();
  const [activeCol, setActiveCol] = useState();

  const bottomRowPlaceHolder = useMemo(() => {
    const firstValue = _get(_head(data), "value");
    return {
      value: _fill(
        Array(_size(_isArray(firstValue) ? firstValue : [firstValue])),
        null,
        null
      ),
    };
  }, [data]);

  return (
    <div
      className={`detail-list d-inline-block mw-100 overflow-auto ${className}`}
      {...props}
    >
      {_map(
        [
          ...data,
          ...(showBottomGutter ? [bottomRowPlaceHolder] : []), // Placeholder to add hover effect
        ],
        ({ label, value }, rowIndex) => (
          <ListGroup horizontal className="d-table-row" key={rowIndex}>
            <StyledListGroupItem
              className={`label ${
                showBottomGutter && rowIndex === _size(data)
                  ? "border-bottom-0"
                  : ""
              } border-left-0 border-right-0 border-top-0 d-table-cell ${
                isNotMobile ? "pr-5" : ""
              } rounded-0 ${labelClassName}`}
              {...labelProps}
            >
              <Text {...labelTextProps}>{label}</Text>
            </StyledListGroupItem>
            {_map(_isArray(value) ? value : [value], (value, key) => (
              <StyledListGroupItem
                active={activeCol === key}
                className={`${
                  showBottomGutter && rowIndex === _size(data)
                    ? "border-bottom-0"
                    : ""
                } border-left-0 border-right-0 border-top-0 d-table-cell ${
                  isNotMobile ? "px-5" : ""
                } rounded-0 ${
                  activeCol === key ? "shadow" : ""
                } ${valueClassName}`}
                key={key}
                onMouseEnter={() => {
                  setActiveCol(key);
                }}
                onMouseLeave={() => {
                  setActiveCol();
                }}
                {...valueProps}
              >
                <Text {...valueTextProps}>
                  {_isNumber(value)
                    ? numeral(value).format("0,0.00")
                    : _isPlainObject(value) && _has(value, "unit")
                    ? translate(_get(value, "unit"), {
                        count: _get(value, "count", 0),
                        postProcess: "interval",
                      })
                    : value}
                </Text>
              </StyledListGroupItem>
            ))}
          </ListGroup>
        )
      )}
    </div>
  );
};

export default DetailList;
