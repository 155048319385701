import _has from "lodash/has";
import React from "react";
import styled from "styled-components";
import Text from "./Text";

const StyledH1 = styled.h1`
  font-family: ${({ font: { family = "title" } = {}, theme }) =>
    _has(theme, `font-family-${family}`)
      ? theme[`font-family-${family}`]
      : theme["font-family-title"]};
  font-size: ${({ as, display, theme }) =>
    display && _has(theme, `display${display}-size`)
      ? theme[`display${display}-size`]
      : as && _has(theme, `${as}-font-size`)
      ? theme[`${as}-font-size`]
      : theme["h1-font-size"]};
  /* TODO Remove font-weight: normal for h2 after App.scss depricated */
  font-weight: ${({ as }) => (as === "h2" ? "normal" : "inherit")};
`;

const Title = ({ as, children = "", ...props }) => (
  <Text as={StyledH1} forwardedAs={as} {...props}>
    {children || ""}
  </Text>
);

export default Title;
