import React from "react";
import Container from "react-bootstrap/Container";
import Jumbotron from "react-bootstrap/Jumbotron";
import useResponsive from "../hooks/useResponsive";
import Buttons from "./Buttons";
import Paragraph from "./Paragraph";
import Title from "./Title";

const Belt = ({
  brief = "",
  briefProps: { className: briefClassName, ...briefProps } = {},
  buttons = [],
  buttonProps: { className: buttonClassName, ...buttonProps } = {},
  children,
  className = "",
  title = "",
  titleProps: { className: titleClassName, ...titleProps } = {},
  ...props
}) => {
  const { isNotMobile } = useResponsive();
  return (
    <Jumbotron
      className={`belt align-items-center d-flex m-0 rounded-0 ${className}`}
      {...props}
    >
      <Container className="text-center">
        {title && (
          <Title
            as="h4"
            className={`font-weight-bold mb-4 ${titleClassName}`}
            font={{ family: "text" }}
            {...titleProps}
          >
            {title}
          </Title>
        )}
        {brief && (
          <Paragraph as="h5" className={briefClassName} {...briefProps}>
            {brief}
          </Paragraph>
        )}
        {children}
        <Buttons
          buttonProps={{
            className: `px-5 py-2 ${buttonClassName} ${
              isNotMobile ? "w-auto" : "w-100"
            }`,
            color: "white",
          }}
          className="justify-content-center mt-4"
          config={buttons}
          {...buttonProps}
        />
      </Container>
    </Jumbotron>
  );
};

export default Belt;
