import React from "react";
import useTranslate from "../hooks/useTranslate";
import Annotation from "./Annotation";
import Paragraph from "./Paragraph";
import Title from "./Title";

const ProductTradeTime = ({ className = "", ...props }) => {
  const { config } = useTranslate({ configKey: "product-trade-time" });
  const { annotation = {}, brief = "", title = "" } = { ...config, ...props };

  return (
    <div className={`trade-time ${className}`} {...props}>
      <Title as="h2">{title}</Title>
      <Paragraph>{brief}</Paragraph>
      <Annotation
        listProps={{
          labelProps: { font: { size: "sm" } },
          valueProps: { font: { size: "sm" } },
        }}
        {...annotation}
      />
    </div>
  );
};

export default ProductTradeTime;
