import _isString from "lodash/isString";
import _isArray from "lodash/isArray";
import _map from "lodash/map";
import _split from "lodash/split";
import React from "react";
import Text from "./Text";

const Texts = ({ children = "", ...props }) => (
  <>
    {_map(children, (paragraph, key) => (
      <Text as="p" {...props} key={key}>
        {paragraph}
      </Text>
    ))}
  </>
);

const Paragraph = ({ children = "", ...props }) =>
  _isString(children) ? (
    <Texts {...props}>{_split(children, /\n|<br\s?\/?>/)}</Texts>
  ) : _isArray(children) ? (
    <Texts {...props}>{children}</Texts>
  ) : (
    children || ""
  );

export default Paragraph;
