import React, { useState } from 'react';
import { useTranslation, Trans } from "react-i18next";


export default function PreciousMetals(props) {
  const { t, i18n } = useTranslation();
  const subsidiary = props.subsidiary
  const minFluctuationValue1 = subsidiary === 'au' ? 'US$ 1' : 'US$ 10'
  const minFluctuationValue2 = subsidiary === 'au' ? 'US$ 0.01' : 'US$ 0.1'
  const marginRequired = subsidiary === 'au' ? '1%' : '0.5%'
  const marginRequiredFrom15thMarch = '5%(20:1)'
  const minFluctuationValue3 = subsidiary === 'au' ? 'US$ 0.1' : 'US$ 1'
  const subParam = {param1: subsidiary === 'au' ? t('sub_au') : (subsidiary === 'hk' ? t('sub_hk') : t('sub_nz'))}
  return (
    <div className="precious-metals">
      {subsidiary !== 'au' && <div className='brief'>{t('precious_metals_brief')}</div>}
      <div className='block gold-xauusd'>
        <div className='title'><h2>{t('gold_spec', { symbal: "XAUUSD" })}</h2></div>
        <table className='spec mt-5'>
          <tbody>
          <tr><td>{t('contract_symbol')}</td><td>{'XAUUSD'}</td></tr>
          <tr><td>{t('contract_size')}</td><td>{t('contract_size_gold_value')}</td></tr>
          {subsidiary !== 'au' &&
            <tr><td>{t('target_spread')}</td><td>{'4'}</td></tr>
          }
          <tr><td>{t('min_tradable_lot')}</td><td>{t('min_tradable_lot_value')}</td></tr>
          {subsidiary === 'au' &&
            <tr><td>{t('max_tradable_lot')}</td><td>{t('max_tradable_lot_value_gold')}</td></tr>
          }
          <tr><td>{t('min_incremental_lot')}</td><td>{t('min_incremental_lot_value')}</td></tr>
          <tr><td>{t('min_fluctuation')}</td><td>{'0.01'}</td></tr>
          <tr><td>{t('us_min_fluctuation_1')}</td><td>{minFluctuationValue1}</td></tr>
          <tr><td>{t('us_min_fluctuation_0.01', { keySeparator: false })}</td><td>{minFluctuationValue2}</td></tr>
          <tr><td>{t('min_spread')}</td><td>{'1.20'}</td></tr>
          <tr><td>{t('ave_spread')}</td><td>{'2.90'}</td></tr>

          {
            subsidiary === 'au' ? (
              <tr><td>{t('margin_required')}</td><td>{marginRequiredFrom15thMarch}</td></tr>
            ) : (
              <tr><td>{t('margin_required')}</td><td>{marginRequired}</td></tr>
            )
          }
          <tr><td>{t('nearest_level_gold')}</td><td>{'0.50'}</td></tr>
          <tr><td>{t('profit_loss_cal')}</td><td>{t('profit_loss_cal_value')}</td></tr>
          <tr><td>{t('roll_over_sc')}</td><td>{t('roll_over_swap_value',subParam)}</td></tr>
          {subsidiary !== 'au' &&
            <tr><td>{t('delivery')}</td><td>{t('non_delieverable')}</td></tr>
          }

          {subsidiary === 'au' ? (
              <>
                <tr><td>{t('trading_hour')}</td><td>{t('trading_hour_gold_value_au')}</td></tr>
                <tr><td>{t('trading_breaks')}</td><td>{t('trading_breaks_value_gold_au')}</td></tr>
              </>
            ) : (
              <tr><td>{t('trading_hour')}</td><td>{t('trading_hour_gold_value')}</td></tr>
            )
          }
          </tbody>
        </table>
      </div>
      <div className='block silver'>
        <div className='title'><h2>{t('silver_spec')}</h2></div>
        <table className='spec'>
          <tbody>
          <tr><td>{t('contract_symbol')}</td><td>{'XAGUSD'}</td></tr>
          <tr><td>{t('contract_size')}</td><td>{t('contract_size_gold_value')}</td></tr>
          {subsidiary !== 'au' &&
            <tr><td>{t('target_spread')}</td><td>{'96'}</td></tr>
          }
          <tr><td>{t('min_tradable_lot')}</td><td>{`0.5 ${t('lot')}`}</td></tr>
          {subsidiary === 'au' &&
            <tr><td>{t('max_tradable_lot')}</td><td>{t('max_tradable_lot_value')}</td></tr>
          }
          <tr><td>{t('min_incremental_lot')}</td><td>{`0.5 ${t('lot')}`}</td></tr>
          <tr><td>{t('min_fluctuation')}</td><td>{'0.001'}</td></tr>
          <tr><td>{t('us_min_fluctuation_1')}</td><td>{minFluctuationValue3}</td></tr>
          <tr><td>{t('min_spread')}</td><td>{'2.00'}</td></tr>
          <tr><td>{t('ave_spread')}</td><td>{'10.70'}</td></tr>
          {
            subsidiary === 'au' ? (
              <tr><td>{t('margin_required')}</td><td>{'10%(10:1)'}</td></tr>
            ) : (
              <tr><td>{t('margin_required')}</td><td>{'1%'}</td></tr>
            )
          }
          <tr><td>{t('nearest_level_gold')}</td><td>{'0.03'}</td></tr>
          <tr><td>{t('roll_over_sc')}</td><td>{t('roll_over_swap_value',subParam)}</td></tr>
          {subsidiary === 'au' ? (
              <>
                <tr><td>{t('trading_hour')}</td><td>{t('trading_hour_gold_value_au')}</td></tr>
                <tr><td>{t('trading_breaks')}</td><td>{t('trading_breaks_value_gold_au')}</td></tr>
              </>
            ) : (
              <tr><td>{t('trading_hour')}</td><td>{t('trading_hour_gold_value')}</td></tr>
            )
          }
          </tbody>
        </table>
      </div>
      {
        subsidiary === 'au' ? (
          <>
            <div className="block gold-xauaud">
              <div className='title'>
                <h2>{t('gold_spec', { symbal: "XAUAUD" })}</h2>
              </div>
              <table className='spec mt-5'>
              <tbody>
                <tr><td>{t('contract_symbol')}</td><td>{'XAUAUD'}</td></tr>
                <tr><td>{t('contract_size')}</td><td>{t('contract_size_gold_value')}</td></tr>
                <tr><td>{t('min_tradable_lot')}</td><td>{`0.01 ${t('lot')}`}</td></tr>
                <tr><td>{t('max_tradable_lot')}</td><td>{t('max_tradable_lot_value_gold')}</td></tr>
                <tr><td>{t('min_incremental_lot')}</td><td>{`0.01 ${t('lot')}`}</td></tr>
                <tr><td>{t('min_fluctuation')}</td><td>{'0.01'}</td></tr>
                <tr><td>{t('aud_min_fluctuation_1')}</td><td>{'AUD$ 1'}</td></tr>
                <tr><td>{t('aud_min_fluctuation_0.01')}</td><td>{'AUD$ 0.01'}</td></tr>
                <tr><td>{t('min_spread')}</td><td>{'1.60'}</td></tr>
                <tr><td>{t('ave_spread')}</td><td>{'4.60'}</td></tr>
                <tr><td>{t('margin_required')}</td><td>{'5% (20:1)'}</td></tr>
                <tr><td>{t('nearest_level_gold')}</td><td>{'0.50'}</td></tr>
                <tr><td>{t('profit_loss_cal')}</td><td>{t('profit_loss_cal_value')}</td></tr>
                <tr><td>{t('roll_over_sc')}</td><td>{t('roll_over_swap_value',subParam)}</td></tr>
                <tr><td>{t('trading_hour')}</td><td>{t('trading_hour_gold_value_au')}</td></tr>
                <tr><td>{t('trading_breaks')}</td><td>{t('trading_breaks_value_gold_au')}</td></tr>
              </tbody>
            </table>
            </div>
            <div className="block gold-xaucnh">
              <div className='title'>
                <h2>{t('gold_spec', { symbal: "XAUCNH" })}</h2>
              </div>
              <table className='spec mt-5'>
                <tbody>
                <tr><td>{t('contract_symbol')}</td><td>{'XAUCNH'}</td></tr>
                <tr><td>{t('contract_size')}</td><td>{t('contract_size_gold_value')}</td></tr>
                <tr><td>{t('min_tradable_lot')}</td><td>{`0.1 ${t('lot')}`}</td></tr>
                <tr><td>{t('max_tradable_lot')}</td><td>{t('max_tradable_lot_value_gold')}</td></tr>
                <tr><td>{t('min_incremental_lot')}</td><td>{`0.1 ${t('lot')}`}</td></tr>
                <tr><td>{t('min_fluctuation')}</td><td>{'0.01'}</td></tr>
                <tr><td>{t('cnh_min_fluctuation_1')}</td><td>{'CNH$ 1'}</td></tr>
                <tr><td>{t('cnh_min_fluctuation_0.1')}</td><td>{'CNH$ 0.1'}</td></tr>
                <tr><td>{t('min_spread')}</td><td>{'8.60'}</td></tr>
                <tr><td>{t('ave_spread')}</td><td>{'27.50'}</td></tr>
                <tr><td>{t('margin_required')}</td><td>{'5% (20:1)'}</td></tr>
                <tr><td>{t('nearest_level_gold')}</td><td>{'0.50'}</td></tr>
                <tr><td>{t('profit_loss_cal')}</td><td>{t('profit_loss_cal_value')}</td></tr>
                <tr><td>{t('roll_over_sc')}</td><td>{t('roll_over_swap_value',subParam)}</td></tr>
                <tr><td>{t('trading_hour')}</td><td>{t('trading_hour_gold_value_au')}</td></tr>
                <tr><td>{t('trading_breaks')}</td><td>{t('trading_breaks_value_gold_au')}</td></tr>
              </tbody>
              </table>
            </div>
            <div className="block gold-xaugbp">
              <div className='title'>
                <h2>{t('gold_spec', { symbal: "XAUGBP" })}</h2>
              </div>
              <table className='spec mt-5'>
                <tbody>
                <tr><td>{t('contract_symbol')}</td><td>{'XAUGBP'}</td></tr>
                <tr><td>{t('contract_size')}</td><td>{t('contract_size_gold_value')}</td></tr>
                <tr><td>{t('min_tradable_lot')}</td><td>{`0.1 ${t('lot')}`}</td></tr>
                <tr><td>{t('max_tradable_lot')}</td><td>{t('max_tradable_lot_value_gold')}</td></tr>
                <tr><td>{t('min_incremental_lot')}</td><td>{`0.1 ${t('lot')}`}</td></tr>
                <tr><td>{t('min_fluctuation')}</td><td>{'0.01'}</td></tr>
                <tr><td>{t('gbp_min_fluctuation_1')}</td><td>{'GBP$ 1'}</td></tr>
                <tr><td>{t('gbp_min_fluctuation_0.1')}</td><td>{'GBP$ 0.1'}</td></tr>
                <tr><td>{t('min_spread')}</td><td>{'1.20'}</td></tr>
                <tr><td>{t('ave_spread')}</td><td>{'3.80'}</td></tr>
                <tr><td>{t('margin_required')}</td><td>{'5% (20:1)'}</td></tr>
                <tr><td>{t('nearest_level_gold')}</td><td>{'0.50'}</td></tr>
                <tr><td>{t('profit_loss_cal')}</td><td>{t('profit_loss_cal_value')}</td></tr>
                <tr><td>{t('roll_over_sc')}</td><td>{t('roll_over_swap_value',subParam)}</td></tr>
                <tr><td>{t('trading_hour')}</td><td>{t('trading_hour_gold_value_au')}</td></tr>
                <tr><td>{t('trading_breaks')}</td><td>{t('trading_breaks_value_gold_au')}</td></tr>
              </tbody>
              </table>
            </div>
            <div className="block gold-xaueur">
              <div className='title'>
                <h2>{t('gold_spec', { symbal: "XAUEUR" })}</h2>
              </div>
              <table className='spec mt-5'>
                <tbody>
                  <tr><td>{t('contract_symbol')}</td><td>{'XAUEUR'}</td></tr>
                  <tr><td>{t('contract_size')}</td><td>{t('contract_size_gold_value')}</td></tr>
                  <tr><td>{t('min_tradable_lot')}</td><td>{`0.01 ${t('lot')}`}</td></tr>
                  <tr><td>{t('max_tradable_lot')}</td><td>{t('max_tradable_lot_value_gold')}</td></tr>
                  <tr><td>{t('min_incremental_lot')}</td><td>{`0.01 ${t('lot')}`}</td></tr>
                  <tr><td>{t('min_fluctuation')}</td><td>{'0.01'}</td></tr>
                  <tr><td>{t('eur_min_fluctuation_1')}</td><td>{'EUR$ 1'}</td></tr>
                  <tr><td>{t('eur_min_fluctuation_0.01')}</td><td>{'EUR$ 0.01'}</td></tr>
                  <tr><td>{t('min_spread')}</td><td>{'1.20'}</td></tr>
                  <tr><td>{t('ave_spread')}</td><td>{'3.60'}</td></tr>
                  <tr><td>{t('margin_required')}</td><td>{'5% (20:1)'}</td></tr>
                  <tr><td>{t('nearest_level_gold')}</td><td>{'0.50'}</td></tr>
                  <tr><td>{t('profit_loss_cal')}</td><td>{t('profit_loss_cal_value')}</td></tr>
                  <tr><td>{t('roll_over_sc')}</td><td>{t('roll_over_swap_value',subParam)}</td></tr>
                  <tr><td>{t('trading_hour')}</td><td>{t('trading_hour_gold_value_au')}</td></tr>
                  <tr><td>{t('trading_breaks')}</td><td>{t('trading_breaks_value_gold_au')}</td></tr>
                </tbody>
              </table>
            </div>
          </>
        ) : null
      }

    </div>
  )
}
