import _map from "lodash/map";
import React from "react";
import useTranslate from "../hooks/useTranslate";
import Accordion from "./Accordion";
import Annotation from "./Annotation";
import DescriptionList from "./DescriptionList";
import Paragraph from "./Paragraph";
import Title from "./Title";

const ProductGuide = ({ className = "", ...props }) => {
  const { config } = useTranslate({ configKey: "product-swap-charge" });
  const { accordions = [], brief = "", title = "" } = { ...config, ...props };

  return (
    <div className={`swap-charge ${className}`} {...props}>
      <Title as="h2" className="mb-4">
        {title}
      </Title>
      <Paragraph className="mb-5">{brief}</Paragraph>
      <Accordion
        data={_map(
          accordions,
          ({ label, value: { annotation, brief, list } = {} }) => ({
            label,
            value: (
              <>
                <Paragraph>{brief}</Paragraph>
                <DescriptionList
                  data={list}
                  labelProps={{ className: "font-weight-normal" }}
                />
                <Annotation
                  listProps={{
                    labelProps: { font: { size: "sm" } },
                    valueProps: { font: { size: "sm" } },
                  }}
                  {...annotation}
                />
              </>
            ),
          })
        )}
        toggleProps={{
          className: "bg-primary text-white shadow",
          iconProps: {
            position: "bottom",
          },
        }}
      />
    </div>
  );
};

export default ProductGuide;
