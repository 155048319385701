import React, { useState } from 'react';
import { useTranslation, Trans } from "react-i18next";
import { Tab, Nav, Row, Col } from 'react-bootstrap'
import { Helmet } from "react-helmet";

import Banner from '../components/Banner'



export default function NotificationPage() {
  
  const { t, i18n } = useTranslation();

  //const pdfLangType = i18n.language === 'en' ? 'eng' : i18n.language === 'cn' ? 'chi' : 'chi'

  return (
    <div className='content'>
      <Helmet>
        <title>{`${t("notifications")} | CLSA Premium`}</title>
      </Helmet>
      <Banner title={t('notifications')} bg={'financial_reports'} brief={t('float_box_universal')}/>
      <section className='container notifications'>
        <div className="containerRight">
          <h4 className='title'>{t('noti_title')}</h4>
          <div className='brief'>{t('noti_brief1')}</div>
          <div className='brief'>{t('noti_brief2')}</div>
          <div className='cert'>
            <h5>{t('intention_cert')}</h5>
            <span>{t('notice_date')}</span>

            <ul>
              {/*<li>{t('no_notice_placeholder')}</li>*/}
              <a href="/documents/NS3_Notice_dated_2022-01-28.pdf" target="_blank">{t('notice_placeholder')}</a>
            </ul>
          </div>
          <div className='cert'>
            <h5>{t('cancellation_cert')}</h5>
            <span>{t('no_notice_date')}</span>
            <ul>
              {/*<li>{t('no_notice_placeholder')}</li>*/}
              <a href="/documents/NS4_dated_13_May_2022.pdf" target="_blank">{t('cancellation_cert')}</a>
            </ul>
          </div>
        </div>
      </section>
    </div>
  );
}