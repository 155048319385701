import React from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Buttons from "../components/Buttons";
import Layout from "../components/Layout";
import LeftBelt from "../components/LeftBelt";
import ListBelt from "../components/ListBelt";
import Paragraph from "../components/Paragraph";
import ScaleImage from "../components/ScaleImage";
import Title from "../components/Title";
import useResponsive from "../hooks/useResponsive";
import useTranslate from "../hooks/useTranslate";

const PlatformPage = ({ className = "", ...props }) => {
  const { isDesktop, isDesktopOrLaptop, isNotMobile } = useResponsive();
  const { config } = useTranslate({ configKey: "mt5" });

  const {
    apps: appsConfig,
    banner: bannerProps,
    belt: beltProps,
    helmet: helmetProps,
    "list-belt": listBeltConfig,
    intro: introConfig,
  } = config || {};

  return (
    <Layout
      bannerProps={{
        variant: "left-bottom",
        ...bannerProps,
      }}
      helmetProps={helmetProps}
    >
      {config && (
        <Container fluid className={`mt5-page p-0 ${className}`} {...props}>
          {introConfig && (
            <Container>
              <Row className="intro py-5 my-5">
                <Col md={7}>
                  <Title as="h2" display={isNotMobile ? 5 : 7}>
                    {introConfig.title}
                  </Title>
                  <Paragraph>{introConfig.brief}</Paragraph>
                  <Buttons
                    buttonProps={{
                      className: `px-5 py-2 mx-2 ${
                        isDesktop ? "w-auto" : "w-100"
                      }`,
                      font: { size: "sm" },
                    }}
                    className="mt-5"
                    config={introConfig.buttons}
                  />
                </Col>
                <Col md={5}>
                  <ScaleImage
                    fluid
                    alt="mt5"
                    scale={1.5}
                    src={introConfig.image}
                  />
                </Col>
              </Row>
            </Container>
          )}
          {listBeltConfig && (
            <ListBelt
              fluid
              className="bg-light"
              itemProps={{ className: "bg-white px-4 py-5 text-primary" }}
              rowProps={{ className: "px-4 py-5 my-5" }}
              titleProps={{ className: "text-primary text-center" }}
              {...listBeltConfig}
            />
          )}
          {appsConfig && (
            <Container>
              <Row className="apps py-5 my-5">
                <Col className="p-5" md={5}>
                  <ScaleImage
                    fluid
                    alt="mt5"
                    scale={1.7}
                    src={appsConfig.image}
                  />
                </Col>
                <Col className="p-5" md={7}>
                  <Title
                    as="h2"
                    className={`${appsConfig.title}-title`}
                    display={6}
                  >
                    {appsConfig.title}
                  </Title>
                  <Paragraph>{appsConfig.brief}</Paragraph>
                  <Buttons
                    buttonProps={{
                      className: `px-4 py-2 ${
                        isDesktopOrLaptop ? "w-auto" : "w-100"
                      }`,
                      font: { size: "sm" },
                    }}
                    className="justify-content-between mt-5"
                    config={appsConfig.buttons}
                  />
                </Col>
              </Row>
            </Container>
          )}
          {beltProps && (
            <LeftBelt
              fluid
              bg="success"
              buttonProps={{
                className: `px-5 py-2 ${isNotMobile ? "w-auto" : "w-100"}`,
                color: "primary",
                variant: "light",
              }}
              buttonsProps={{
                className: "justify-content-center",
              }}
              className="mt-4"
              titleProps={{
                display: isNotMobile ? 5 : 7,
              }}
              {...beltProps}
            />
          )}
        </Container>
      )}
    </Layout>
  );
};

export default PlatformPage;
