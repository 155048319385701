/** @format */

import React, { Children, cloneElement, useEffect, useState } from "react";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
  useParams,
  useRouteMatch,
} from "react-router-dom";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Layout from "./components/Layout";
import useSubsidiary from "./hooks/useSubsidiary";
import AboutUsPage from "./pages/AboutUsPage";
import AccountOpeningPage from "./pages/AccountOpeningPage";
import AccountTypesPage from "./pages/AccountTypesPage";
import AnnouncementPage from "./pages/AnnouncementPage";
import AuHomePage from "./pages/AuHomePage";
import BullionHomePage from "./pages/BullionHomePage";
import ClientResourcesPage from "./pages/ClientResourcesPage";
import ContactPage from "./pages/ContactPage";
import DepositAndWithdrawalPage from "./pages/DepositAndWithdrawalPage";
import DepositPage from "./pages/DepositPage";
import DisclaimerPage from "./pages/DisclaimerPage";
import EducationPage from "./pages/EducationPage";
import FinancialReportPage from "./pages/FinancialReportPage";
import FSGPage from "./pages/FSGPage";
import GovernancePage from "./pages/GovernancePage";
import Homepage from "./pages/Homepage";
import IBProgramPage from "./pages/IBProgramPage";
import IntermediatePage from "./pages/IntermediatePage";
import LegalDocPage from "./pages/LegalDocPage";
import NewAboutUsPage from "./pages/NewAboutUsPage";
import NewLegalDocPage from "./pages/NewLegalDocPage";
import NewPlatformPage from "./pages/NewPlatformPage";
import NoMatchPage from "./pages/NoMatchPage";
import NotificationPage from "./pages/NotificationPage";
import OldAccountOpeningPage from "./pages/OldAccountOpeningPage";
import OldIntermediatePage from "./pages/OldIntermediatePage";
import OldLegalDocPage from "./pages/OldLegalDocPage";
import OldPlatformPage from "./pages/OldPlatformPage";
import OldPolicyPage from "./pages/OldPolicyPage";
import OldTermPage from "./pages/OldTermPage";
import PDSPage from "./pages/PDSPage";
import PlatformPage from "./pages/PlatformPage";
import PolicyPage from "./pages/PolicyPage";
import ProductAndServicePage from "./pages/ProductAndServicePage";
import ProductPage from "./pages/ProductPage";
import ProspectusPage from "./pages/ProspectusPage";
import SummaryAUPage from "./pages/SummaryAUPage";
import SummaryHKPage from "./pages/SummaryHKPage";
import SummaryNZPage from "./pages/SummaryNZPage";
import TermPage from "./pages/TermPage";
import WelcomeRewardPage from "./pages/WelcomeRewardPage";
import WithdrawPage from "./pages/WithdrawPage";

// Generate sitemap does not have window
const language =
  typeof window === "undefined"
    ? "en"
    : localStorage.getItem("i18nextLng") || "en";

const Delay = ({ children = <></>, time = 1 }) => {
  const [hidden, setHidden] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setHidden(false);
    }, time);
  }, [time]);
  return hidden ? null : children;
};

const Subsidiary = ({ children = <></> }) => {
  const history = useHistory();
  const location = useLocation();
  const { id } = useParams();
  const { params, ...match } = useRouteMatch();
  const { subsidiary } = useSubsidiary();

  return Children.map(children, (child) =>
    cloneElement(child, {
      history,
      location,
      match: {
        ...match,
        params: {
          ...params,
          id: id || (subsidiary === "home" ? "list" : subsidiary),
          sub: subsidiary,
        },
      },
    })
  );
};

const LayoutWrapper = ({ children = <></> }) => (
  <Layout
    headerProps={{ bg: "dark" }}
    showBanner={false}
    showFooter={false}
    showHeader={false}
    showHelmet={false}
  >
    <Subsidiary>
      <Header />
      {children}
      <Footer />
    </Subsidiary>
  </Layout>
);

export default (
  <Switch>
    {/* New Routes */}
    {/*
....###....##.....##
...##.##...##.....##
..##...##..##.....##
.##.....##.##.....##
.#########.##.....##
.##.....##.##.....##
.##.....##..#######.
*/}
    {/* Redirect all au access to new site */}
    {/* <Route
      exact
      path="/en/home/intermediate/au"
      render={() => {
        window.location.replace("https://clsapremium.com.au/");
      }}
    />
    <Route
      exact
      path="/cn/home/intermediate/au"
      render={() => {
        window.location.replace("https://clsapremium.com.au/zh/home_zh-cn/");
      }}
    />
    <Route
      exact
      path="/tc/home/intermediate/au"
      render={() => {
        window.location.replace("https://clsapremium.com.au/zhf/home_zh-hk/");
      }}
    />
    <Route
      exact
      path="/en/au*"
      render={() => {
        window.location.replace("https://clsapremium.com.au/");
      }}
    />
    <Route
      exact
      path="/cn/au*"
      render={() => {
        window.location.replace("https://clsapremium.com.au/zh/home_zh-cn/");
      }}
    />
    <Route
      exact
      path="/tc/au*"
      render={() => {
        window.location.replace("https://clsapremium.com.au/zhf/home_zh-hk/");
      }}
    />
    <Route
      exact
      path="/au"
      render={() => {
        window.location.replace("https://clsapremium.com.au/");
      }}
    /> */}

    <Route exact path="/:lng(en|cn|tc)/:subsidiary(au)/about_us">
      <NewAboutUsPage />
    </Route>
    <Route path="/:lng(en|cn|tc)/:subsidiary(au)/legal_documents">
      <NewLegalDocPage />
    </Route>
    <Route path="/:lng(en|cn|tc)/:subsidiary(au)/client_resources">
      <ClientResourcesPage />
    </Route>
    <Route path="/:lng(en|cn|tc)/:subsidiary(au)/latest_events/education">
      <EducationPage />
    </Route>
    <Redirect
      exact
      from="/:lng(en|cn|tc)/:subsidiary(au)/platform"
      to="/:lng/:subsidiary/platform/mt5"
    />
    <Route path="/:lng(en|cn|tc)/:subsidiary(au)/platform/mt5">
      <NewPlatformPage />
    </Route>
    <Redirect
      exact
      from="/:lng(en|cn|tc)/:subsidiary(au)/product"
      to="/:lng/:subsidiary/product/forex"
    />
    <Route path="/:lng(en|cn|tc)/:subsidiary(au)/product/:tab">
      <ProductPage />
    </Route>
    <Redirect
      exact
      from="/:lng(en|cn|tc)/:subsidiary(au)/trading_with_us"
      to="/:lng/:subsidiary/trading_with_us/account_types"
    />
    <Route path="/:lng(en|cn|tc)/:subsidiary(au)/trading_with_us/account_types">
      <AccountTypesPage />
    </Route>
    <Route exact path="/:lng(en|cn|tc)/:subsidiary(au)">
      <AuHomePage />
    </Route>
    {/*
.########..##.....##.##.......##.......####..#######..##....##
.##.....##.##.....##.##.......##........##..##.....##.###...##
.##.....##.##.....##.##.......##........##..##.....##.####..##
.########..##.....##.##.......##........##..##.....##.##.##.##
.##.....##.##.....##.##.......##........##..##.....##.##..####
.##.....##.##.....##.##.......##........##..##.....##.##...###
.########...#######..########.########.####..#######..##....##
*/}
    <Redirect
      exact
      from="/:lng(en|cn|tc)/:subsidiary(bullion)/product"
      to="/:lng/:subsidiary/product/precious_metals"
    />
    <Route path="/:lng(en|cn|tc)/:subsidiary(bullion)/product/:tab">
      <ProductPage />
    </Route>
    <Route path="/:lng(en|cn|tc)/:subsidiary(bullion)/platform/mt5">
      <OldPlatformPage />
    </Route>
    <Route path="/:lng(en|cn|tc)/:subsidiary(bullion)/account_open">
      <OldAccountOpeningPage />
    </Route>
    <Route path="/:lng(en|cn|tc)/:subsidiary(bullion)/legal_documents">
      <OldLegalDocPage />
    </Route>
    <Route path="/:lng(en|cn|tc)/:subsidiary(bullion)/deposit">
      <DepositPage />
    </Route>
    <Route path="/:lng(en|cn|tc)/:subsidiary(bullion)/withdraw">
      <WithdrawPage />
    </Route>
    <Route path="/:lng(en|cn|tc)/:subsidiary(bullion)/terms_of_use">
      <OldTermPage />
    </Route>
    <Route path="/:lng(en|cn|tc)/:subsidiary(bullion)/privacy_policy">
      <OldPolicyPage />
    </Route>
    <Route path="/:lng(en|cn|tc)/:subsidiary(bullion)/ib_program">
      <IBProgramPage />
    </Route>
    <Route path="/:lng(en|cn|tc)/:subsidiary(bullion)/welcome_rewards">
      <WelcomeRewardPage />
    </Route>
    <Route exact path="/:lng(en|cn|tc)/:subsidiary(bullion)">
      <BullionHomePage />
    </Route>

    <Route path="/:lng(en|cn|tc)/home/intermediate/:subsidiary(au|bullion)">
      <OldIntermediatePage />
    </Route>

    {/* Root Redirection */}
    <Redirect exact from="/" to={`/${language}`} />
    <Redirect exact from="/:lng(en|cn|tc)" to="/:lng/home" />

    {/* Old AU Routes Redirection */}
    <Redirect
      from="/product_service/au/:lng(en|cn|tc)/:id"
      to="/:lng/au/product/:id"
    />
    <Redirect
      from="/account_open/au/:lng(en|cn|tc)"
      to="/:lng/au/trading_with_us/account_types"
    />
    <Redirect
      from="/platform/:tab/au/:lng(en|cn|tc)"
      to="/:lng/au/platform/:tab"
    />
    <Redirect
      from="/:lng(en|cn|tc)/au/about_us/legal_documents"
      to="/:lng/au/legal_documents"
    />
    <Redirect
      from="/legal_documents/au/:lng(en|cn|tc)"
      to="/:lng/au/legal_documents"
    />
    <Redirect
      from="/deposit_withdraw/au/:lng(en|cn|tc)"
      to="/:lng/au/client_resources"
    />
    <Redirect
      from="/deposit_withdraw/:subsidiary(bullion)/:lng(en|cn|tc)/:id"
      to="/:lng/:subsidiary/:id"
    />
    <Redirect
      from="/deposit_withdraw/:subsidiary(bullion)/:lng(en|cn|tc)"
      to="/:lng/:subsidiary/deposit"
    />

    {/* Old Component Routes */}

    <Redirect
      exact
      from="/:lng(en|cn|tc)/home"
      to="/:lng/home/about_us/about_clsap"
    />

    <Route exact path="/:lng(en|cn|tc)/home">
      <LayoutWrapper>
        <Homepage />
      </LayoutWrapper>
    </Route>
    <Route exact path="/:lng(en|cn|tc)/:subsidiary(au)">
      <LayoutWrapper>
        <SummaryAUPage />
      </LayoutWrapper>
    </Route>
    <Route exact path="/:lng(en|cn|tc)/:subsidiary(hk)">
      <LayoutWrapper>
        <SummaryHKPage />
      </LayoutWrapper>
    </Route>
    <Route exact path="/:lng(en|cn|tc)/:subsidiary(nz)">
      <LayoutWrapper>
        <SummaryNZPage />
      </LayoutWrapper>
    </Route>

    <Redirect
      from="/:lng(en|cn|tc)/about_us/:id"
      to="/:lng/home/about_us/:id"
    />
    <Redirect
      exact
      from="/:lng(en|cn|tc)/about_us"
      to="/:lng/home/about_us/about_clsap"
    />
    <Route path="/:lng(en|cn|tc)/:subsidiary(home)/about_us/:id">
      <LayoutWrapper>
        <AboutUsPage />
      </LayoutWrapper>
    </Route>

    <Redirect from="/:lng(en|cn|tc)/contact" to="/:lng/home/contact" />
    <Route path="/:lng(en|cn|tc)/:subsidiary(home)/contact">
      <LayoutWrapper>
        <ContactPage />
      </LayoutWrapper>
    </Route>

    <Redirect
      from="/:lng(en|cn|tc)/corporate_governance"
      to="/:lng/home/corporate_governance"
    />
    <Route path="/:lng(en|cn|tc)/:subsidiary(home)/corporate_governance">
      <LayoutWrapper>
        <GovernancePage />
      </LayoutWrapper>
    </Route>

    <Redirect
      from="/:lng(en|cn|tc)/financial_reports"
      to="/:lng/home/financial_reports"
    />
    <Route path="/:lng(en|cn|tc)/:subsidiary(home)/financial_reports">
      <LayoutWrapper>
        <FinancialReportPage />
      </LayoutWrapper>
    </Route>

    <Redirect
      from="/intermediate/:subsidiary(au|bullion|hk|nz)/:lng(en|cn|tc)"
      to="/:lng/home/intermediate/:subsidiary"
    />
    <Route path="/:lng(en|cn|tc)/home/intermediate/:subsidiary(au|bullion|hk|nz)">
      <LayoutWrapper>
        <IntermediatePage />
      </LayoutWrapper>
    </Route>

    <Redirect
      from="/:lng(en|cn|tc)/notifications"
      to="/:lng/home/notifications"
    />
    <Route path="/:lng(en|cn|tc)/:subsidiary(home)/notifications">
      <LayoutWrapper>
        <NotificationPage />
      </LayoutWrapper>
    </Route>

    <Redirect from="/:lng(en|cn|tc)/prospectus" to="/:lng/home/prospectus" />
    <Route path="/:lng(en|cn|tc)/:subsidiary(home)/prospectus">
      <LayoutWrapper>
        <ProspectusPage />
      </LayoutWrapper>
    </Route>

    <Redirect
      from="/:lng(en|cn|tc)/announcement"
      to="/:lng/home/announcement"
    />
    <Route path="/:lng(en|cn|tc)/:subsidiary(home)/announcement">
      <LayoutWrapper>
        <AnnouncementPage />
      </LayoutWrapper>
    </Route>

    <Redirect from="/:lng(en|cn|tc)/disclaimer" to="/:lng/home/disclaimer" />
    <Route path="/:lng(en|cn|tc)/:subsidiary(home)/disclaimer">
      <LayoutWrapper>
        <DisclaimerPage />
      </LayoutWrapper>
    </Route>

    <Redirect
      from="/:lng(en|cn|tc)/privacy_policy/list"
      to="/:lng/home/privacy_policy"
    />
    <Redirect
      from="/privacy_policy/:subsidiary(bullion|hk|nz)/:lng(en|cn|tc)"
      to="/:lng/:subsidiary/privacy_policy"
    />
    <Route path="/:lng(en|cn|tc)/:subsidiary(bullion|hk|home|nz)/privacy_policy">
      <LayoutWrapper>
        <PolicyPage />
      </LayoutWrapper>
    </Route>

    <Redirect
      from="/:lng(en|cn|tc)/terms_of_use/list"
      to="/:lng/home/terms_of_use"
    />
    <Redirect
      from="/terms_of_use/:subsidiary(bullion|hk|nz)/:lng(en|cn|tc)"
      to="/:lng/:subsidiary/terms_of_use"
    />
    <Route path="/:lng(en|cn|tc)/:subsidiary(bullion|hk|home|nz)/terms_of_use">
      <LayoutWrapper>
        <TermPage />
      </LayoutWrapper>
    </Route>

    <Redirect
      from="/account_open/:subsidiary(hk)/:lng(en|cn|tc)"
      to="/:lng/:subsidiary/account_open"
    />
    <Route path="/:lng(en|cn|tc)/:subsidiary(hk|nz)/account_open">
      <LayoutWrapper>
        <AccountOpeningPage />
      </LayoutWrapper>
    </Route>

    <Redirect
      from="/deposit_withdraw/:subsidiary(hk)/:lng(en|cn|tc)/:id"
      to="/:lng/:subsidiary/deposit_withdraw/:id"
    />
    <Route path="/:lng(en|cn|tc)/:subsidiary(hk)/deposit_withdraw/:id">
      <LayoutWrapper>
        <DepositAndWithdrawalPage />
      </LayoutWrapper>
    </Route>

    <Redirect
      from="/legal_documents/:subsidiary(bullion|hk|nz)/:lng(en|cn|tc)/"
      to="/:lng/:subsidiary/legal_documents/"
    />
    <Route path="/:lng(en|cn|tc)/:subsidiary(bullion|hk|nz)/legal_documents">
      <LayoutWrapper>
        <LegalDocPage />
      </LayoutWrapper>
    </Route>

    <Redirect
      from="/platform/:tab/:subsidiary(bullion|hk)/:lng(en|cn|tc)"
      to="/:lng/:subsidiary/platform/:tab"
    />
    <Route path="/:lng(en|cn|tc)/:subsidiary(bullion|hk|nz)/platform/:tab">
      <LayoutWrapper>
        <PlatformPage />
      </LayoutWrapper>
    </Route>

    <Redirect
      from="/product_service/:subsidiary(bullion|hk)/:lng(en|cn|tc)/:id"
      to="/:lng/:subsidiary/product_service/:id"
    />
    <Route path="/:lng(en|cn|tc)/:subsidiary(bullion|hk)/product_service/:id">
      <LayoutWrapper>
        <ProductAndServicePage />
      </LayoutWrapper>
    </Route>

    <Redirect from="/fsg/:subsidiary(hk|nz)" to="/:lng/:subsidiary/fsg/" />
    <Route path="/:lng(en|cn|tc)/:subsidiary(hk|nz)/fsg">
      <LayoutWrapper>
        <FSGPage />
      </LayoutWrapper>
    </Route>

    <Redirect from="/pds/:subsidiary(hk|nz)" to="/:lng/:subsidiary/pds" />
    <Route path="/:lng(en|cn|tc)/:subsidiary(hk|nz)/pds">
      <LayoutWrapper>
        <PDSPage />
      </LayoutWrapper>
    </Route>

    <Redirect
      exact
      from="/:subsidiary(au|bullion|home|hk|nz)/:lng(en|cn|tc)"
      to="/:lng/:subsidiary"
    />
    <Redirect
      from="/:subsidiary(au|bullion|home|hk|nz)"
      to={`/${language}/:subsidiary`}
    />

    <Route>
      <Delay time={1}>
        <LayoutWrapper>
          <NoMatchPage />
        </LayoutWrapper>
      </Delay>
    </Route>

    {/*
.########..########.########..####.########..########..######..########
.##.....##.##.......##.....##..##..##.....##.##.......##....##....##...
.##.....##.##.......##.....##..##..##.....##.##.......##..........##...
.########..######...##.....##..##..########..######...##..........##...
.##...##...##.......##.....##..##..##...##...##.......##..........##...
.##....##..##.......##.....##..##..##....##..##.......##....##....##...
.##.....##.########.########..####.##.....##.########..######.....##...
    */}
    <Redirect
      exact
      strict
      from="/product_service/:sub/:lang(en|cn|tc)"
      to="/product_service/:sub/:lang/forex"
    />
    <Redirect
      exact
      strict
      from="/deposit_withdraw/:sub/:lang(en|cn|tc)"
      to="/deposit_withdraw/:sub/:lang/deposit"
    />

    {/*
.##....##..#######......######..##.....##.########.
.###...##.##.....##....##....##.##.....##.##.....##
.####..##.##.....##....##.......##.....##.##.....##
.##.##.##.##.....##.....######..##.....##.########.
.##..####.##.....##..........##.##.....##.##.....##
.##...###.##.....##....##....##.##.....##.##.....##
.##....##..#######......######...#######..########.
    */}
    <Route exact component={Homepage} path="/" />
    <Route exact component={AboutUsPage} path="/about_us/:id" />
    <Route exact component={AnnouncementPage} path="/announcement" />
    <Route exact component={GovernancePage} path="/corporate_governance" />
    <Route exact component={ProspectusPage} path="/prospectus" />
    <Route exact component={NotificationPage} path="/notifications" />
    <Route exact component={FinancialReportPage} path="/financial_reports" />
    <Route exact component={ContactPage} path="/contact" />
    <Route exact component={Homepage} path="/:lang" />
    <Route exact component={AboutUsPage} path="/:lang/about_us/:id" />
    <Route exact component={AnnouncementPage} path="/:lang/announcement" />
    <Route
      exact
      component={GovernancePage}
      path="/:lang/corporate_governance"
    />
    <Route exact component={ProspectusPage} path="/:lang/prospectus" />
    <Route exact component={NotificationPage} path="/:lang/notifications" />
    <Route
      exact
      component={FinancialReportPage}
      path="/:lang/financial_reports"
    />
    <Route exact component={ContactPage} path="/:lang/contact" />
    <Route exact component={TermPage} path="/:lang/terms_of_use/:id" />
    <Route exact component={PolicyPage} path="/:lang/privacy_policy/:id" />
    <Route exact component={DisclaimerPage} path="/:lang/disclaimer/:id" />

    {/*
..######..##.....##.########...######..####.########..####....###....########..##....##
.##....##.##.....##.##.....##.##....##..##..##.....##..##....##.##...##.....##..##..##.
.##.......##.....##.##.....##.##........##..##.....##..##...##...##..##.....##...####..
..######..##.....##.########...######...##..##.....##..##..##.....##.########.....##...
.......##.##.....##.##.....##.......##..##..##.....##..##..#########.##...##......##...
.##....##.##.....##.##.....##.##....##..##..##.....##..##..##.....##.##....##.....##...
..######...#######..########...######..####.########..####.##.....##.##.....##....##...
    */}
    <Route
      exact
      component={ProductAndServicePage}
      path="/product_service/:sub/:id"
    />
    <Route exact component={ProductAndServicePage} path="/product/:sub/:id" />
    <Route exact component={AccountOpeningPage} path="/account_open/:id" />
    <Route
      exact
      component={DepositAndWithdrawalPage}
      path="/deposit_withdraw/:sub/:id"
    />
    <Route exact component={PlatformPage} path="/platform/:tab/:id" />
    <Route exact component={LegalDocPage} path="/legal_documents/:id" />
    <Route exact component={SummaryHKPage} path="/bullion" />
    <Route exact component={SummaryHKPage} path="/hk" />
    <Route exact component={SummaryAUPage} path="/au" />
    <Route exact component={SummaryNZPage} path="/nz" />
    <Route exact component={IntermediatePage} path="/intermediate/:id" />
    <Route exact component={TermPage} path="/terms_of_use/:id" />
    <Route exact component={PolicyPage} path="/privacy_policy/:id" />
    <Route exact component={DisclaimerPage} path="/disclaimer/:id" />
    <Route exact component={FSGPage} path="/fsg/:id" />
    <Route exact component={PDSPage} path="/pds/:id" />

    <Route
      exact
      component={ProductAndServicePage}
      path="/product_service/:sub/:lang/:id"
    />
    <Route
      exact
      component={ProductAndServicePage}
      path="/product/:sub/:lang/:id"
    />
    <Route
      exact
      component={AccountOpeningPage}
      path="/account_open/:id/:lang"
    />
    <Route
      exact
      component={DepositAndWithdrawalPage}
      path="/deposit_withdraw/:sub/:lang/:id"
    />
    <Route exact component={PlatformPage} path="/platform/:tab/:id/:lang" />
    <Route exact component={LegalDocPage} path="/legal_documents/:id/:lang" />
    <Route exact component={SummaryHKPage} path="/bullion/:lang" />
    <Route exact component={SummaryHKPage} path="/hk/:lang" />
    <Route exact component={SummaryAUPage} path="/au/:lang" />
    <Route exact component={SummaryNZPage} path="/nz/:lang" />
    <Route exact component={IntermediatePage} path="/intermediate/:id/:lang" />
    <Route exact component={TermPage} path="/terms_of_use/:id/:lang" />
    <Route exact component={PolicyPage} path="/privacy_policy/:id/:lang" />
    <Route exact component={DisclaimerPage} path="/disclaimer/:id/:lang" />
    <Route exact component={FSGPage} path="/fsg/:id/:lang" />
    <Route exact component={PDSPage} path="/pds/:id/:lang" />
    <Route component={NoMatchPage} />
  </Switch>
);
