import React, { useState } from 'react';
import { useTranslation, Trans } from "react-i18next";
import { Tab, Nav, Row, Col } from 'react-bootstrap'
import { Helmet } from "react-helmet";


import GroupPolicyEN from '../components/policyAndTerm/GroupPolicyEN';
import GroupPolicyCN from '../components/policyAndTerm/GroupPolicyCN';

import BullionPolicyEN from '../components/policyAndTerm/BullionPolicyEN';

import HKPolicyEN from '../components/policyAndTerm/HKPolicyEN';
import HKPolicyCN from '../components/policyAndTerm/HKPolicyCN';

import AUPolicyEN from '../components/policyAndTerm/AUPolicyEN';
import AUPolicyCN from '../components/policyAndTerm/AUPolicyCN';

import NZPolicyEN from '../components/policyAndTerm/NZPolicyEN';
import NZPolicyCN from '../components/policyAndTerm/NZPolicyCN';

export default function PolicyPage({match}) {

  const { t, i18n } = useTranslation();

  return (
    <div className='content'>
      <Helmet>
        <title>{t("title")}</title>
      </Helmet>

      <section className='container term-and-policy'>
        {i18n.language === 'en' && match.params.id === 'list' && <GroupPolicyEN />}
        {match.params.id === 'bullion' && <BullionPolicyEN />}
        {i18n.language === 'en' && match.params.id === 'hk' && <HKPolicyEN />}
        {i18n.language === 'en' && match.params.id === 'au' && <AUPolicyEN />}
        {i18n.language === 'en' && match.params.id === 'nz' && <NZPolicyEN />}
        {i18n.language !== 'en' && match.params.id === 'list' && <GroupPolicyCN />}
        {i18n.language !== 'en' && match.params.id === 'hk' && <HKPolicyCN />}
        {i18n.language !== 'en' && match.params.id === 'au' && <AUPolicyCN />}
        {i18n.language !== 'en' && match.params.id === 'nz' && <NZPolicyCN />}
      </section>
    </div>
  );
}