import React, { useState } from 'react';
import { useTranslation, Trans } from "react-i18next";


export default function Indices(props) {
  const { t, i18n } = useTranslation();
  const subsidiary = props.subsidiary
  const location = props.location
  const system_time_str = 'system_' + location
  return (
    <div className="indices">

      <div className='block overflow-auto'>
        {subsidiary === "au" && (
          <div className="sticky-top text-right" style={{ left: 0 }}>{t('daylight_saving_hours_apply')}</div>
        )}
        <table className={`index ${subsidiary === "au" ? "mt-2" : "mt-5"}`}>
          <thead>
            <tr>
              <th className="pr-2 py-4 text-nowrap">{t('symbol')}</th>
              <th className="px-4 py-4 text-nowrap">{t('min_spread')}</th>
              <th className="px-4 py-4 text-nowrap">{t('ave_spread')}</th>
              {
                subsidiary === "au" ? (
                  <th className="px-4 py-4 text-nowrap">{t('margin_required')}</th>
                )
                : (<th className="px-4 py-4 text-nowrap">{t('margin_level')}</th>)
              }
              <th className="px-4 py-4 text-nowrap">{t('min_size')}</th>
              <th className="px-4 py-4 text-nowrap">{t('max_size')}</th>
              <th className="px-4 py-4 text-nowrap">{`${t('trading_hours')} (${t('system_time')}: GMT+3)`}</th>
              <th className="pl-2 py-4 text-nowrap">{`${t('trading_hours')} (${t(system_time_str)})`}</th>
            </tr>
          </thead>
          <tbody>
          <tr>
            <td className="text-nowrap">{t('AUS200')}</td>
            <td className="text-nowrap">1.220</td>
            <td className="text-nowrap">2.724</td>
            {
              subsidiary === "au" ? (
                <td className="text-nowrap">5%</td>
              ) : (
                <td className="text-nowrap">3%</td>
              )
            }
            <td className="text-nowrap">1</td>
            <td className="text-nowrap">200</td>
            <td className="text-nowrap">02:50-09:30, 10:10-00:00</td>
            {subsidiary === 'au' && <td className="text-nowrap">09:50-16:30, 17:10-07:00</td>}
            {subsidiary === 'nz' && <td className="text-nowrap">11:50-18:30, 19:10-09:00</td>}
          </tr>
          <tr>
            <td className="text-nowrap">{t('UK100')}</td>
            <td className="text-nowrap">1.050</td>
            <td className="text-nowrap">2.014</td>
            {
              subsidiary === "au" ? (
                <td className="text-nowrap">5%</td>
              ) : (
                <td className="text-nowrap">3%</td>
              )
            }
            <td className="text-nowrap">1</td>
            <td className="text-nowrap">100</td>
            <td className="text-nowrap">03:00-23:00</td>
            {subsidiary === 'au' && <td className="text-nowrap">10:00-05:00</td>}
            {subsidiary === 'nz' && <td className="text-nowrap">13:00-09:00</td>}
          </tr>
          {/* <tr>
            <td className="text-nowrap">{t('CHINA50')}</td>
            <td className="text-nowrap">1.500</td>
            <td className="text-nowrap">6.477</td>
            <td className="text-nowrap">5%</td>
            <td className="text-nowrap">1</td>
            <td className="text-nowrap">100</td>
            <td className="text-nowrap">3:00-10:30, 11:00-22:45</td>
            {subsidiary === 'au' && <td className="text-nowrap">12:00-19:30, 20:00-07:45</td>}
            {subsidiary === 'nz' && <td className="text-nowrap">14:00-21:30, 22:00-09:45</td>}
          </tr> */}
          <tr>
            <td className="text-nowrap">{t('H-SHARES')}</td>
            <td className="text-nowrap">9.300</td>
            <td className="text-nowrap">11.050</td>
            {
              subsidiary === "au" ? (
                <td className="text-nowrap">10%</td>
              ) : (
                <td className="text-nowrap">3%</td>
              )
            }
            <td className="text-nowrap">1</td>
            <td className="text-nowrap">250</td>
            <td className="px-2 text-nowrap">04:15-07:00, 08:00-11:30, 12:15-22:00</td>
            {subsidiary === 'au' && <td className="px-2 text-nowrap">11:15-14:00, 15:00-18:30, 19:15-05:00</td>}
            {subsidiary === 'nz' && <td className="px-2 text-nowrap">14:15-17:00, 18:00-21:00, 22:15-08:00</td>}
          </tr>
          <tr>
            <td className="text-nowrap">{t('HK50')}</td>
            <td className="text-nowrap">16.400</td>
            <td className="text-nowrap">18.612</td>
            {
              subsidiary === "au" ? (
                <td className="text-nowrap">10%</td>
              ) : (
                <td className="text-nowrap">3%</td>
              )
            }
            <td className="text-nowrap">1</td>
            <td className="text-nowrap">250</td>
            <td className="px-2 text-nowrap">04:15-07:00, 08:00-11:30, 12:15-22:00</td>
            {subsidiary === 'au' && <td className="px-2 text-nowrap">11:15-14:00, 15:00-18:30, 19:15-05:00</td>}
            {subsidiary === 'nz' && <td className="px-2 text-nowrap">14:15-17:00, 18:00-21:00, 22:15-08:00</td>}
          </tr>
          <tr>
            <td className="text-nowrap">{t('JP225')}</td>
            <td className="text-nowrap">16.640</td>
            <td className="text-nowrap">24.259</td>
            {
              subsidiary === "au" ? (
                <td className="text-nowrap">5%</td>
              ) : (
                <td className="text-nowrap">3%</td>
              )
            }
            <td className="text-nowrap">10</td>
            <td className="text-nowrap">1000</td>
            <td className="text-nowrap">01:01 - 23:59</td>
            {subsidiary === 'au' && <td className="text-nowrap">08:01 - 06:59</td>}
            {subsidiary === 'nz' && <td className="text-nowrap">11:01 - 09:59</td>}
          </tr>
          {/* <tr>
            <td className="text-nowrap">{t('SG30')}</td>
            <td className="text-nowrap">1.260</td>
            <td className="text-nowrap">1.406</td>
            <td className="text-nowrap">5%</td>
            <td className="text-nowrap">1</td>
            <td className="text-nowrap">1000</td>
            <td className="text-nowrap">02:30-11:20, 11:50-22:45</td>
            {subsidiary === 'au' && <td className="text-nowrap">11:30-20:20, 20:50-07:45</td>}
            {subsidiary === 'nz' && <td className="text-nowrap">13:30-22:20, 22:50-09:45</td>}
          </tr> */}
          <tr>
            <td className="text-nowrap">{t('NDX100')}</td>
            <td className="text-nowrap">2.800</td>
            <td className="text-nowrap">3.753</td>
            {
              subsidiary === "au" ? (
                <td className="text-nowrap">5%</td>
              ) : (
                <td className="text-nowrap">2%</td>
              )
            }
            <td className="text-nowrap">1</td>
            <td className="text-nowrap">250</td>
            <td className="text-nowrap">01:00-23:15; 23:30-00:00</td>
            {subsidiary === 'au' && <td className="text-nowrap">08:00-06:15, 06:30-07:00</td>}
            {subsidiary === 'nz' && <td className="text-nowrap">11:00-09:15, 09:30-10:00</td>}
          </tr>
          <tr>
            <td className="text-nowrap">{t('DJ30')}</td>
            <td className="text-nowrap">1.040</td>
            <td className="text-nowrap">2.191</td>
            {
              subsidiary === "au" ? (
                <td className="text-nowrap">5%</td>
              ) : (
                <td className="text-nowrap">2%</td>
              )
            }
            <td className="text-nowrap">1</td>
            <td className="text-nowrap">250</td>
            <td className="text-nowrap">01:00-23:15; 23:30-00:00</td>
            {subsidiary === 'au' && <td className="text-nowrap">08:00-06:15, 06:30-07:00</td>}
            {subsidiary === 'nz' && <td className="text-nowrap">11:00-09:15, 09:30-10:00</td>}
          </tr>
          <tr>
            <td className="text-nowrap">{t('SP500')}</td>
            <td className="text-nowrap">1.400</td>
            <td className="text-nowrap">1.741</td>
            {
              subsidiary === "au" ? (
                <td className="text-nowrap">5%</td>
              ) : (
                <td className="text-nowrap">2%</td>
              )
            }
            <td className="text-nowrap">1</td>
            <td className="text-nowrap">1000</td>
            <td className="text-nowrap">01:00-23:15; 23:30-00:00</td>
            {subsidiary === 'au' && <td className="text-nowrap">08:00-06:15, 06:30-07:00</td>}
            {subsidiary === 'nz' && <td className="text-nowrap">11:00-09:15, 09:30-10:00</td>}
          </tr>

          <tr>
            <td className="text-nowrap">{t('USDX')}</td>
            <td className="text-nowrap">16.002</td>
            <td className="text-nowrap">16.012</td>
            {
              subsidiary === "au" ? (
                <td className="text-nowrap">20%</td>
              ) : (
                <td className="text-nowrap">5%</td>
              )
            }
            <td className="text-nowrap">0.01</td>
            <td className="text-nowrap">100</td>
            <td className="text-nowrap">03:00-00:00</td>
            {subsidiary === 'au' && <td className="text-nowrap">10:00-07:00</td>}
            {subsidiary === 'nz' && <td className="text-nowrap">13:00-10:00</td>}
          </tr>
          <tr>
            <td className="text-nowrap">{t('VIX')}</td>
            <td className="text-nowrap">0.120</td>
            <td className="text-nowrap">0.189</td>
            {
              subsidiary === "au" ? (
                <td className="text-nowrap">20%</td>
              ) : (
                <td className="text-nowrap">10%</td>
              )
            }
            <td className="text-nowrap">0.1</td>
            <td className="text-nowrap">100</td>
            <td className="text-nowrap">01:00-00:00</td>
            {subsidiary === 'au' && <td className="text-nowrap">08:00-07:00</td>}
            {subsidiary === 'nz' && <td className="text-nowrap">11:00-10:00</td>}
          </tr>
          {subsidiary === 'au' ? (
            <>
              <tr>
                <td className="text-nowrap">{t('German30')}</td>
                <td className="text-nowrap">1.600</td>
                <td className="text-nowrap">7.200</td>
                <td className="text-nowrap">5%</td>
                <td className="text-nowrap">1</td>
                <td className="text-nowrap">100</td>
                <td className="text-nowrap">01:01-23:59</td>
                <td className="text-nowrap">08:01-06:59 </td>
              </tr>
              <tr>
                <td className="text-nowrap">{t('EURO50')}</td>
                <td className="text-nowrap">1.600</td>
                <td className="text-nowrap">6.800</td>
                <td className="text-nowrap">5%</td>
                <td className="text-nowrap">1</td>
                <td className="text-nowrap">100</td>
                <td className="text-nowrap">01:01-23:59</td>
                <td className="text-nowrap">08:01-06:59</td>
              </tr>
            </>
          ) : null}
          </tbody>
        </table>
      </div>
      {subsidiary === 'au' ? (
          <div className='comment mt-5'>
            <p className="font-weight-bold">{t('indices_comment_1')}</p>
            <p className="font-weight-bold">{t('indices_comment_2')}</p>
          </div>
        ) : null
      }
    </div>
  )
}
