import React from "react";
import CardDeck from "react-bootstrap/CardDeck";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Title from "./Title";

const CardBelt = ({
  brief,
  children,
  className = "",
  cards,
  title,
  titleProps,
  ...props
}) => (
  <Container className={`card-belt ${className}`} {...props}>
    <Row>
      <Container>
        {title && (
          <Row>
            <Col className="mb-4" xs={12}>
              <Title as="h2" display={5} {...titleProps}>
                {title}
              </Title>
            </Col>
          </Row>
        )}
        <Row>
          <Col className="mb-4" xs={12}>
            <CardDeck>{children}</CardDeck>
          </Col>
        </Row>
      </Container>
    </Row>
  </Container>
);

export default CardBelt;
