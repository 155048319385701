import React, { useState } from 'react';
import { useTranslation, Trans } from "react-i18next";


export default function Milestone() {
  const { t, i18n } = useTranslation();

  return (
  	<div className='milestone'>
      <div className='block'>
        <div className='year'>2001</div>
        <div>{t('hm_1')}</div>
      </div>
      <div className='block'>
        <div className='year'>2002</div>
        <div>{t('hm_2')}</div>
        <div>{t('hm_3')}</div>
      </div>
      <div className='block'>
        <div className='year'>2003</div>
        <div>{t('hm_5')}</div>
      </div>
      <div className='block'>
        <div className='year'>2004</div>
        <div>{t('hm_6')}</div>
      </div>
      <div className='block'>
        <div className='year'>2005</div>
        <div>{t('hm_8')}</div>
        <div>{t('hm_9')}</div>
      </div>
      <div className='block'>
        <div className='year'>2006</div>
        <div>{t('hm_10')}</div>
      </div>
      <div className='block'>
        <div className='year'>2009</div>
        <div>{t('hm_14')}</div>
        <div>{t('hm_16')}</div>
      </div>
      <div className='block'>
        <div className='year'>2010</div>
        <div>{t('hm_17')}</div>
      </div>
      <div className='block'>
        <div className='year'>2011</div>
        <div>{t('hm_19')}</div>
      </div>
      <div className='block'>
        <div className='year'>2013</div>
        <div>{t('hm_20')}</div>
        <div>{t('hm_21')}</div>
      </div>
      <div className='block'>
        <div className='year'>2014</div>
        <div>{t('hm_22')}</div>
      </div>
      <div className='block'>
        <div className='year'>2015</div>
        <div>{t('hm_23')}</div>
      </div>
      <div className='block'>
        <div className='year'>2016</div>
        <div>{t('hm_24')}</div>
      </div>
      <div className='block'>
        <div className='year'>2017</div>
        <div>{t('hm_25')}</div>
      </div>
      <div className='block'>
        <div className='year'>2019</div>
        <div>{t('hm_26')}</div>
      </div>
    </div>
  )
}