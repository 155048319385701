import _get from "lodash/get";
import _map from "lodash/map";
import _size from "lodash/size";
import React, { useState } from "react";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import styled from "styled-components";
import Pagination from "./Pagination";
import Table from "./Table";
import Text from "./Text";

const StyledNavLink = styled(Nav.Link)`
  &.active,
  &:hover {
    color: ${({ theme: { success: color } }) => color} !important;
  }

  &.active {
    border-bottom-style: solid;
    border-bottom-width: 2px;
  }
`;

const TabTables = ({
  defaultActiveKey = 0,
  tables = [],
  tableProps,
  navProps: { className: navClassNames = "", ...navProps } = {},
  ...props
}) => {
  const [page, setPage] = useState(1);
  const [table, setTable] = useState(_get(tables, [defaultActiveKey, "value"]));
  return (
    <Tab.Container
      defaultActiveKey={defaultActiveKey}
      onSelect={(activeKey) => {
        setPage(1);
        setTable(_get(tables, [activeKey, "value"]));
      }}
      {...props}
    >
      <Nav className={`mb-3 ${navClassNames}`} variant="pills" {...navProps}>
        {_map(tables, ({ label }, key) => (
          <Nav.Item key={key}>
            <StyledNavLink
              className="border-success bg-transparent rounded-0"
              eventKey={key}
            >
              <Text>{label}</Text>
            </StyledNavLink>
          </Nav.Item>
        ))}
      </Nav>
      <Tab.Content className="mb-5 shadow">
        {_map(tables, ({ value }, key) => (
          <Tab.Pane eventKey={key} key={key}>
            <Table page={page} {...value} {...tableProps} />
          </Tab.Pane>
        ))}
      </Tab.Content>
      {table.pagesize ? (
        <Pagination
          className="justify-content-end"
          page={page}
          totalPages={Math.ceil(
            _size(_get(table, "body")) / _get(table, "pagesize")
          )}
          onPageChange={setPage}
        />
      ) : null}
    </Tab.Container>
  );
};

export default TabTables;
