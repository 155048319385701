import React from "react";
import { useTranslation } from "react-i18next";

/**
 * @param props
 */
export default function PreviousMetals(props) {
  const { t, i18n } = useTranslation();

  const { subsidiary } = props;
  const subParam = {
    param1:
      subsidiary === "au"
        ? t("sub_au")
        : subsidiary === "hk"
        ? t("sub_hk")
        : t("sub_nz"),
  };

  return (
    <div className="forex">
      <div className="block">
        <div className="title">
          <h2>{t("forex_spec")}</h2>
        </div>
        <table className="spec mt-5">
          <tbody>
            <tr>
              <td>{t("contract_size")}</td>
              <td>{t("contract_size_gold_value")}</td>
            </tr>
            <tr>
              <td>{t("required_margin")}</td>
              <td>1%</td>
            </tr>
            <tr>
              <td>{t("nearest_level")}</td>
              <td>{t("nearest_level_value")}</td>
            </tr>
            <tr>
              <td>{t("profit_loss_cal", { context: subsidiary })}</td>
              <td>{t("profit_loss_cal_value")}</td>
            </tr>

            <tr>
              <td>{t("roll_over_swap", { context: subsidiary })}</td>
              <td>
                {t("roll_over_swap_value", {
                  context: subsidiary,
                  ...subParam,
                })}
              </td>
            </tr>
            <tr>
              <td>{t("trading_hour")}</td>
              <td>{t("trading_hour_value", { context: subsidiary })}</td>
            </tr>
            <tr>
              <td>{t("trading_breaks")}</td>
              <td>{t("trading_breaks_value_gold_au")}</td>
            </tr>
          </tbody>
        </table>
        {subsidiary === "au" ? (
          <div className="comment mt-5">
            <p>
              <b>{t("forex_spec_comment_key_1")}</b> {t("forex_spec_comment_1")}
            </p>
            <p>
              <b>{t("forex_spec_comment_key_2")}</b> {t("forex_spec_comment_2")}
            </p>
          </div>
        ) : null}
      </div>
      <div className="block fluc">
        <div className="title">
          <h2>{t("product_detail")}</h2>
        </div>
        <div className="row">
          <div className="col-md-12 overflow-auto mt-5">
            <table>
              <thead>
                <tr>
                  <th className="pr-5 py-4 text-nowrap sticky-top">
                    {t("symbol")}
                  </th>
                  <th className="pr-5 py-4 text-nowrap sticky-top">
                    {t("min_tradable_lot")}
                  </th>
                  <th className="pr-5 py-4 text-nowrap sticky-top">
                    {t("max_tradable_lot")}
                  </th>
                  <th className="pr-5 py-4 text-nowrap sticky-top">
                    {t("min_incremental_lot")}
                  </th>
                  <th className="pr-5 py-4 text-nowrap sticky-top">
                    {t("min_spread_star")}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>XAUUSD</td>
                  <td>0.01</td>
                  <td>50</td>
                  <td>0.01</td>
                  <td>0.40</td>
                </tr>
                <tr>
                  <td>XAGUSD</td>
                  <td>0.5</td>
                  <td>100</td>
                  <td>0.5</td>
                  <td>0.02</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
