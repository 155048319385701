import React, { useState } from 'react';
import { useTranslation, Trans } from "react-i18next";
import { Tab, Nav, Row, Col } from 'react-bootstrap'
import { Helmet } from "react-helmet";

import BullionTermEN from '../components/policyAndTerm/BullionTermEN';

import GroupTermEN from '../components/policyAndTerm/GroupTermEN';
import GroupTermCN from '../components/policyAndTerm/GroupTermCN';

import HKTermEN from '../components/policyAndTerm/HKTermEN';
import HKTermCN from '../components/policyAndTerm/HKTermCN';

import AUTermEN from '../components/policyAndTerm/AUTermEN';
import AUTermCN from '../components/policyAndTerm/AUTermCN';

import NZTermEN from '../components/policyAndTerm/NZTermEN';
import NZTermCN from '../components/policyAndTerm/NZTermCN';


export default function TermPage({match}) {

  const { t, i18n } = useTranslation();

  return (
    <div className='content'>
      <Helmet>
        <title>{t("title")}</title>
      </Helmet>

      <section className='container term-and-policy'>
        {i18n.language === 'en' && match.params.id === 'list' && <GroupTermEN />}
        {match.params.id === 'bullion' && <BullionTermEN />}
        {i18n.language === 'en' && match.params.id === 'hk' && <HKTermEN />}
        {i18n.language === 'en' && match.params.id === 'au' && <AUTermEN />}
        {i18n.language === 'en' && match.params.id === 'nz' && <NZTermEN />}
        {i18n.language !== 'en' && match.params.id === 'list' && <GroupTermCN />}
        {i18n.language !== 'en' && match.params.id === 'hk' && <HKTermCN />}
        {i18n.language !== 'en' && match.params.id === 'au' && <AUTermCN />}
        {i18n.language !== 'en' && match.params.id === 'nz' && <NZTermCN />}
      </section>
    </div>
  );
}