import React, { useState, useEffect } from 'react';
import { useTranslation, Trans } from "react-i18next";
import { Tab, Nav, Row, Col } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import fetch from 'isomorphic-fetch'
import _ from 'lodash'
import { Helmet } from "react-helmet";

import Banner from '../components/Banner'

export default function AnnouncementPage({match}) {
  
  const { t, i18n } = useTranslation();
  const [data, setData] = useState([]);
  let lang = (i18n.language === 'cn' || i18n.language === 'tc') ? 'cn' : 'en'

  useEffect(()=>{
    fetch(`/api/announcements?lang=${lang}`)
    .then(res => res.json())
    .then(body => {
      // filter item by publish time
      _.remove(body, (v,key)=>{
        return v.ATTR.published_at && new Date() < new Date(v.ATTR.published_at)
      })

      // Sory by date desc
      const announcements = body.sort(({ ATTR: { date: date1 } }, { ATTR: { date: date2 } }) => {
        const toDate = (date) => new Date(...(date.split("/").reverse()));
        return toDate(date2) - toDate(date1);
      })

      let list = _.map(announcements, (v,key)=>{
        return(
          <li key={key}>
            <ul>
              <li className="w3-col l2 m2">{v.ATTR.date}</li>
              <li className="w3-col l10 m10"><a href={v.ATTR.fileUrl} target="_blank">{v.ATTR.headline}</a></li>
            </ul>
          </li>
        )
      })
      list.unshift(
        <li className="title" key='title'>
          <ul className="w3-row">
            <li className="w3-col l2 m2">Release Date</li>
            <li className="w3-col l10 m10">Document</li>
          </ul>
        </li>
      )
      setData(list)
      //console.log(body)
    })
  },[lang])

  return (
    <div className='content'>
      <Helmet>
        <title>{`${t("announcement")} | CLSA Premium`}</title>
      </Helmet>
      <Banner title={t('announcement')} bg={'financial_reports'} subsidiary={match.params.id}/>
      <section className='container announcement'>
        <ul id="YearSectionContent" className="relationList">
          {_.isEmpty(data) ? <div className='loading'><FontAwesomeIcon icon={faSpinner} size="lg" pulse /></div> : data}
          
        </ul>
      </section>
    </div>
  );
}