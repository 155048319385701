import React from "react";
import useTranslate from "../hooks/useTranslate";
import Paragraph from "./Paragraph";

const Disclaimer = ({ className = "", ...props }) => {
  const { config: disclaimer } = useTranslate({ configKey: "disclaimer" });
  return (
    <Paragraph
      className={`disclaimer mb-0 text-secondary ${className}`}
      font={{ size: "sm" }}
      {...props}
    >
      {disclaimer}
    </Paragraph>
  );
};

export default Disclaimer;
