import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faBookReader,
  faCheck,
  faCoins,
  faCubes,
  faDollarSign,
  faLandmark,
  faStopwatch,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _map from "lodash/map";
import React from "react";
import Badge from "react-bootstrap/Badge";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import styled from "styled-components";
import Buttons from "../components/Buttons";
import CardBelt from "../components/CardBelt";
import IconBelt from "../components/IconBelt";
import Layout from "../components/Layout";
import Paragraph from "../components/Paragraph";
import Text from "../components/Text";
import Title from "../components/Title";
import TitleSplitter from "../components/TitleSplitter";
import TopBelt from "../components/TopBelt";
import useResponsive from "../hooks/useResponsive";
import useTranslate from "../hooks/useTranslate";

library.add(
  faBookReader,
  faCheck,
  faCoins,
  faCubes,
  faDollarSign,
  faLandmark,
  faStopwatch,
  faTimes
);

const StyledDiv = styled.div`
  left: 0;
  top: 0;
`;

const CardBadge = ({ children = "" }) => (
  <StyledDiv className="position-absolute">
    <Badge className="px-4 rounded-0 font-weight-normal" variant="success">
      <Text as="h4">{children}</Text>
    </Badge>
  </StyledDiv>
);

const AccountTypesPage = ({ className = "", ...props }) => {
  const { isNotMobile } = useResponsive();
  const { config } = useTranslate({ configKey: "account-types" });

  const {
    banner: bannerProps,
    helmet: helmetProps,
    "icon-belt": IconBeltConfig,
    intro: introConfig,
    "top-belt": topBeltProps,
    types: typesConfig,
  } = config || {};

  return (
    <Layout
      bannerProps={{
        variant: "left-bottom",
        ...bannerProps,
      }}
      helmetProps={helmetProps}
    >
      {config ? (
        <>
          <Container className={`account-types-page ${className}`} {...props}>
            {introConfig && (
              <Row className="intro mb-3 mt-5 py-5">
                <Col className="text-center">
                  <Title as="h2" display={isNotMobile ? 5 : 7}>
                    {introConfig.title}
                  </Title>
                  <Paragraph>{introConfig.brief}</Paragraph>
                </Col>
              </Row>
            )}
          </Container>
          {typesConfig && (
            <CardBelt className="types mb-5">
              {_map(
                typesConfig,
                ({ badge, brief, buttons, feature, title, type }, key) => (
                  <Card
                    className="border-0 px-4 py-5 rounded-0 shadow"
                    key={key}
                  >
                    {badge && <CardBadge>{badge}</CardBadge>}
                    <Card.Body className="d-flex flex-column">
                      <Card.Title>
                        <Title
                          as="h3"
                          className="font-weight-bold"
                          font={{ family: "text" }}
                        >
                          {title}
                        </Title>
                      </Card.Title>
                      <Paragraph as={Card.Text}>{brief}</Paragraph>
                      <TitleSplitter color="success" width={2} />
                      {feature && (
                        <div className="feature my-5">
                          <Title
                            as="h5"
                            className="text-primary"
                            font={{ family: "text" }}
                          >
                            {feature.title}
                          </Title>
                          {_map(feature.list, ({ label, value }, key) => (
                            <p key={key}>
                              <FontAwesomeIcon
                                className={
                                  value === "times"
                                    ? "text-danger"
                                    : "text-primary"
                                }
                                icon={value}
                              />
                              <Text className="ml-2">{label}</Text>
                            </p>
                          ))}
                        </div>
                      )}
                      {type && (
                        <div className="type mt-auto">
                          <Title
                            as="h4"
                            className="text-primary"
                            font={{ family: "text" }}
                          >
                            {type.title}
                          </Title>
                          <TitleSplitter
                            color="success"
                            className="mb-3"
                            width={2}
                          />
                          {_map(type.list, ({ title, list }) => (
                            <div className="d-inline-block mr-5">
                              <Title
                                as="h5"
                                className="text-primary"
                                font={{ family: "text" }}
                              >
                                {title}
                              </Title>
                              {_map(list, (value) => (
                                <Text className="d-block mb-2">{value}</Text>
                              ))}
                            </div>
                          ))}
                        </div>
                      )}
                    </Card.Body>
                    <Card.Footer className="bg-transparent border-0">
                      <Buttons
                        buttonProps={{
                          className: `px-5 py-2 ${
                            isNotMobile ? "w-auto" : "w-100"
                          }`,
                        }}
                        config={buttons}
                      />
                    </Card.Footer>
                  </Card>
                )
              )}
            </CardBelt>
          )}
          {IconBeltConfig && (
            <IconBelt
              fluid
              bg="light"
              innerProps={{ className: "px-4 py-5 my-5" }}
              {...IconBeltConfig}
            />
          )}
          {topBeltProps && (
            <TopBelt backgroundProps={{ height: 450 }} {...topBeltProps} />
          )}
        </>
      ) : null}
    </Layout>
  );
};

export default AccountTypesPage;
