import React, { useEffect, useState } from 'react';
import { useTranslation, Trans } from "react-i18next";
import { Tabs, Tab } from 'react-bootstrap'
import { Helmet } from "react-helmet";
import { useHistory } from 'react-router-dom';

import Banner from '../components/Banner'
import Forex from '../components/product/Forex'
import PreciousMetals from '../components/product/PreciousMetals'
import BullionPreviousMetals from '../components/product/BullionPreviousMetals'
import Indices from '../components/product/Indices'
import Commodities from '../components/product/Commodities'
import Disclaimer from '../components/product/Disclaimer'

export default function ProductAndServicePage({match}) {
  const { push } = useHistory();
  const { id: tab, sub } = match.params;
  // Declare a new state variable, which we'll call "count"
  const [lang, setLang] = useState('English');
  // const [tab, setTab] = useState(id);
  const { t, i18n } = useTranslation();
  const handleTabSelect = (eventKey)=>{
    // setTab(eventKey)
    push(`/product_service/${sub}/${i18n.language}/${eventKey}`)
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  // useEffect(() => {
  //   setTab(id);
  // }, [id])

  return (
    <div className='content'>
      <Helmet>
        <title>{t("ps_title", {
          context: sub,
          param: t(
            sub === "au"
              ? "australia"
              : sub === "nz"
              ? "new_zealand"
              : null
          )
        })}</title>
        {sub === 'au' &&
          <meta name="description" content={t("ps_description_au")}></meta>
        }
      </Helmet>
      <Banner title={t(sub === 'au' ? tab : 'product_service')} bg={'product_service'} />
      <section className='container product'>
        <Tabs activeKey={tab} id="uncontrolled-tab-example"
          onSelect={handleTabSelect}>
          {sub !== 'bullion' && <Tab eventKey="forex" title={t('forex_trading')}>
            <Forex subsidiary={sub} />
          </Tab>}
          {sub !== 'hk' &&
            <Tab eventKey="precious_metals" title={t('precious_metals')}>
            {sub === 'bullion' ? <BullionPreviousMetals subsidiary={sub} /> : <PreciousMetals subsidiary={sub} />}
          </Tab>
          }
          {sub !== 'hk' && sub !== 'bullion' &&
          <Tab eventKey="commodities" title={t('commodities')}>
            <Commodities subsidiary={sub} />
          </Tab>
          }
          {sub !== 'hk' && sub !== 'bullion' &&
          <Tab eventKey="indices" title={t('indices')}>
            <Indices location={sub} subsidiary={sub} />
          </Tab>
          }
        </Tabs>
        <Disclaimer subsidiary={sub} tabSelected={tab}/>

      </section>
    </div>
  );
}