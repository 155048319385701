import React, { useState,useEffect } from 'react';
import { useTranslation, Trans } from "react-i18next";
import { Tabs, Tab } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { Helmet } from "react-helmet";

import Banner from '../components/Banner'

export default function AccountOpeningPage({match}) {

  const { t, i18n } = useTranslation();

  const pdfLangType = i18n.language === 'en' ? 'EN' : 'CN'

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const i18nOptions = {
    keySeparator: false
  };

  return (
    <div className='content'>
      <Helmet>
        <title>
          {t("ao_title", {
            param: t(
              match.params.id === "au"
                ? "australia"
                : match.params.id === "nz"
                ? "new_zealand"
                : null
            ),
          })}
        </title>
        {match.params.id === "au" && (
          <meta name="description" content={t("ao_description_au")}></meta>
        )}
      </Helmet>
      <Banner title={t('account_open')} bg={'account_open'} />
      <section className='container account-open'>
        <Tabs defaultActiveKey={match.params.id} id="uncontrolled-tab-example">
          <Tab eventKey="bullion" title={t('hong_kong', { context: match.params.id })}>
            <div>
              <h2>{t('ao_hk_title')}</h2>

              <div className='block'>
                <h5 className='title'>{t('ao_individual_account')}</h5>
                {/* <div className='brief'>{t('ao_ia_brief')}</div> */}
                <div className='para'>
                  <div>{t('ao_ia_1')}</div>
                  <div>{t('ao_ia_1_brief')}</div>
                  <ol>
                    <li>
                      {t('ao_ia_1.1', i18nOptions)}
                      <br />
                      {t('ao_ia_1.1_brief', i18nOptions)}
                    </li>
                    <li>
                      {t('ao_ia_1.2', i18nOptions)}
                      <br />
                      {t('ao_ia_1.2_brief', i18nOptions)}
                    </li>
                  </ol>
                  <div>{t('ao_ia_1_note')}</div>
                  <a className="btn m-4" href="mailto:services.bullion@clsapremium.com?subject=預約親身開戶 / Face-to-face application&body=請填寫聯絡資料以便本公司客戶服務代表可以儘快聯絡您，謝謝！%0D%0APlease fill in your information for our client services representatives to contact you. Thank you!%0D%0A%0D%0A姓名 name：%0D%0A電話號碼 phone：%0D%0A%0D%0A客戶服務代表將於2個工作天內聯絡您。感謝您使用CLSA Premium服務！%0D%0AOur client services representatives will contact you within 2 business days. Thank you for using CLSA Premium services! %0D%0A%0D%0ACLSA Premium Bullion Limited%0D%0A852 2600 8000%0D%0Aservices.bullion@clsapremium.com%0D%0ASuites 7501 %26 7508, 75/F, International Commerce Centre, 1 Austin Road West, Kowloon, Hong Kong%0D%0A">{t('face_to_face_application')}</a>
                </div>
                <div className='para'>
                  <div>{t('ao_ia_2')}</div>
                  <div>{t('ao_ia_2_brief')}</div>
                  <div>{t('ao_ia_2_note')}</div>
                  {/* <ul>
                    <li>{t('ao_ia_2.1', i18nOptions)}</li>
                    <li>{t('ao_ia_2.2', i18nOptions)}</li>
                    <li>{t('ao_ia_2.3', i18nOptions)}</li>
                  </ul> */}
                  <a className="btn m-4" href="mailto:services.bullion@clsapremium.com?subject=網上開戶 / Online Application&body=請填寫聯絡資料以便本公司客戶服務代表可以儘快聯絡您，謝謝！%0D%0APlease fill in your information for our client services representatives to contact you. Thank you!%0D%0A%0D%0A姓名 name：%0D%0A電話號碼 phone：%0D%0A%0D%0A客戶服務代表將於2個工作天內聯絡您。感謝您使用CLSA Premium服務！%0D%0AOur client services representatives will contact you within 2 business days. Thank you for using CLSA Premium services! %0D%0A%0D%0ACLSA Premium Bullion Limited %0D%0A852 2600 8000%0D%0Aservices.bullion@clsapremium.com%0D%0ASuites 7501 %26 7508, 75/F, International Commerce Centre, 1 Austin Road West, Kowloon, Hong Kong%0D%0A">{t('online_application')}</a>
                </div>
                {/* <div className='para'>
                  <div>{t('ao_ia_3')}</div>
                  <ul>
                    <li>{t('ao_ia_3.1', i18nOptions)}</li>
                    <li>{t('ao_ia_3.2', i18nOptions)}</li>
                    <li>{t('ao_ia_3.3', i18nOptions)}</li>
                  </ul>
                </div> */}
              </div>

              <div className='block'>
                <h5 className='title'>{t('ao_company_account')}</h5>
                <div className='para'>
                  <div>{t('ao_ca_brief')}</div>
                  <a className="btn m-4" href="mailto:services.bullion@clsapremium.com?subject=公司帳戶 / Corporate Account Application&body=請填寫聯絡資料以便本公司客戶服務代表可以儘快聯絡您，謝謝！%0D%0APlease fill in your information for our client services representatives to contact you. Thank you!%0D%0A%0D%0A聯絡姓名 name：%0D%0A電話號碼 phone：%0D%0A公司名稱 company name：%0D%0A%0D%0A客戶服務代表將於2個工作天內聯絡您。感謝您使用CLSA Premium服務！%0D%0AOur client services representatives will contact you within 2 business days. Thank you for using CLSA Premium services!%0D%0A%0D%0ACLSA Premium Bullion Limited%0D%0A852 2600 8000%0D%0Aservices.bullion@clsapremium.com%0D%0ASuites 7501 %26 7508, 75/F, International Commerce Centre, 1 Austin Road West, Kowloon, Hong Kong%0D%0A">{t('corporate_account_application')}</a>
                </div>
                {/* <ul>
                  <li>{t('ao_ca_1')}</li>
                  <li>{t('ao_ca_2')}</li>
                  <li>{t('ao_ca_3')}</li>
                  <li>{t('ao_ca_4')}</li>
                  <li>{t('ao_ca_5')}</li>
                  <li>
                    {t('ao_ca_6')}
                    <ul>
                      <li>{t('ao_ca_6.1', i18nOptions)}</li>
                      <li>{t('ao_ca_6.2', i18nOptions)}</li>
                      <li>{t('ao_ca_6.3', i18nOptions)}</li>
                      <li>{t('ao_ca_6.4', i18nOptions)}</li>
                      <li>{t('ao_ca_6.5', i18nOptions)}</li>
                    </ul>
                  </li>
                </ul> */}
              </div>
              <div className='block'>
                <h2>{t('contacts')}</h2>
                <div>{t('customer_services_hotline')}: <b>852 2600 8000</b></div>
                <div>{t('cs_email')}: <b><a href="mailto:services.bullion@clsapremium.com">services.bullion@clsapremium.com</a></b></div>
                <div>{t('ao_office_address')}: <b>{t('hk_address')}</b></div>
              </div>

            </div>
          </Tab>
          <Tab eventKey="hk" title={t('hong_kong')}>
            <div>
              <h2>{t('ao_hk_title')}</h2>

              <div className='block'>
                <h5 className='title'>{t('ao_individual_account')}</h5>
                {/* <div className='brief'>{t('ao_ia_brief')}</div> */}
                <div className='para'>
                  <div>{t('ao_ia_1')}</div>
                  <div>{t('ao_ia_1_brief')}</div>
                  <ol>
                    <li>
                      {t('ao_ia_1.1', i18nOptions)}
                      <br />
                      {t('ao_ia_1.1_brief', i18nOptions)}
                    </li>
                    <li>
                      {t('ao_ia_1.2', i18nOptions)}
                      <br />
                      {t('ao_ia_1.2_brief', i18nOptions)}
                    </li>
                  </ol>
                  <div>{t('ao_ia_1_note')}</div>
                  <a className="btn m-4" href="mailto:services.hk@clsapremium.com?subject=預約親身開戶 / Face-to-face application&body=請填寫聯絡資料以便本公司客戶服務代表可以儘快聯絡您，謝謝！%0D%0APlease fill in your information for our client services representatives to contact you. Thank you!%0D%0A%0D%0A姓名 name：%0D%0A電話號碼 phone：%0D%0A%0D%0A客戶服務代表將於2個工作天內聯絡您。感謝您使用CLSA Premium服務！%0D%0AOur client services representatives will contact you within 2 business days. Thank you for using CLSA Premium services! %0D%0A%0D%0ACLSA Premium International (HK) Limited%0D%0A852 2600 7688%0D%0Aservices.hk@clsapremium.com%0D%0ASuites 7501 %26 7508, 75/F, International Commerce Centre, 1 Austin Road West, Kowloon, Hong Kong%0D%0A">{t('face_to_face_application')}</a>
                </div>
                <div className='para'>
                  <div>{t('ao_ia_2')}</div>
                  <div>{t('ao_ia_2_brief')}</div>
                  <div>{t('ao_ia_2_note')}</div>
                  {/* <ul>
                    <li>{t('ao_ia_2.1', i18nOptions)}</li>
                    <li>{t('ao_ia_2.2', i18nOptions)}</li>
                    <li>{t('ao_ia_2.3', i18nOptions)}</li>
                  </ul> */}
                  <a className="btn m-4" href="mailto:services.hk@clsapremium.com?subject=網上開戶 / Online Application&body=請填寫聯絡資料以便本公司客戶服務代表可以儘快聯絡您，謝謝！%0D%0APlease fill in your information for our client services representatives to contact you. Thank you!%0D%0A%0D%0A姓名 name：%0D%0A電話號碼 phone：%0D%0A%0D%0A客戶服務代表將於2個工作天內聯絡您。感謝您使用CLSA Premium服務！%0D%0AOur client services representatives will contact you within 2 business days. Thank you for using CLSA Premium services! %0D%0A%0D%0ACLSA Premium International (HK) Limited %0D%0A852 2600 7688%0D%0Aservices.hk@clsapremium.com%0D%0ASuites 7501 %26 7508, 75/F, International Commerce Centre, 1 Austin Road West, Kowloon, Hong Kong%0D%0A">{t('online_application')}</a>
                </div>
                {/* <div className='para'>
                  <div>{t('ao_ia_3')}</div>
                  <ul>
                    <li>{t('ao_ia_3.1', i18nOptions)}</li>
                    <li>{t('ao_ia_3.2', i18nOptions)}</li>
                    <li>{t('ao_ia_3.3', i18nOptions)}</li>
                  </ul>
                </div> */}
              </div>

              <div className='block'>
                <h5 className='title'>{t('ao_company_account')}</h5>
                <div className='para'>
                  <div>{t('ao_ca_brief')}</div>
                  <a className="btn m-4" href="mailto:services.hk@clsapremium.com?subject=公司帳戶 / Corporate Account Application&body=請填寫聯絡資料以便本公司客戶服務代表可以儘快聯絡您，謝謝！%0D%0APlease fill in your information for our client services representatives to contact you. Thank you!%0D%0A%0D%0A聯絡姓名 name：%0D%0A電話號碼 phone：%0D%0A公司名稱 company name：%0D%0A%0D%0A客戶服務代表將於2個工作天內聯絡您。感謝您使用CLSA Premium服務！%0D%0AOur client services representatives will contact you within 2 business days. Thank you for using CLSA Premium services!%0D%0A%0D%0ACLSA Premium International (HK) Limited%0D%0A852 2600 7688%0D%0Aservices.hk@clsapremium.com%0D%0ASuites 7501 %26 7508, 75/F, International Commerce Centre, 1 Austin Road West, Kowloon, Hong Kong%0D%0A">{t('corporate_account_application')}</a>
                </div>
                {/* <ul>
                  <li>{t('ao_ca_1')}</li>
                  <li>{t('ao_ca_2')}</li>
                  <li>{t('ao_ca_3')}</li>
                  <li>{t('ao_ca_4')}</li>
                  <li>{t('ao_ca_5')}</li>
                  <li>
                    {t('ao_ca_6')}
                    <ul>
                      <li>{t('ao_ca_6.1', i18nOptions)}</li>
                      <li>{t('ao_ca_6.2', i18nOptions)}</li>
                      <li>{t('ao_ca_6.3', i18nOptions)}</li>
                      <li>{t('ao_ca_6.4', i18nOptions)}</li>
                      <li>{t('ao_ca_6.5', i18nOptions)}</li>
                    </ul>
                  </li>
                </ul> */}
              </div>
              <div className='block'>
                <h2>{t('contacts')}</h2>
                <div>{t('customer_services_hotline')}: <b>852 2600 7688</b></div>
                <div>{t('cs_email')}: <b><a href="mailto:services.hk@clsapremium.com">services.hk@clsapremium.com</a></b></div>
                <div>{t('ao_office_address')}: <b>{t('hk_address')}</b></div>
              </div>

            </div>
          </Tab>
          <Tab eventKey="au" title={t('australia')}>
            <div className='row'>
              <div className='col-md-6'>
                <h2>{t('ao_au_title')}</h2>
                <div className='block'>
                  <h5 className='title'>{t('ao_multi_currency_account')}</h5>
                  <div className='brief'>{t('ao_mc_brief')}</div>
                  <div className='demo'>{t('ao_demo_account_brief')}</div>
                  <div className='row'>
                    <div className='col-md-6'>
                      <Link to="/platform/mt5/au" className="btn individual">{t('download_mt5')}</Link>
                    </div>
                  </div>
                  <div className="wholesale">
                    {t('ao_wholesale_client_1.1', i18nOptions)}
                    <a href="mailto:sales@clsapremium.com">sales@clsapremium.com</a>
                    {t('ao_wholesale_client_1.2', i18nOptions)}
                  </div>
                </div>
              </div>
              <div className='col-md-6'>
                <ul>
                  <li>{t('ao_mc_1')}</li>
                  <li>{t('ao_mc_3')}</li>
                  <li>{t('ao_mc_4')}</li>
                  <li>{t('ao_mc_6')}</li>
                </ul>
              </div>
            </div>
          </Tab>
          <Tab eventKey="nz" title={t('new_zealand')}>
            <div className='nz-block'>
              <h2>{t('ao_fx_account')}</h2>
              <h4>{t('ao_nz_title')}</h4>
              <div className='block'>
                <h5 className='title'>{t('ao_multi_currency_account')}</h5>
                <div>{t('ao_mc_brief')}</div>
                <ul>
                  <li>{t('ao_mc_1')}</li>
                  <li>{t('ao_mc_2')}</li>
                  <li>{t('ao_mc_3')}</li>
                  <li>{t('ao_mc_4')}</li>
                  <li>{t('ao_mc_5')}</li>
                  <li>{t('ao_mc_6')}</li>
                </ul>
              </div>

              <div className='block'>
                <h5 className='title'>{t('ao_individual_account')}</h5>

                <div>
                  <div>{t('ao_au_ia_1')}</div>
                  <ul>
                    <li>{t('ao_au_ia_1.1', i18nOptions)}</li>
                    <li>{t('ao_au_ia_1.2', i18nOptions)}</li>
                  </ul>
                </div>
                <div>
                  <div>{t('ao_au_ia_2')}</div>
                  <ul>
                    <li>{t('ao_au_ia_2.1', i18nOptions)}</li>
                    <li>{t('ao_au_ia_2.2', i18nOptions)}</li>
                    <li>{t('ao_au_ia_2.3', i18nOptions)}</li>
                  </ul>
                </div>
              </div>

              <div className='block'>
                <h5 className='title'>{t('ao_company_account')}</h5>
                <div>{t('ao_au_ca_1')}</div>
                <ul>
                  <li>{t('ao_au_ca_1.1', i18nOptions)}</li>
                  <li>{t('ao_au_ca_1.2', i18nOptions)}</li>
                  <li>{t('ao_au_ca_1.3', i18nOptions)}</li>
                  <li>{t('ao_au_ca_1.4', i18nOptions)}</li>
                  <li>{t('ao_au_ca_1.5', i18nOptions)}</li>
                </ul>
                <div>{t('ao_au_ca_2')}</div>
                <ul>
                  <li>{t('ao_au_ca_2.1', i18nOptions)}</li>
                  <li>{t('ao_au_ca_2.2', i18nOptions)}</li>
                  <li>{t('ao_au_ca_2.3', i18nOptions)}</li>
                  <li>{t('ao_au_ca_2.4', i18nOptions)}</li>
                  <li>{t('ao_au_ca_2.5', i18nOptions)}</li>
                  <li>{t('ao_au_ca_2.6', i18nOptions)}</li>
                </ul>
              </div>

            </div>

          </Tab>
        </Tabs>


      </section>
    </div>
  );
}