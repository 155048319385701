import React from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Jumbotron from "react-bootstrap/Jumbotron";
import Row from "react-bootstrap/Row";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import useResponsive from "../hooks/useResponsive";
import useTranslate from "../hooks/useTranslate";
import Breadcrumb from "./Breadcrumb";
import Buttons from "./Buttons";
import Paragraph from "./Paragraph";
import Title from "./Title";

const StyledJumbotron = styled(Jumbotron)`
  background-image: ${({ backgroundimage }) => `url("${backgroundimage}")`};
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: ${({ height = 500 }) => `${height}px`};
`;

const Banner = ({
  backgroundimage = "",
  brief = "",
  buttons = [],
  subtitle,
  title,
  variant = "center",
  ...customProps
}) => {
  const { tab } = useParams();
  const { isNotMobile } = useResponsive();
  const { translate } = useTranslate();

  const {
    breadcrumbProps,
    briefProps: { className: briefClassName = "", ...briefProps } = {},
    buttonProps: { className: buttonClassName, ...buttonProps } = {},
    className,
    colProps,
    rowProps,
    showBreadcrumbs = false,
    subtitleProps: {
      className: subtitleClassName = "",
      position: subtitlePosition = "bottom",
      ...subtitleProps
    } = {},
    titleProps: { className: titleClassName = "", ...titleProps } = {},
    ...props
  } = variant === "left-bottom"
    ? {
        className: "align-items-end",
        rowProps: {
          className: "justify-content-start",
        },
        showBreadcrumbs: true,
        ...customProps,
      }
    : {
        className: "align-items-center",
        rowProps: {
          className: "justify-content-center",
        },
        titleProps: {
          className: "text-center",
        },
        ...customProps,
      };

  return (
    <StyledJumbotron
      backgroundimage={backgroundimage}
      className={`banner bg-dark d-flex rounded-0 mb-0 ${className}`}
      {...props}
    >
      <Container className={isNotMobile ? "" : "px-0"}>
        <Row {...rowProps}>
          <Col {...colProps}>
            {showBreadcrumbs && <Breadcrumb {...breadcrumbProps} />}
            {subtitlePosition === "top" && (
              <Title
                as="h3"
                className={`subtitle text-capitalize text-white text-wrap ${subtitleClassName}`}
                {...subtitleProps}
              >
                {subtitle}
              </Title>
            )}
            <Title
              as="h1"
              className={`title text-capitalize text-white text-wrap ${titleClassName}`}
              display={isNotMobile ? 3 : 5}
              {...titleProps}
            >
              {title || translate(tab)}
            </Title>
            {subtitlePosition !== "top" && (
              <Title
                as="h3"
                className={`subtitle text-capitalize text-white text-wrap ${subtitleClassName}`}
                {...subtitleProps}
              >
                {subtitle}
              </Title>
            )}
            <Paragraph
              className={`brief text-white ${briefClassName}`}
              {...briefProps}
            >
              {brief}
            </Paragraph>
            <Buttons
              buttonProps={{
                className: `px-5 py-2 ${buttonClassName} ${
                  isNotMobile ? "w-auto" : "w-100"
                }`,
                ...buttonProps,
              }}
              config={buttons}
            />
          </Col>
        </Row>
      </Container>
    </StyledJumbotron>
  );
};

export default Banner;
