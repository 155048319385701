import _map from "lodash/map";
import React from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Accordion from "../components/Accordion";
import Annotation from "../components/Annotation";
import Layout from "../components/Layout";
import Paragraph from "../components/Paragraph";
import Text from "../components/Text";
import Title from "../components/Title";
import useTranslate from "../hooks/useTranslate";

const Accordions = ({ accordions }) => (
  <Accordion
    data={_map(
      accordions,
      ({ label, value: { annotation, brief, list, title } = {} }) => ({
        label,
        value: (
          <>
            <Title
              as="h5"
              className="font-weight-bold mb-3"
              font={{ family: "text" }}
            >
              {title || ""}
            </Title>
            <Paragraph as="h6" className="mb-4">
              {brief || ""}
            </Paragraph>
            {_map(list, ({ brief, list, title }, key) => (
              <div className="mb-4" key={key}>
                <Title
                  as="h5"
                  className="font-weight-bold"
                  font={{ family: "text" }}
                >
                  {title}
                </Title>
                <Paragraph as="h6" className="mb-3">
                  {brief}
                </Paragraph>
                <ol className="pl-3">
                  {_map(list, (value, key) => (
                    <Text as="li" key={key}>
                      {value}
                    </Text>
                  ))}
                </ol>
              </div>
            ))}
            <Annotation {...annotation} />
          </>
        ),
      })
    )}
    toggleProps={{
      className: "shadow",
    }}
  />
);

const PaymentAccountMaintenancePage = ({ className = "", ...props }) => {
  const { config } = useTranslate({ configKey: "client-resources" });

  const {
    "account-maintenance": accountMaintenanceConfig,
    banner: bannerProps,
    helmet: helmetProps = {},
    payment: paymentConfig,
  } = config || {};

  return (
    <Layout
      bannerProps={{
        variant: "left-bottom",
        ...bannerProps,
      }}
      helmetProps={helmetProps}
    >
      {config && (
        <Container className={`client-resources-page ${className}`} {...props}>
          {paymentConfig && (
            <Row className="payment my-5">
              <Col>
                <Title as="h2" className="mb-4">
                  {paymentConfig.title}
                </Title>
                <Accordions accordions={paymentConfig.accordions} />
              </Col>
            </Row>
          )}
          {accountMaintenanceConfig && (
            <Row className="account-maintenance my-5">
              <Col>
                <Title as="h2" className="mb-4">
                  {accountMaintenanceConfig.title}
                </Title>
                <Accordions accordions={accountMaintenanceConfig.accordions} />
              </Col>
            </Row>
          )}
        </Container>
      )}
    </Layout>
  );
};

export default PaymentAccountMaintenancePage;
