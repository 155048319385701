/** @format */

import AOS from "aos";
import "aos/dist/aos.css";
import React, { Suspense } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import Theme from "./components/Theme";
import "./App.scss";
// import Footer from "./components/Footer";
// import Header from "./components/Header";
// import Banner from "./components/NewBanner";
// import Footer from "./components/NewFooter";
// import Header from "./components/NewHeader";
// import router from "./router";
import routes from "./routes";

AOS.init();

const App = () => (
  <Theme>
    <div className="main">
      <Suspense fallback="">
        <Router>
          {/* <Routes /> */}
          {/* <Header history={history} /> */}
          {/* <Footer history={history} /> */}
          {/* {router} */}
          {routes}
          {/* <Subsidiary>
          <Header />
          <Banner
            backgroundimage="/ProductHeader.jpg"
            brief="Professional, Diversified and Forward-looking Financial Products and Services"
            title="Global Forex Trading"
          ></Banner>
          {router}
          <Footer />
        </Subsidiary> */}
        </Router>
      </Suspense>
    </div>
  </Theme>
);

export default App;
