import React from "react";
import { Helmet as ReactHelmet } from "react-helmet";
import _map from "lodash/map";

const Helmet = ({ title, meta, ...props }) => (
  <ReactHelmet {...props}>
    {title && <title>{title}</title>}
    {title && <meta content={title} name="title" />}
    {_map(meta, (props, key) => (
      <meta key={key} {...props} />
    ))}
  </ReactHelmet>
);

export default Helmet;
