import React from "react";
import styled from "styled-components";

const StyledDiv = styled.div`
  height: ${({ height = 0, theme: { spacer } }) =>
    height ? `calc(${height}px + ${spacer})` : 0};
  opacity: ${({ opacity = 0.6 }) => opacity};
  top: ${({ top = 0 }) => `${top}px`};
  width: ${({ width }) => (width ? `${width}px` : "100vw")};
  z-index: -1;
`;

const Curtain = ({ className = "", ...props }) => (
  // TODO Change -curtain to curtain when App.scss dumped
  <StyledDiv className={`-curtain bg-dark fixed-top ${className}`} {...props} />
);

export default Curtain;
