import _map from "lodash/map";
import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import useTranslate from "../hooks/useTranslate";
import DescriptionList from "./DescriptionList";
import Title from "./Title";

const ContactInfo = ({
  className = "",
  colProps = {},
  rowProps = {},
  ...props
}) => {
  const { config } = useTranslate({ configKey: "contact-info" });
  return (
    //  TODO p-0 can take out after removing App.scss
    <Container fluid className={`contact-info ${className} p-0`} {...props}>
      <Row {...rowProps}>
        {_map(config, ({ label, value }, key) => (
          <Col key={key} lg={6} {...colProps}>
            <Title
              as="h6"
              className="city font-weight-bold"
              font={{ family: "text" }}
            >
              {label}
            </Title>
            <DescriptionList className="city-contact-info" data={value} />
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default ContactInfo;
