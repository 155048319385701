import React from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Layout from "../components/Layout";
import Paragraph from "../components/Paragraph";
import Title from "../components/Title";
import useTranslate from "../hooks/useTranslate";

const TermPage = () => {
  const {
    config: { brief, title },
  } = useTranslate({
    configKey: "term",
  });
  return (
    <Layout headerProps={{ bg: "dark" }} showBanner={false}>
      <Container className="term-page py-5 px-0">
        <Row className="p-5">
          <Col className="pt-5 text-center">
            <Title font={{ family: "text" }}>{title}</Title>
          </Col>
        </Row>
        <Row>
          <Col>
            <Paragraph>{brief}</Paragraph>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default TermPage;
