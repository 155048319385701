/** @format */

import React from "react";
import useTranslate from "../hooks/useTranslate";
import Buttons from "./Buttons";

const HeaderUserBtns = ({ className = "", ...props }) => {
  const { config } = useTranslate({ configKey: "header-user-btns" });
  return (
    <Buttons
      buttonProps={{ font: { size: "sm" }, variant: "light" }}
      className={`header-user-btns flex-nowrap justify-content-center ${className}`}
      config={config}
      {...props}
    />
  );
};

export default HeaderUserBtns;
