import React, { useState } from 'react';

export default function NZPolicyCN(){
	return (
	<div >
	

<h1> PRIVACY POLICY </h1>
<p><strong>CLSA Premium New Zealand Ltd</strong></p>
<p><strong>Introduction</strong></p>
<p>This privacy policy explains how CLSA Premium New Zealand Ltd (<strong>we</strong>, <strong>us</strong>, <strong>our</strong>) collects, uses, stores, discloses and protects your personal information. Personal information is information about an identifiable individual (a natural person).</p>
<p>This policy does not limit or exclude any of your rights under the Privacy Act 1993 (<strong>Act</strong>).</p>
<p>We may change this policy by uploading a revised policy on our website www.clsapremium.com.&nbsp; Changes will apply from the date that we upload the revised policy.</p>
<p><strong>Personal information we collect</strong></p>
<p>Personal information we collect about you may include your name &amp; date of birth, residential address, phone number, email address, payment information, and identity document information. &nbsp;</p>
<p>We may also maintain records of all transactions and activities on accounts you have with us, including information about products and services provided to you and trades conducted by you.</p>
<p>We will also collect statistical information about the use of our Website. &nbsp;Information about your computer and your visits to and use of the Website (such as your IP address, location, browser type, user name, documents downloaded and types of transactions conducted) may be recorded when you use our Website. &nbsp;This information may be used to identify you. &nbsp;We will treat this information as personal information.</p>
<p><strong>How we collect your personal information</strong></p>
<p>We will collect your personal information in the following circumstances:</p>
<ul>
<li>when you provide that personal information to us, including via the Website and any related services, through any registration process, through any contact with us (e.g. telephone call or email)</li>
<li>when we obtain information from third parties (with your consent) and from publicly available information</li>
<li>when you buy or use our products and services, e.g. opening an account, conducting transactions through our platform</li>
<li>when we provide our products and services to you, e.g. opening an account for you, monitoring your transactions.</li>
</ul>
<p>You are not required to provide the personal information that we request but, if you choose not to do so, in many cases we will not be able to provide you with our products and services or respond to any queries you may have.</p>
<p><strong>How we use your personal information</strong></p>
<p>We will use your personal information:</p>
<ul>
<li>to provide, manage and administer products and services we provide to you, including keeping you updated whilst you are a customer, including in relation to your margin obligations, and the funds in your account</li>
<li>to verify your identity and other personal information you provide</li>
<li>to market our products and services to you, including contacting you electronically (e.g. by text or email)</li>
<li>to improve the products and services that we provide to you</li>
<li>to show you advertising and information that is most relevant to you and your interests</li>
<li>to undertake credit checks on you (if necessary)</li>
<li>to bill you and to collect money that you owe us</li>
<li>to respond to communications from you</li>
<li>to conduct research and statistical analysis (on an anonymised basis)</li>
<li>to protect and/or enforce our legal rights and interests, including defending any claim</li>
<li>to comply with our obligations at law and requests from regulators for information</li>
<li>for any other purpose authorised by you or the Act.</li>
</ul>
<p><strong>Disclosing your personal information</strong></p>
<p>We may disclose your personal information to: &nbsp;</p>
<ul>
<li>another company within the group of companies of which we are party for the purpose of assisting us to provide products and services to you, and to comply with our obligations at law</li>
<li>any business that supports provision of our products and services, including any person that hosts or maintains any underlying IT system or data centre that we use to provide the Website or other products and services</li>
<li>a credit reference agency for the purpose of credit checking on you</li>
<li>other third parties (for anonymised statistical information)</li>
<li>a person who can require us to supply your personal information (e.g. a regulatory authority)</li>
<li>any new owners or re-organised entities in the event of a business restructuring, sale, purchase, or joint venture affecting our business</li>
<li>any other person authorised by the Act or another law (or otherwise on request from a regulator)</li>
<li>any other person authorised by you.</li>
</ul>
<p>A business that supports the provision of our products and services may be located outside New Zealand.&nbsp; This means that your personal information may be held and processed outside New Zealand.</p>
<p><strong>Protecting your personal information</strong></p>
<p>We will take reasonable steps to keep your personal information safe from loss, unauthorised activity, or other misuse. When we communicate with you electronically (including via the Website, by email and text message) we cannot promise that the communication will be complete, confidential or secure.&nbsp;</p>
<p>If you are not located in New Zealand, the information we collect may be processed in and transferred between your location and New Zealand. &nbsp;New Zealand may not have equivalent data protection laws to those in force in your location.</p>
<p><strong>Accessing and correcting your personal information</strong></p>
<p>Subject to certain grounds for refusal set out in the Act, you have the right to access your readily retrievable personal information that we hold and to request a correction to your personal information.&nbsp; We will need evidence to confirm that you are the individual to whom the personal information relates.</p>
<p>With respect to a request for correction, if we think the correction is reasonable and we are reasonably able to change the personal information, we will make the correction. &nbsp;If we do not make the correction, we will take reasonable steps to note on the personal information that you requested the correction.</p>
<p>If you want to exercise those rights please contact us using our contact details set out at the end of this document.&nbsp; You should provide evidence of who you are and set out the details of your request (e.g. the personal information, or the correction, that you are requesting).</p>
<p>We may charge you our reasonable costs of providing to you copies of your personal information or correcting that information.</p>
<p>If you follow a link on our Website to third party websites or applications, the owner of that website or application will have its own privacy policy relating to your personal information. We may use cookies to monitor your use of the website and collect the statistical information referred to above in addition to allowing you to access your account online.&nbsp; You may disable cookies by changing the settings on your browser, although this may mean that you cannot use all of the features of the Website.</p>
<p><strong>Contact details </strong></p>
<p>If you have any questions or concerns about our privacy policy, or any other matter, please feel free to contact us.&nbsp; You can reach us in one of the following ways:</p>
<ul>
<li>Phone Number: +64 9 3598999</li>
<li>Email Address: services@claspremium.com</li>
</ul>
</div>
)
}