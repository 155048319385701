/** @format */

import React from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const PopupBox = (props) => {
  const { buttonText, className, children, status = "off", onClose } = props;
  const { t } = useTranslation();

  return (
    <div className={`popup-wrapper ${status} ${className}`}>
      <div className="curtain"></div>
      <div className="box">
        <div className="body">{children}</div>
        <Button variant="light" onClick={onClose}>
          {buttonText || t("close")}
        </Button>
      </div>
    </div>
  );
};

export default PopupBox;
