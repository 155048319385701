import _map from "lodash/map";
import React from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import ListGroup from "react-bootstrap/ListGroup";
import Row from "react-bootstrap/Row";
import styled from "styled-components";
import Annotation from "../components/Annotation";
import Layout from "../components/Layout";
import Paragraph from "../components/Paragraph";
import Text from "../components/Text";
import Title from "../components/Title";
import TitleSplitter from "../components/TitleSplitter";
import useResponsive from "../hooks/useResponsive";
import useTranslate from "../hooks/useTranslate";

const StyledA = styled.a`
  &:hover {
    text-decoration: none;
  }
`;

const StyledListGroupItem = styled(ListGroup.Item)`
  &:last-child {
    border-bottom-width: 1px !important;
  }
`;

const LegalDocPage = ({ className = "", ...props }) => {
  const { isNotMobile } = useResponsive();
  const { config } = useTranslate({ configKey: "legal-doc" });

  const {
    annotation: annotationConfig,
    banner: bannerProps,
    documents: documentsConfig,
    helmet: helmetProps,
    intro: introConfig,
    notice: noticeConfig,
  } = config || {};

  return (
    <Layout
      bannerProps={{
        variant: "left-bottom",
        ...bannerProps,
      }}
      helmetProps={helmetProps}
    >
      {config && (
        <Container
          className={`legal-doc-page mt-4 py-5 ${className}`}
          {...props}
        >
          {introConfig && (
            <Row className="intro mb-3">
              <Col className="text-center">
                <Title as="h2" display={isNotMobile ? 4 : 6}>
                  {introConfig.title}
                </Title>
                <Paragraph>{introConfig.brief}</Paragraph>
                <TitleSplitter className="mx-auto" color="success" />
              </Col>
            </Row>
          )}
          {noticeConfig && (
            <Row className="notice mb-3">
              <Col>
                <Text>{noticeConfig.brief}</Text>
                <ol className="pl-3">
                  {_map(noticeConfig.list, (value, key) => (
                    <Text as="li" key={key}>
                      {value}
                    </Text>
                  ))}
                </ol>
              </Col>
            </Row>
          )}
          {documentsConfig && (
            <Row className="documents mb-3">
              <Col className="d-flex justify-content-center">
                <ListGroup className="d-inline-flex" variant="flush">
                  {_map(documentsConfig, ({ label, value }, key) => (
                    <StyledListGroupItem
                      className="bg-transparent text-center pt-5"
                      key={key}
                    >
                      <StyledA
                        href={value}
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        <Title
                          as="h3"
                          className="text-dark"
                          display={isNotMobile ? 7 : 8}
                        >
                          {label}
                        </Title>
                      </StyledA>
                    </StyledListGroupItem>
                  ))}
                </ListGroup>
              </Col>
            </Row>
          )}
          {annotationConfig && (
            <Row className="annotation mb-5">
              <Col className="text-center">
                <Annotation {...annotationConfig} />
              </Col>
            </Row>
          )}
        </Container>
      )}
    </Layout>
  );
};

export default LegalDocPage;
