import React, { useState } from 'react';

export default function HKTermEN(){
	return (
	<div className="text-justify">


<h1> Terms and Conditions of Use </h1>

<p><strong><u>Website Terms of Use</u></strong></p>
<p>This web site is operated by CLSA Premium International (HK) Limited (<strong>&ldquo;CLSA Premium Hong Kong&rdquo;</strong>) a company incorporated in Hong Kong ("<strong>CLSA Premium Hong Kong Web Site</strong>"). &nbsp;</p>
<p>CLSA Premium International (HK) Limited is a wholly owned subsidiary of CLSA Premium Limited and is licensed to carry out Type 3 (Leveraged Foreign Exchange) activities in Hong Kong and is regulated by Securities and Futures Commission Hong Kong.</p>
<p>&nbsp;</p>

<p><strong><u>Terms variations</u></strong></p>
<p>By accessing the CLSA Premium Hong Kong Web Site, you agree you will be bound by the Terms of Use, notice, disclaimers and other notices on the CLSA Premium Hong Kong Web Site. The Terms of Use may be changed, revised or amended without any notice given to you and at sole discretion of CLSA Premium Hong Kong.</p>
<p>&nbsp;</p>

<p><strong><u>Visitors responsibility</u></strong></p>
<p>The information on this website is not intended for distribution to, or use by, any person in any country or jurisdiction where such distribution or use would be contrary to local law or regulation. It is the responsibility of visitors to the CLSA Premium Hong Kong Web Site to ascertain the terms of and comply with any local law or regulation to which they are subject.</p>
<p>&nbsp;</p>

<p><strong><u>Third Party website </u></strong></p>
<p>CLSA Premium Hong Kong Web Site may contain links to other web sites operated by third parties. CLSA Premium Hong Kong assumes no responsibility for the content or maintenance of any such third party site and makes no warranties, express or implied, with respect to its content or otherwise. CLSA Premium Hong Kong does not endorse, or approve of the operators of such third Party web sites, or the content, information, graphics and material on such third party websites. Access and use of information contain in the third party website is at your own risk.</p>
<p>&nbsp;</p>

<p><strong><u>Not investment advice </u></strong></p>
<p>&nbsp;</p>
<p>Please note that the information provided on the CLSA Premium Hong Kong website does not take into account of your investment and financial needs, capability and objectives.</p>
<p>&nbsp;</p>
<p>Nothing herein shall constitutes or shall be deemed to constitute as providing financial or investment advice to you. Nothing on the CLSA Premium Hong Kong Web Site shall be taken as recommendation to invest in any of the financial products offered by CLSA Premium Hong Kong.</p>
<p>Prior to making any investment, you should make your independent assessment as to whether a particular investment product is suitable for your investment needs. If unsure, you should seek independent legal, tax and financial advice before deciding to proceed on making any investment.</p>

<p><strong><u>Privacy Policy</u></strong></p>
<p>Your personal information may be collected by CLSA Premium Hong Kong by visiting the CLSA Premium Hong Kong Web Site. To the extent that you have provided CLSA Premium Hong Kong with any personal information on CLSA Premium Hong Kong Web Site, CLSA Premium Hong Kong will only use these personal data in accordance with the purposes set forth in the privacy policy [link to privacy policy] and is committed to safeguarding the personal information collected.</p>
<p>&nbsp;</p>
<p><strong><u>Copyright </u></strong></p>
<p>All text, graphics, icons, photographs, videos, images, illustrations, compilations and other material on the CLSA Premium Hong Kong Web site are the copyright of CLSA Premium Hong Kong.</p>
<p>&nbsp;</p>
<p>Reproduction or other use of works retrieved from CLSA Premium Hong Kong Web Site in all forms, media and technologies now existing or hereafter developed is subject to the Copyright Ordinance (Chapter 528, Laws of Hong Kong) and equivalent legislation around the world. A single copy of material on the CLSA Premium Hong Kong Web Site may be downloaded and/or reprinted for non-commercial and personal use only and may not be distributed to third parties. Any downloaded or reprinted material may not be altered in any way and must contain the following copyright notice: &ldquo;&copy; CLSA Premium Hong Kong 2021 All rights reserved&rdquo; and/or any other copyright notice contained on the downloaded material.</p>
<p>&nbsp;</p>
<p>No other permission is granted to you, or anyone acting on your behalf, to print, copy, reproduce, distribute, transmit, upload, download, store, display in public, alter, or modify the content of CLSA Premium Hong Kong Web Site. Any unauthorised downloading, re-transmission or other copying or modification of the content of the CLSA Premium Hong Kong Web Site or any other use than that described above is strictly prohibited by law.</p>
<p>&nbsp;</p>
<p>The name and the logo of CLSA Premium Hong Kong are proprietary trademarks of CLSA Premium Hong Kong and our licensors, the use of which without express written permission is strictly prohibited.</p>
<p>&nbsp;</p>
<p><strong><u>Disclaimer of liability </u></strong></p>
<p>The information and content contained in the Site have been produced and collated by CLSA Premium Hong Kong in accordance with its current practices and policies and with the benefit of information currently available to it. All reasonable efforts have been made to ensure the accuracy of the contents of the pages of the CLSA Premium Hong Kong Web Site at the time of preparation.</p>
<p>&nbsp;</p>
<p>The information publish on the CLSA Premium Hong Kong Web Site are for general information only and nothing on the CLSA Premium Hong Kong Website constitutes professional advice or any binding commitment upon CLSA Premium Hong Kong in relation to the availability of services provided by CLSA Premium Hong Kong.</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<p>Notwithstanding the efforts made by CLSA Premium Hong Kong to ensure the accuracy of the CLSA Premium Hong Kong Web Site, CLSA Premium Hong Kong disclaims any responsibility or liability in respect to any use or reference to the CLSA Premium Hong Kong Web Site, and CLSA Premium Hong Kong does not warrant or guarantee the adequacy, accuracy or completeness of any information herein or that such information will be delivered in a timely or uninterrupted form.<strong>&nbsp;</strong></p>
<p><strong>&nbsp;</strong></p>
<p><strong>CLSA PREMIUM HONG KONG MAKES NO REPRESENTATION OR WARRANTY, WHETHER EXPRESS OR IMPLIED, OF ANY KIND WITH RESPECT TO THE SITE, ITS OPERATION AND PUBLICATIONS ON THIS SITE ARE PROVIDED &ldquo;AS IS&rdquo;. CLSA PREMIUM HONG KONG EXPRESSLY DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED, OF ANY KIND RELATING TO THE CLSA PREMIUM HONG KONG WEB SITE, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE, AND ANY OBLIGATION TO UPDATE ANY INFORMATION HEREIN AND ANY LIABILITY FOR INACCURACIES AND OMISSIONS IN SUCH INFORMATION. TO THE EXTENT PERMIISSBLE UNDER APPLICABLE LAW, CLSA PREMIUM HONG KONG ACCEPTS NO LIABILITY FOR LOSSES OR DAMAGES WHICH MAY BE DIRECTLY OR INDIRECTLY SUSTAINED BY ANY VISITOR ON ACCESS TO THE SITE OR OTHER PERSON WHO OBTAINS ACCESS TO THE PUBLICATIONS ON THE SITE, OR FOR ANY CLAIM BY ANY THIRD PARTY.</strong><strong>&nbsp;</strong></p>
<p><strong>&nbsp;</strong></p>
<p>You agree that this exclusion of liability is comprehensive and applies to all damages of any kind including without limitation direct, indirect, compensatory, special, multiple, incidental, punitive and consequential.</p>
<p><strong><u>Severability </u></strong></p>
<p>In the event where any provisions of the Terms of Use is held unenforceable then that provision or, if permissible, the relevant part of it, will be severed in respect of that jurisdiction. The remainder of the Terms of Use will have full force and effect and the validity or enforceability of that provision in any other jurisdiction is not affected.</p>
<p><strong><u>Governing law and jurisdiction</u></strong></p>
<p>By accessing the CLSA Premium Hong Kong Web Site and any of its content, you agree that any disputes shall be governed by the Laws of Hong Kong and to the non-exclusive jurisdiction of the Courts of Hong Kong in connection with any suit, action or proceeding arising out of or in connection with the use of the CLSA Hong Kong Premium Web Site.</p>

</div>
)
}