import _map from "lodash/map";
import React from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import styled from "styled-components";
import DescriptionList from "../components/DescriptionList";
import Layout from "../components/Layout";
import Paragraph from "../components/Paragraph";
import Text from "../components/Text";
import Title from "../components/Title";
import useTranslate from "../hooks/useTranslate";

const StyledContainer = styled(Container)`
  background-color: ${({ theme: { "darkpurple-800": color } }) => color};
`;

const StyledRow = styled(Row)`
  padding-top: 150px;
`;

const DepositPage = ({ className = "", ...props }) => {
  const { config } = useTranslate({ configKey: "withdraw" });
  const {
    banner: bannerProps,
    helmet: helmetProps,
    intro: introConfig,
    fund: fundConfig,
    procedure: procedureConfig,
    method: methodConfig,
    fee: feeConfig,
  } = config || {};

  return (
    <Layout
      bannerProps={bannerProps}
      headerProps={{ bg: "dark" }}
      helmetProps={helmetProps}
    >
      {config && (
        <Container
          className={`withdraw-page position-relative py-5 ${className}`}
          {...props}
        >
          <Row>
            <StyledContainer>
              <StyledRow className="pb-5">
                {introConfig &&
                  (({ brief, title }) => (
                    <Col className="intro pl-5" lg={4}>
                      <Title as="h2" className="text-white" display={7}>
                        {title}
                      </Title>
                      <Paragraph className="text-white">{brief}</Paragraph>
                    </Col>
                  ))(introConfig)}
                <Col className="px-5" lg={8}>
                  <Row className="bg-white p-5 mb-5">
                    {fundConfig &&
                      (({ brief, list, title }) => (
                        <Col className="fund">
                          <Title
                            as="h4"
                            className="font-weight-bold mb-3"
                            color="darkpurple-700"
                            display={7}
                          >
                            {title}
                          </Title>
                          <Paragraph
                            className="font-weight-bold mb-5"
                            color="darkpurple-700"
                          >
                            {brief}
                          </Paragraph>
                          <DescriptionList
                            data={list}
                            labelProps={{
                              className: "mr-5",
                              color: "darkpurple-800",
                            }}
                            valueProps={{
                              className: "ml-5",
                            }}
                          />
                        </Col>
                      ))(fundConfig)}
                  </Row>
                  <Row className="bg-white p-5 mb-5">
                    {procedureConfig &&
                      (({ list, title }) => (
                        <Col className="fund">
                          <Title
                            as="h4"
                            className="font-weight-bold mb-3"
                            color="darkpurple-700"
                            display={7}
                          >
                            {title}
                          </Title>
                          <ol className="pl-3">
                            {_map(list, (value, key) => (
                              <Text
                                as="li"
                                className="mb-3"
                                color="darkpurple-800"
                                font={{
                                  size: "sm",
                                }}
                                key={key}
                              >
                                {value}
                              </Text>
                            ))}
                          </ol>
                        </Col>
                      ))(procedureConfig)}
                  </Row>
                  <Row className="bg-white p-5 mb-5">
                    {methodConfig &&
                      (({ brief, title }) => (
                        <Col className="fund">
                          <Title
                            as="h4"
                            className="font-weight-bold mb-3"
                            color="darkpurple-700"
                            display={7}
                          >
                            {title}
                          </Title>
                          <Paragraph
                            className="font-weight-bold mb-5"
                            color="darkpurple-700"
                          >
                            {brief}
                          </Paragraph>
                        </Col>
                      ))(methodConfig)}
                  </Row>
                  <Row className="bg-white p-5">
                    {feeConfig &&
                      (({ brief, title }) => (
                        <Col className="fund">
                          <Title
                            as="h4"
                            className="font-weight-bold mb-3"
                            color="darkpurple-700"
                            display={7}
                          >
                            {title}
                          </Title>
                          <Paragraph
                            className="font-weight-bold mb-5"
                            color="darkpurple-700"
                          >
                            {brief}
                          </Paragraph>
                        </Col>
                      ))(feeConfig)}
                  </Row>
                </Col>
              </StyledRow>
            </StyledContainer>
          </Row>
        </Container>
      )}
    </Layout>
  );
};

export default DepositPage;
