import React, { useState } from 'react';
import { useTranslation, Trans } from "react-i18next";
import { Tab, Nav, Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { Helmet } from "react-helmet";

import Banner from '../components/Banner'
import ApiPopupBox from '../components/ApiPopupBox'
// import WarningPopupBox from '../components/WarningPopupBox'

export default function SummaryAUPage({match}) {
  // Declare a new state variable, which we'll call "count"
  const [lang, setLang] = useState('English');
  const { t, i18n } = useTranslation();

  return (
    <div className='content'>
      <Helmet>
        <title>{t("title_sub", { param: t("australia") })}</title>
        <meta name="description" content={t("description_au")}></meta>
      </Helmet>
      <Banner title={`CLSA Premium ${t('australia')}`} bg={'location'} subsidiary={match.url.split('/')[1]} showBrief/>
      <ApiPopupBox subsidiary={match.url.split('/')[1]} />
      {/* <WarningPopupBox buttonText={t("close")} paragraph={[ t("warning_1_au"), t("warning_2_au"), t("warning_3_au"), t("warning_4_au"), t("warning_5_au")]} /> */}
      <section className='container location'>
        
        <div className='block'>
          <div className='intro'>
            <h2>{t('welcom_clsa_au')}</h2>
            <div className='summary'>{t('citic_company')}</div>
            <div className='brief'>{t('summary1.1_au', { keySeparator: false })}</div>
          </div>
        </div>
        <div className='block'>
          <ul>
            <li>
              <div className='title'>{t('company_qualifications')}</div>
              <div className='text'>{t('company_qualifications_text')}</div>
            </li>
            <li>
              <div className='title'>{t('platform_function')}</div>
              <div className='text'>{t('platform_function_text')}</div>
            </li>
            <li>
              <div className='title'>{t('investor_education')}</div>
              <div className='text'>{t('investor_education_text')}</div>
            </li>
            <li>
              <div className='title'>{t('diversified_services')}</div>
              <div className='text'>{t('diversified_services_text')}</div>
            </li>
            <li>
              <div className='title'>{t('market_information')}</div>
              <div className='text'>{t('market_information_text')}</div>
            </li>
          </ul>
        </div>

          {/*
          <div className='block row'>
            <div className='col-md-6 col-xm-12'>
              <article>
                <img src='/about-6_1.png' />
                <hr />
                <h1>Australian Securities &amp; Investments Commission (ASIC)</h1>
                <p>{t('accreditation_1')} (No.<a href="https://connectonline.asic.gov.au/RegistrySearch/faces/landing/ProfessionalRegisters.jspx?_adf.ctrl-state=24pp64892_12" target="_blank">226602</a>)</p>
              </article>
            </div>
            <div className='intro col-md-6 col-xm-12'>
              <h2>{t('accreditation')}</h2>
              <div className='accredit asic'>Australian Securities &amp; Investments Commission (ASIC)</div>
              <div className='accredit'>{t('accreditation_1')} (No.<a href="https://connectonline.asic.gov.au/RegistrySearch/faces/landing/ProfessionalRegisters.jspx?_adf.ctrl-state=24pp64892_12" target="_blank">226602</a>)</div>
            </div>
          </div>*/}
        
      </section>
      {/* <section className='campaign'>
        <div className='wrapper'>
          <div className='latest'>{t('clsap_latest')}</div>
          <div className='analysis'>{t('us_election_analysis')}</div>
          <div className='analysis-text'>{t('us_election_analysis_text1')}</div>
          <div className='analysis-text'>{t('us_election_analysis_text2')}</div>
          <div className='opportunity'><a href='https://sites.google.com/view/uselection-cn' target='_blank'>{t('see_opportunities')}</a></div>
        </div>
      </section> */}
      <section className='campaign'>
        <div className='wrapper'>
          <div className='latest'>{t('clsap_latest')}</div>
          {i18n.language === 'en' ? (
              <>
                <div className='analysis'>{t('global_opportunities')}</div>
                <div className='analysis'>{t('within_one_account')}</div>
              </>
            ) : (<div className='analysis'>{t('global_opportunities_within_one_account')}</div>)
          }
          <div className='opportunity'><a href='https://join.clsapremium.com/?utm_source=webiste' target='_blank'>{t('see_opportunities')}</a></div>
        </div>
      </section>
    </div>
  );
}