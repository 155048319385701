import _map from "lodash/map";
import React from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import styled from "styled-components";
import Annotation from "../components/Annotation";
import DetailList from "../components/DetailList";
import Layout from "../components/Layout";
import Paragraph from "../components/Paragraph";
import Text from "../components/Text";
import Title from "../components/Title";
import useTranslate from "../hooks/useTranslate";

const StyledDiv = styled.div`
  background-color: ${({ theme: { "darkpurple-800": color } }) => color};
  height: 500px;
`;

const StyledRow = styled(Row)`
  padding-top: 150px;
`;

const DepositPage = ({ className = "", ...props }) => {
  const { config } = useTranslate({ configKey: "deposit" });
  const {
    banner: bannerProps,
    helmet: helmetProps,
    intro: introConfig,
    deposit: depositConfig,
    detail: detailConfig,
  } = config || {};

  return (
    <Layout
      bannerProps={bannerProps}
      headerProps={{ bg: "dark" }}
      helmetProps={helmetProps}
    >
      {config && (
        <Container
          className={`deposit-page position-relative py-5 ${className}`}
          {...props}
        >
          <StyledDiv className="position-absolute w-100" />
          <StyledRow>
            <Container className="mt-5">
              <Row>
                {introConfig &&
                  (({ brief, title }) => (
                    <Col className="intro pl-5" lg={3}>
                      <Title as="h2" className="text-white" display={7}>
                        {title}
                      </Title>
                      <Paragraph className="text-white">{brief}</Paragraph>
                    </Col>
                  ))(introConfig)}
                <Col className="bg-white p-5" lg={9}>
                  <Row className="mb-5">
                    {depositConfig &&
                      (({ annotation, brief, list, note, subtitle, title }) => (
                        <Col className="deposit">
                          <Title
                            as="h4"
                            className="font-weight-bold mb-3"
                            color="darkpurple-700"
                            display={7}
                          >
                            {title}
                          </Title>
                          <Text
                            className="font-weight-bold"
                            color="lightpurple"
                          >
                            {subtitle}
                          </Text>
                          <Paragraph
                            className="font-weight-bold"
                            color="darkpurple-800"
                          >
                            {brief}
                          </Paragraph>
                          <ol className="pl-3">
                            {_map(list, (value, key) => (
                              <Text
                                as="li"
                                className="mb-3"
                                color="darkpurple-800"
                                font={{
                                  size: "sm",
                                }}
                                key={key}
                              >
                                {value}
                              </Text>
                            ))}
                          </ol>
                          <Annotation
                            listProps={{
                              labelProps: {
                                className: "font-weight-normal",
                                color: "lightpurple",
                                font: {
                                  size: "sm",
                                },
                              },
                              valueProps: {
                                color: "lightpurple",
                                font: {
                                  size: "sm",
                                },
                              },
                            }}
                            {...annotation}
                          />
                          {note &&
                            (({ list, title }) => (
                              <div className="note">
                                <Title
                                  as="h6"
                                  className="font-weight-bold mb-3"
                                  color="lightpurple"
                                  font={{ family: "text", size: "sm" }}
                                >
                                  {title}
                                </Title>
                                <ol className="pl-3">
                                  {_map(list, (value, key) => (
                                    <Text
                                      as="li"
                                      color="darkpurple-800"
                                      font={{
                                        size: "sm",
                                      }}
                                      key={key}
                                    >
                                      {value}
                                    </Text>
                                  ))}
                                </ol>
                              </div>
                            ))(note)}
                        </Col>
                      ))(depositConfig)}
                  </Row>
                  <Row className="mt-5">
                    {detailConfig &&
                      (({ list, title }) => (
                        <Col className="detail">
                          <Title
                            as="h4"
                            className="font-weight-bold mb-4"
                            color="darkpurple-700"
                            display={7}
                          >
                            {title}
                          </Title>
                          <DetailList
                            className="px-0"
                            data={list}
                            labelProps={{
                              textProps: {
                                color: "darkpurple-800",
                              },
                            }}
                            valueProps={{
                              active: false,
                              className: "shadow-none",
                              textProps: {
                                color: "darkpurple-800",
                              },
                            }}
                          />
                        </Col>
                      ))(detailConfig)}
                  </Row>
                </Col>
              </Row>
            </Container>
          </StyledRow>
        </Container>
      )}
    </Layout>
  );
};

export default DepositPage;
