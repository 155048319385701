import _map from "lodash/map";
import React from "react";
import Buttons from "./Buttons";
import Title from "./Title";

const SidebarLinks = ({ className = "", links = [], ...props }) => (
  <div className={`sidebar-links ${className}`} {...props}>
    {_map(links, ({ label, value }, key) => (
      <div className="p-4 shadow mb-4 text-center" key={key}>
        <Title as="h4">{label}</Title>
        <Buttons
          buttonProps={{ font: { size: "sm" } }}
          className="m-auto justify-content-center"
          config={value}
        />
      </div>
    ))}
  </div>
);

export default SidebarLinks;
