import React from "react";
import ScaleImage from "./ScaleImage";

const Logo = (props) => (
  <ScaleImage
    alt="CLSA Premium"
    className="logo"
    height={60}
    scale={1.2}
    src="/CLSAPremiumLogo_white.png"
    {...props}
  />
);

export default Logo;
