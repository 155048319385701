/** @format */

import _has from "lodash/has";
import React from "react";
import BsButton from "react-bootstrap/Button";
import styled from "styled-components";

const StyledButton = styled(BsButton)`
  color: ${({ color, theme }) => theme[color]};
  font-family: ${({ font: { family = "button" } = {}, theme }) =>
    _has(theme, `font-family-${family}`)
      ? theme[`font-family-${family}`]
      : theme["font-family-button"]};
  font-size: ${({ font: { size = "base" } = {}, theme }) =>
    _has(theme, `font-size-${size}`)
      ? theme[`font-size-${size}`]
      : theme["font-size-base"]};
`;

const Button = ({ className = "", children, ...props }) => (
  <StyledButton className={`rounded-pill ${className}`} {...props}>
    {children}
  </StyledButton>
);

export default Button;
