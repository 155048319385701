import _isString from "lodash/isString";
import _map from "lodash/map";
import React from "react";
import ListGroup from "react-bootstrap/ListGroup";
import useResponsive from "../hooks/useResponsive";
import useSubsidiary from "../hooks/useSubsidiary";
import useTranslate from "../hooks/useTranslate";
import FooterLink from "./FooterLink";
import Text from "./Text";
import Title from "./Title";

const FooterNav = ({
  className = "",
  listGroupItemProps: {
    className: listGroupItemClassName = "",
    ...listGroupItemProps
  } = {},
  ...props
}) => {
  const { isDesktop } = useResponsive();
  const { subsidiary } = useSubsidiary();
  const { config } = useTranslate({ configKey: "nav" });

  return (
    <ListGroup
      className={`${subsidiary}-footer-nav justify-content-between ${className} p-0`} // TODO p-0 can take out after removing App.scss
      horizontal={isDesktop}
      variant={!isDesktop && "flush"}
      {...props}
    >
      {_map(config, ({ label, value }, key) => (
        <ListGroup.Item
          className={`footer-nav-item bg-transparent border-0 rounded-0 ${listGroupItemClassName}`}
          key={key}
          {...listGroupItemProps}
        >
          {_isString(value) ? (
            <FooterLink scrollToTop to={value}>
              <Title
                as="h6"
                className="font-weight-bold"
                font={{ family: "text" }}
              >
                {label}
              </Title>
            </FooterLink>
          ) : (
            <>
              <Title
                as="h6"
                className="font-weight-bold"
                font={{ family: "text" }}
              >
                {label}
              </Title>
              <ListGroup className="bg-transparent border-0" variant="flush">
                {_map(value, ({ label, value }, key) => (
                  <ListGroup.Item
                    className={`footer-nav-item bg-transparent border-0 ${
                      isDesktop ? "pl-0" : ""
                    } rounded-0`}
                    key={key}
                  >
                    <FooterLink scrollToTop to={value}>
                      <Text className="text-wrap">{label}</Text>
                    </FooterLink>
                  </ListGroup.Item>
                ))}
              </ListGroup>
            </>
          )}
        </ListGroup.Item>
      ))}
    </ListGroup>
  );
};

export default FooterNav;
