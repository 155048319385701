import _compact from "lodash/compact";
import _filter from "lodash/filter";
import _isArray from "lodash/isArray";
import _isObject from "lodash/isObject";
import _last from "lodash/last";
import _map from "lodash/map";
import _mergeWith from "lodash/mergeWith";
import _size from "lodash/size";
import { useTranslation } from "react-i18next";
import useSubsidiary from "./useSubsidiary";

const useTranslate = ({
  configKey,
  namespace,
  namespaces = [],
  parentKey,
  parentKeys = [],
  ...options
} = {}) => {
  const { subsidiary } = useSubsidiary();
  const { t, i18n } = useTranslation([
    ...(namespace ? [namespace] : []),
    ...namespaces,
    subsidiary,
  ]);

  return {
    get config() {
      if (!configKey) return null;
      const i18nOptions = {
        ...options,
        defaultValue: null,
        returnObjects: true,
      };
      const commonConfig = t(`common:${configKey}`, i18nOptions);
      const config = t(configKey, i18nOptions);

      const fallbackLng = _last(i18n.options.fallbackLng);
      const commonFixedT = i18n.getFixedT(fallbackLng, "common");
      const fixedT = i18n.getFixedT(fallbackLng, subsidiary);
      const fallbackLngCommonConfig = commonFixedT(configKey, i18nOptions);
      const fallbackLngConfig = fixedT(configKey, i18nOptions);

      const configs = [
        fallbackLngCommonConfig,
        commonConfig,
        fallbackLngConfig,
        config,
      ];

      if (_isObject(_last(_compact(configs)))) {
        const configObjs = _filter(configs, _isObject);
        const res = _mergeWith({}, ...configObjs, (objValue, srcValue) =>
          _isArray(objValue) &&
          _isArray(srcValue) &&
          _size(objValue) > _size(srcValue)
            ? srcValue
            : undefined
        );
        return res;
      }

      return (
        config ||
        fallbackLngConfig ||
        commonConfig ||
        fallbackLngCommonConfig ||
        null
      );
    },
    i18n,
    language: i18n.language,
    translate: (key, i18nOptions) =>
      key
        ? t(
            [
              ..._map(
                [parentKey, ...parentKeys],
                (parentKey) => `${parentKey ? `${parentKey}.${key}` : null}`
              ),
              key,
            ],
            {
              context: subsidiary,
              ...options,
              ...i18nOptions,
            }
          )
        : "",
  };
};

export default useTranslate;
