import _get from "lodash/get";
import _keyBy from "lodash/keyBy";
import _map from "lodash/map";
import _reduce from "lodash/reduce";
import _set from "lodash/set";
import _some from "lodash/some";
import React, { useState } from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Button from "./Button";
import Text from "./Text";

const FormGroup = ({
  label,
  labelProps,
  controlProps: { required, type, ...controlProps },
  id,
  name,
  ...props
}) => (
  <Form.Group controlId={id} {...props}>
    {/checkbox|radio/.test(type) ? (
      <Form.Check
        label={<Text {...labelProps}>{label}</Text>}
        required={required}
        type={type}
        {...controlProps}
      />
    ) : (
      <>
        <Text as={Form.Label} required={required} {...labelProps}>
          {label}
        </Text>
        <Form.Control required={required} type={type} {...controlProps} />
      </>
    )}
  </Form.Group>
);

const ContactForm = ({
  action = "#",
  className = "",
  controls = [],
  subject = "",
  submit = "Submit",
  ...props
}) => {
  const [body, setBody] = useState(_keyBy(controls, "value.name"));

  return (
    <Container className={`contact-form ${className}`} {...props}>
      <Form action={action} id="body">
        <Row>
          {_map(
            controls,
            ({ label, value: { width = 12, name, ...props } }, key) => (
              <FormGroup
                as={Col}
                controlProps={{
                  onChange: ({ target: { value } }) => {
                    setBody(
                      _set({ ...body }, [name, "value"], {
                        ..._get(body, [name, "value"]),
                        value,
                      })
                    );
                  },
                  name,
                  ...props,
                }}
                id={name}
                key={key}
                label={label}
                lg={width}
              />
            )
          )}
        </Row>
      </Form>
      <Row>
        <Col>
          <Button
            className="w-100"
            disabled={_some(
              controls,
              ({ value: { required, value } }) => required && !value
            )}
            href={`${action}?subject=${encodeURIComponent(
              subject
            )}&body=${_reduce(
              controls,
              (result, { label, value: { form, value = "" } } = {}) =>
                form === "body"
                  ? `${result}${encodeURIComponent(`${label}: ${value}\r\n`)}`
                  : result,
              ""
            )}`}
          >
            {submit}
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default ContactForm;
