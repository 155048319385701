import React, { useState } from 'react';
import { useTranslation, Trans } from "react-i18next";
import { Tab, Nav, Row, Col } from 'react-bootstrap'
import { Helmet } from "react-helmet";

import Banner from '../components/Banner'



export default function ProspectusPage({match}) {
  
  const { t, i18n } = useTranslation();

  const pdfLangType = i18n.language === 'en' ? 'eng' : i18n.language === 'cn' ? 'chi' : 'chi'

  return (
    <div className='content'>
      <Helmet>
        <title>{`${t("prospectus")} | CLSA Premium`}</title>
      </Helmet>
      <Banner title={t('prospectus')} bg={'financial_reports'} brief={t('float_box_universal')}/>
      <section className='container prospectus'>
        <div className="containerRight">
            <ul id="DocumentList" className="relationList2 smargin">
            <li>
              <a href={`/documents/prospectus_${pdfLangType}.pdf`} target="_blank">
                <ul className="w3-row">
                  <li className="w3-col l2 m2">11/06/2013</li>
                  <li className="w3-col l8 m8">{t('prospectus')}</li>
                  <li className="w3-col l1 m1"><img src="/downloadcloud.png" /></li>
                </ul>
              </a>
            </li>
          </ul>

        </div>
      </section>
    </div>
  );
}