import _map from "lodash/map";
import React from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Layout from "../components/Layout";
import LeftBelt from "../components/LeftBelt";
import Paragraph from "../components/Paragraph";
import ScaleImage from "../components/ScaleImage";
import Title from "../components/Title";
import useResponsive from "../hooks/useResponsive";
import useTranslate from "../hooks/useTranslate";

const AboutUsPage = ({ className = "", ...props }) => {
  const { isDesktop, isNotMobile } = useResponsive();
  const { config } = useTranslate({ configKey: "about-us" });

  const {
    banner: bannerProps,
    belt: beltProps,
    helmet: helmetProps,
    intro: introConfig,
    "intro-more": introMoreConfig,
    platform: platformConfig,
  } = config || {};

  return (
    <Layout
      bannerProps={{
        className: `align-items-end ${isDesktop ? "mb-0" : ""}`,
        colProps: { className: "pb-md-5", lg: 6 },
        height: 700,
        rowProps: {
          className: "justify-content-end",
        },
        subtitleProps: { className: "mb-4" },
        titleProps: { display: 6 },
        ...bannerProps,
      }}
      helmetProps={helmetProps}
    >
      {config && (
        <Container
          fluid
          className={`about-us-page p-0 ${className}`}
          {...props}
        >
          {introConfig && (
            <Row noGutters className={`intro ${isDesktop ? "mb-0" : "mb-3"}`}>
              <Col
                className="d-flex flex-column justify-content-center p-5"
                lg={6}
              >
                <Title as="h2" className="mb-4 mx-md-5 text-left">
                  {introConfig.title}
                </Title>
                <Paragraph className="mx-md-5">{introConfig.brief}</Paragraph>
              </Col>
              <Col lg={6}>
                <ScaleImage
                  fluid
                  alt={introConfig.image}
                  src={introConfig.image}
                />
              </Col>
            </Row>
          )}
          {introMoreConfig && (
            <Row
              noGutters
              className={`intro-more bg-light ${isDesktop ? "mb-0" : "mb-3"}`}
            >
              <Col lg={4}>
                <ScaleImage
                  fluid
                  alt={introMoreConfig.image}
                  className="w-100"
                  src={introMoreConfig.image}
                />
              </Col>
              <Col className="align-items-center d-flex py-4 px-5" lg={8}>
                <Row>
                  {_map(introMoreConfig.list, ({ label, value }, key) => (
                    <Col key={key} md={4}>
                      <Title as="h2">{label}</Title>
                      <Paragraph font={{ size: "sm" }}>{value}</Paragraph>
                    </Col>
                  ))}
                </Row>
              </Col>
            </Row>
          )}
          {platformConfig && (
            <Row
              noGutters
              className={`platform ${isDesktop ? "mb-0" : "mb-3"}`}
            >
              <Col
                className="d-flex flex-column justify-content-center p-5"
                lg={6}
              >
                <Title as="h2" className="mb-4 mx-md-5 text-left">
                  {platformConfig.title}
                </Title>
                <Paragraph className="mx-md-5">
                  {platformConfig.brief}
                </Paragraph>
              </Col>
              <Col lg={6}>
                <ScaleImage
                  fluid
                  alt={platformConfig.image}
                  src={platformConfig.image}
                />
              </Col>
            </Row>
          )}
          {beltProps && (
            <LeftBelt
              fluid
              bg="success"
              buttonProps={{
                className: `px-5 py-2 ${isNotMobile ? "w-auto" : "w-100"}`,
                color: "primary",
                variant: "light",
              }}
              buttonsProps={{
                className: "justify-content-center",
              }}
              className="mt-4"
              titleProps={{
                display: isNotMobile ? 5 : 7,
              }}
              {...beltProps}
            />
          )}
        </Container>
      )}
    </Layout>
  );
};

export default AboutUsPage;
