import React from "react";
import DescriptionList from "./DescriptionList";
import Title from "./Title";

const Annotation = ({
  className = "",
  list = [],
  listProps = {},
  title = "",
  titleProps = {},
  ...props
}) => (
  <div className={`annotation ${className}`} {...props}>
    <Title as="h5" font={{ family: "text" }} {...titleProps}>
      {title}
    </Title>
    <DescriptionList className="mb-3" data={list} {...listProps} />
  </div>
);

export default Annotation;
