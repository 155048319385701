import React from "react";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import useLink from "../hooks/useLink";
import useSubsidiary from "../hooks/useSubsidiary";
import useTranslate from "../hooks/useTranslate";

const footerLinkStyle = css`
  &:hover {
    text-decoration: none;
  }
`;

const StyledA = styled.a`
  ${footerLinkStyle}
`;

const StyledLink = styled(Link)`
  ${footerLinkStyle}
`;

const FooterLink = ({
  children = "",
  as: Tag = "div",
  scrollToTop,
  target,
  to = "",
  ...props
}) => {
  const { isExternal } = useLink();
  const { subsidiary } = useSubsidiary();
  const { language } = useTranslate();

  const sharedClassName = "text-white text-nowrap";

  return (
    // TODO fix document links
    <Tag {...props}>
      {isExternal(to) ? (
        <StyledA
          className={`${subsidiary}-footer-external-link ${sharedClassName}`}
          href={to}
          rel="noopener noreferrer"
          target="_blank"
        >
          {children}
        </StyledA>
      ) : (
        <StyledLink
          className={`${subsidiary}-footer-link ${sharedClassName}`}
          to={`/${language}/${subsidiary}/${to}${scrollToTop ? "?top=0" : ""}`}
          {...(target === "_blank"
            ? { rel: "noopener noreferrer", target: "_blank" }
            : {})}
        >
          {children}
        </StyledLink>
      )}
    </Tag>
  );
};

export default FooterLink;
