import React from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { Link } from "react-router-dom";
import Layout from "../components/Layout";
import Text from "../components/Text";
import Title from "../components/Title";
import useSubsidiary from "../hooks/useSubsidiary";
import useTranslate from "../hooks/useTranslate";

const IntermediatePage = () => {
  const { subsidiary } = useSubsidiary();
  const {
    config: { enter, title },
  } = useTranslate({
    configKey: "intermediate",
  });

  return (
    <Layout headerProps={{ bg: "dark" }} showBanner={false}>
      <Container className={`${subsidiary}-intermediate-page p-5`}>
        <Row className="p-5">
          <Col className="pt-5 text-center">
            <Title as="h1" font={{ family: "text" }}>
              {title}
            </Title>
          </Col>
        </Row>
        <Row className="p-5">
          <Col className="text-center">
            <Link to={`/${subsidiary}`}>
              <Text as="h1" className="font-weight-normal">
                {enter}
              </Text>
            </Link>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default IntermediatePage;
