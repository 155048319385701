import _map from "lodash/map";
import React from "react";
import ListGroup from "react-bootstrap/ListGroup";
import { Link } from "react-scroll";
import styled from "styled-components";
import useHeaderHeight from "../hooks/useHeaderHeight";
import Title from "./Title";

const StyledListGroupItem = styled(ListGroup.Item)`
  &:active,
  &.active {
    color: ${({ theme: { dark } }) => dark} !important;
  }
`;

const SidebarNav = ({ className = "", nav = [], ...props }) => {
  const [headerHeight] = useHeaderHeight();
  return (
    <ListGroup
      className={`sidebar-nav ${className}`}
      variant="flush"
      {...props}
    >
      {_map(nav, ({ label, value }, key) => (
        <StyledListGroupItem
          action
          className="bg-transparent border-secondary pl-0 text-muted"
          key={key}
        >
          <Link
            smooth
            className={`scroll-to-${value}-link text-secondary`}
            offset={headerHeight * -1 - 20}
            to={value}
          >
            <Title as="h6" className="mb-0">
              {label}
            </Title>
          </Link>
        </StyledListGroupItem>
      ))}
      <ListGroup.Item />
    </ListGroup>
  );
};

export default SidebarNav;
