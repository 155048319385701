import React from "react";
import Jumbotron from "react-bootstrap/Jumbotron";
import styled from "styled-components";
import Belt from "./Belt";

const StyledJumbotron = styled(Jumbotron)`
  background-image: ${({ backgroundimage = "" }) =>
    `url("${backgroundimage}")`};
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: ${({ height = 500 }) => `${height}px`};
`;

const BackgroundBelt = (props) => <StyledJumbotron as={Belt} {...props} />;

export default BackgroundBelt;
