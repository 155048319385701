import _map from "lodash/map";
import React from "react";
import Text from "./Text";

const DescriptionList = ({
  className = "",
  data = [],
  labelProps: { className: labelClassName = "", ...labelProps } = {},
  lineProps: { className: lineClassName = "", ...lineProps } = {},
  valueProps: { className: valueClassName = "", ...valueProps } = {},
  ...props
}) => (
  <dl className={`description-list ${className}`} {...props}>
    {_map(data, ({ label, value }, key) => (
      <div className={lineClassName} key={key} {...lineProps}>
        {label && (
          <Text
            as="dt"
            className={`label d-inline mr-1 ${labelClassName}`}
            {...labelProps}
          >
            {label}
          </Text>
        )}
        {value && (
          <Text
            as="dd"
            className={`value d-inline ${valueClassName}`}
            {...valueProps}
          >
            {value}
          </Text>
        )}
      </div>
    ))}
  </dl>
);

export default DescriptionList;
