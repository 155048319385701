import React from "react";
import SidebarLinks from "./SidebarLinks";
import SidebarNav from "./SidebarNav";

const Sidebar = ({ className = "", links, nav, ...props }) => (
  <div className={`sidebar ${className}`} {...props}>
    {nav && <SidebarNav className="mb-4" nav={nav} />}
    {links && <SidebarLinks links={links} />}
  </div>
);

export default Sidebar;
