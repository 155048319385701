import React from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import BackgroundBelt from "../components/BackgroundBelt";
import Buttons from "../components/Buttons";
import Layout from "../components/Layout";
import ListBelt from "../components/ListBelt";
import Paragraph from "../components/Paragraph";
import ScaleImage from "../components/ScaleImage";
import Title from "../components/Title";
import useResponsive from "../hooks/useResponsive";
import useTranslate from "../hooks/useTranslate";

const PlatformPage = ({ className = "", ...props }) => {
  const { isDesktop, isNotMobile } = useResponsive();
  const { config } = useTranslate({ configKey: "mt5" });

  const {
    apps: appsConfig,
    banner: bannerProps,
    belt: beltProps,
    helmet: helmetProps,
    "list-belt": listBeltConfig,
    intro: introConfig,
  } = config || {};

  return (
    <Layout
      bannerProps={bannerProps}
      headerProps={{ bg: "dark" }}
      helmetProps={helmetProps}
    >
      {config && (
        <Container fluid className={`mt5-page p-0 ${className}`} {...props}>
          {introConfig &&
            (({ brief, image, title }) => (
              <Container>
                <Row className="intro pt-5">
                  <Col className="text-center">
                    <Title
                      as="h2"
                      className="font-weight-bold mb-5 text-center"
                      display={isNotMobile ? 6 : 8}
                    >
                      {title}
                    </Title>
                    <Paragraph className="text-center">{brief}</Paragraph>
                    <ScaleImage
                      fluid
                      alt="mt5"
                      className="text-center"
                      src={image}
                      width="50%"
                    />
                  </Col>
                </Row>
              </Container>
            ))(introConfig)}
          {appsConfig &&
            (({ buttons }) => (
              <Row className="apps pb-5">
                <Col className="mx-auto">
                  <Buttons
                    buttonProps={{
                      className: `px-5 py-3 mx-2 ${
                        isDesktop ? "w-auto" : "w-100"
                      }`,
                    }}
                    className="justify-content-center"
                    config={buttons}
                  />
                </Col>
              </Row>
            ))(appsConfig)}
          {listBeltConfig && (
            <ListBelt
              fluid
              className="bg-light"
              colProps={{ lg: 4 }}
              itemProps={{
                title: {
                  className: "font-weight-bold text-info",
                  font: { family: "text" },
                },
              }}
              rowProps={{ className: "px-4 py-5 my-5" }}
              titleProps={{ display: 6 }}
              {...listBeltConfig}
            />
          )}
          {beltProps && (
            <BackgroundBelt
              fluid
              briefProps={{
                as: "h4",
                className: "font-italic",
              }}
              className="text-white"
              height={300}
              titleProps={{
                display: 7,
              }}
              {...beltProps}
            />
          )}
        </Container>
      )}
    </Layout>
  );
};

export default PlatformPage;
