import _map from "lodash/map";
import React, { Fragment, useState } from "react";
import BsCard from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import ListGroup from "react-bootstrap/ListGroup";
import Row from "react-bootstrap/Row";
import { Link } from "react-router-dom";
import AccordionBelt from "../components/AccordionBelt";
import CardBelt from "../components/CardBelt";
import Layout from "../components/Layout";
import LeftBelt from "../components/LeftBelt";
import NotifyModal from "../components/NotifyModal";
import Paragraph from "../components/Paragraph";
import Text from "../components/Text";
import Title from "../components/Title";
import TitleSplitter from "../components/TitleSplitter";
import useTranslate from "../hooks/useTranslate";

const AccordionBeltWrapper = ({ list, title }) => {
  const [activeKey, setActiveKey] = useState();
  return (
    <AccordionBelt
      accordionProps={{
        data: [
          {
            label: title,
            value: (
              <ListGroup variant="flush">
                {_map(list, ({ date, subtitle, title, to }, key) => (
                  <ListGroup.Item className="d-flex px-0" key={key}>
                    <Container className="p-0">
                      <Row>
                        <Col className="flex-grow-1">
                          <Text as="h6" className="text-primary">
                            {subtitle}
                          </Text>
                          {((to) => {
                            const Wrapper = to ? Link : Fragment;
                            return (
                              <Wrapper to={to}>
                                <Title
                                  as="h4"
                                  className="text-body"
                                  font={{ family: "text" }}
                                >
                                  {title}
                                </Title>
                              </Wrapper>
                            );
                          })(to)}
                        </Col>
                        <Col className="align-items-end d-flex flex-grow-0 justify-content-end">
                          <Text as="h6">{date}</Text>
                        </Col>
                      </Row>
                    </Container>
                  </ListGroup.Item>
                ))}
              </ListGroup>
            ),
          },
        ],
        icon: activeKey ? "caret-up" : "caret-down",
        iconPosition: "bottom",
        toggleProps: {
          className: "bg-transparent border-0 text-center",
        },
        onSelect: (key) => {
          setActiveKey(key);
        },
      }}
      className={activeKey ? "bg-white text-primary" : "bg-primary text-white"}
    />
  );
};

const Card = ({ brief, className = "", image, title, to, ...props }) => (
  <BsCard className={`border-0 rounded-0 ${className}`} {...props}>
    <BsCard.Img src={image} variant="top" />
    <BsCard.Body>
      {title && (
        <>
          <TitleSplitter className="mb-2" width={4} />
          <BsCard.Title
            as="a"
            className={`${to}-external-link`}
            href={to}
            rel="noopener noreferrer"
            target="_blank"
          >
            <Title as="h4" className="text-primary" font={{ family: "text" }}>
              {title}
            </Title>
          </BsCard.Title>
        </>
      )}
      <Paragraph as={Card.Text}>{brief}</Paragraph>
    </BsCard.Body>
  </BsCard>
);

const EducationPage = ({ className = "", ...props }) => {
  const { config, language } = useTranslate({ configKey: "education" });

  const {
    accordion: accordionProps,
    banner: bannerProps,
    belt: beltProps,
    "card-belt": cardBeltConfig,
    helmet: helmetProps,
    notify: notifyProps,
  } = config || {};

  return (
    <Layout
      bannerProps={{
        buttonProps: {
          className: "justify-content-start",
          color: "primary",
          variant: "light",
        },
        subtitleProps: {
          as: "h5",
          font: { family: "text" },
          position: "top",
        },
        titleProps: { className: "w-50", display: 6 },
        variant: "left-bottom",
        ...bannerProps,
      }}
      helmetProps={helmetProps}
    >
      {config && (
        <Container
          fluid
          className={`education-page p-0 ${className}`}
          {...props}
        >
          {accordionProps && <AccordionBeltWrapper {...accordionProps} />}
          {cardBeltConfig &&
            (({ cards, ...props }) => (
              <CardBelt
                fluid
                className="bg-light py-5"
                titleProps={{ className: "text-center", display: 6 }}
                {...props}
              >
                {_map(cards, (props, key) => (
                  <Card key={key} {...props} />
                ))}
              </CardBelt>
            ))(cardBeltConfig)}
          {beltProps && (
            <LeftBelt
              fluid
              briefProps={{
                className: "text-center",
              }}
              buttonProps={{
                as: "div",
                className: "bg-white border-0 rounded-0 shadow w-25",
              }}
              buttonsProps={{
                className: "justify-content-center",
              }}
              titleProps={{
                className: "text-center",
                display: 6,
              }}
              {...beltProps}
            />
          )}
        </Container>
      )}
      {language === "en" && (
        <NotifyModal
          show
          bodyProps={{ className: "px-5 text-center" }}
          footerProps={{ className: "border-0 pb-5 justify-content-center" }}
          headerProps={{ className: "border-0" }}
          {...notifyProps}
        />
      )}
    </Layout>
  );
};

export default EducationPage;
