import React from "react";
import BsPagination from "react-bootstrap/Pagination";

const Pagination = ({ page = 1, onPageChange, totalPages = 0, ...props }) => (
  <BsPagination {...props}>
    <BsPagination.First onClick={() => onPageChange(1)} />
    {page - 1 > 0 && (
      <BsPagination.Prev onClick={() => onPageChange(page - 1)} />
    )}
    {page - 2 > 0 && <BsPagination.Ellipsis disabled />}
    {page - 2 > 0 && (
      <BsPagination.Item onClick={() => onPageChange(page - 2)}>
        {page - 2}
      </BsPagination.Item>
    )}
    {page - 1 > 0 && (
      <BsPagination.Item onClick={() => onPageChange(page - 1)}>
        {page - 1}
      </BsPagination.Item>
    )}
    <BsPagination.Item active>{page}</BsPagination.Item>
    {page + 1 <= totalPages && (
      <BsPagination.Item onClick={() => onPageChange(page + 1)}>
        {page + 1}
      </BsPagination.Item>
    )}
    {page + 2 <= totalPages && (
      <BsPagination.Item onClick={() => onPageChange(page + 2)}>
        {page + 2}
      </BsPagination.Item>
    )}
    {page + 2 < totalPages && <BsPagination.Ellipsis disabled />}
    {page + 1 <= totalPages && (
      <BsPagination.Next onClick={() => onPageChange(page + 1)} />
    )}
    <BsPagination.Last onClick={() => onPageChange(totalPages)} />
  </BsPagination>
);

export default Pagination;
