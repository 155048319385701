import React from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import useSubsidiary from "../hooks/useSubsidiary";
import useTranslate from "../hooks/useTranslate";
import ContactInfo from "./ContactInfo";
import Copyright from "./Copyright";
import Disclaimer from "./Disclaimer";
import FooterNav from "./FooterNav";
import Logo from "./Logo";

const Footer = ({ className = "", fluid = false, ...props }) => {
  const { subsidiary } = useSubsidiary();
  const { config } = useTranslate({ configKey: "footer" });

  const {
    "contact-info": contactInfoProps,
    disclaimer: disclaimerProps,
    logo: logoProps,
    nav: navProps,
  } = {
    ...config,
    // Rename props to merge with config
    ...(({ contactInfoProps, disclaimerProps, logoProps, navProps }) => ({
      ...(contactInfoProps ? { "contact-info": contactInfoProps } : {}),
      ...(disclaimerProps ? { disclaimer: disclaimerProps } : {}),
      ...(logoProps ? { logo: logoProps } : {}),
      ...(navProps ? { nav: navProps } : {}),
    }))(props),
  };

  return (
    <footer className={`${subsidiary}-footer bg-dark ${className}`} {...props}>
      {/* TODO p-0 can take out after removing App.scss */}
      <Container className="p-0" fluid={fluid}>
        {logoProps && (
          <Row noGutters className="logo pt-5">
            <Col>
              <Logo {...logoProps} />
            </Col>
          </Row>
        )}
        {contactInfoProps && (
          <Row noGutters className="contact-info py-5">
            <Col>
              <ContactInfo
                className="text-white"
                rowProps={{ noGutters: true }}
                {...contactInfoProps}
              />
            </Col>
          </Row>
        )}
        {navProps && (
          <Row noGutters className="nav border-bottom pb-5">
            <Col>
              <FooterNav
                className="text-white"
                listGroupItemProps={{ className: "p-0" }}
                {...navProps}
              />
            </Col>
          </Row>
        )}
        {disclaimerProps && (
          <Row noGutters className="disclaimer border-bottom py-5">
            <Col>
              <Disclaimer {...disclaimerProps} />
            </Col>
          </Row>
        )}
        <Row noGutters className="copyright py-3">
          <Col>
            <Copyright />
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
