/** @format */

import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "./Button";
import Paragraph from "./Paragraph";
import Title from "./Title";

const NotifyModal = ({
  bodyProps,
  brief = "",
  className = "",
  show: defaultShow = false,
  footerProps,
  headerProps,
  title = "",
  ok,
  ...props
}) => {
  const [show, setShow] = useState(defaultShow);

  return (
    <Modal
      centered
      backdrop="static"
      className={`notify-modal ${className}`}
      show={show}
      onHide={() => {}}
      {...props}
    >
      <Modal.Header {...headerProps}>
        <Modal.Title as={Title} font={{ family: "text" }}>
          {title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body {...bodyProps}>
        <Paragraph>{brief}</Paragraph>
      </Modal.Body>
      <Modal.Footer {...footerProps}>
        <Button variant="primary" onClick={() => setShow(false)}>
          {ok}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default NotifyModal;
