import React, { useState } from 'react';

import { useTranslation, Trans } from "react-i18next";
import { Link } from 'react-router-dom'
// import WarningPopupBox from '../components/WarningPopupBox';

export default function Homepage() {
  
  const [lang, setLang] = useState('English');
  const { t, i18n } = useTranslation();

  return (
    <div className='content'>
      <section className='homepage'>
        <div className='section1'>
          <div className='text'>
            
            <h1>{t('banner_1')}</h1>
            
            <h3 className='line1'>{t('banner_2')}</h3>
            <h3>{t('banner_3')}</h3>
          </div>
        </div>
      </section>
      <section className="promoteSec">
        <div className="row">
          
          <div className="col-md-6 col-xm-12">
            <div className="textBlock">
              <p>{t('promo_1')}</p>
              <p>{t('promo_2')}</p>
              <Link to="/platform/download_mt5/hk" className="btn btn-primary">{t('download')}</Link>
            </div>
          </div>
          
          <div className="col-md-6 col-xm-12">
            <div className='img'></div>
          </div>
          
        </div>
      </section>
      {/* <WarningPopupBox /> */}
    </div>
  );
}