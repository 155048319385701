import React, { useState } from 'react';
import { useTranslation, Trans } from "react-i18next";


export default function Disclaimer(props) {
  const { t, i18n } = useTranslation();
  const subsidiary = props.subsidiary
  const tabSelected = props.tabSelected
  const subParam = {param1: subsidiary === 'au' ? t('sub_au') : (subsidiary === 'hk' ? t('sub_hk') : t('sub_nz'))}
  return (
    <div className="disclaimer">
      {subsidiary !== 'au' && <div>{t('disclaimer_1', { context: subsidiary, ...subParam })}</div> }
      <br />
      {subsidiary === 'au'
        ? <div>{t('disclaimer_2_au', subParam)}</div>
        : <div>{t('disclaimer_2', { context: subsidiary, ...subParam })}</div>
      }
      <br />
      <div>{t('disclaimer_3', { context: subsidiary })}</div>
      <br />
      {(subsidiary === 'hk' || subsidiary === 'bullion') ? null : <div>{t('disclaimer_4', {param1: tabSelected === 'forex' ? t('margin_forex') : t('cfd')})}</div>}
    </div>
  )
}
