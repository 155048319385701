import _map from "lodash/map";
import React from "react";
import Carousel from "react-bootstrap/Carousel";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Buttons from "../components/Buttons";
import Layout from "../components/Layout";
import LeftBelt from "../components/LeftBelt";
import Banner from "../components/NewBanner";
import Paragraph from "../components/Paragraph";
import Title from "../components/Title";
import useResponsive from "../hooks/useResponsive";
import useTranslate from "../hooks/useTranslate";
import DescriptionList from "../components/DescriptionList";

const AuHomePage = ({ className = "", ...props }) => {
  // const { isNotMobile, isDesktopOrLaptop } = useResponsive();
  // const {
  //   config: {
  //     carousel: carouselConfig,
  //     "contact-info": contactInfoConfig,
  //     belt: beltProps = {},
  //     helmet: helmetProps = {},
  //     intro: introConfig = {},
  //   },
  // } = useTranslate({ configKey: "home" });
  const { isNotMobile } = useResponsive();
  const {
    config: {
      banner: bannerProps,
      "contact-info": contactInfoConfig,
      helmet: helmetProps = {},
      intro: introConfig = {},
    },
  } = useTranslate({ configKey: "home" });

  return (
    <Layout
      bannerProps={bannerProps}
      // headerProps={{ bg: "dark" }}
      helmetProps={helmetProps}
    >
      <Container
        className={`bullion-home-page mt-4 pt-5 ${className}`}
        {...props}
      >
        {introConfig &&
          (({ brief, title }) => (
            <Row className="intro my-5">
              <Col>
                <Title
                  as="h2"
                  className="font-weight-bold"
                  display={isNotMobile ? 6 : 8}
                >
                  {title}
                </Title>
                <Paragraph
                  className="text-justify"
                  font={{ size: isNotMobile ? "md" : "sm" }}
                >
                  {brief}
                </Paragraph>
              </Col>
            </Row>
          ))(introConfig)}
        {contactInfoConfig &&
          (({ list, title }) => (
            <Row className="intro my-5">
              <Col>
                <Title
                  as="h2"
                  className="font-weight-bold"
                  display={isNotMobile ? 6 : 8}
                >
                  {title}
                </Title>
                <DescriptionList
                  className="mb-3"
                  data={list}
                  labelProps={{
                    className: "font-weight-normal",
                  }}
                  valueProps={{
                    className: "font-weight-bold",
                  }}
                />
              </Col>
            </Row>
          ))(contactInfoConfig)}
      </Container>

      {/* <Carousel controls={isDesktopOrLaptop}>
        {_map(carouselConfig, (props, key) => (
          <Carousel.Item key={key}>
            <Banner
              alignItems={isNotMobile ? "center" : "top"}
              height={1076 / (isNotMobile ? 1 : 2)}
              titleProps={{ className: "pt-5", display: isNotMobile ? 3 : 5 }}
              {...props}
            />
          </Carousel.Item>
        ))}
      </Carousel>
      <Container className={`au-home-page ${className}`} {...props}>
        <Row className="intro my-5">
          <Col className={isNotMobile ? "my-5" : ""}>
            <Title
              as="h2"
              className="text-center"
              display={isNotMobile ? 5 : 7}
            >
              {introConfig.title}
            </Title>
            <Paragraph
              className="text-center"
              font={{ size: isNotMobile ? "md" : "sm" }}
            >
              {introConfig.brief}
            </Paragraph>
            <Buttons
              buttonProps={{
                className: `px-5 py-2 ${isNotMobile ? "w-auto" : "w-100"}`,
                font: { size: "sm" },
              }}
              className="mt-5 justify-content-center text-center"
              config={introConfig.buttons}
            />
          </Col>
        </Row>
      </Container>
      <LeftBelt
        fluid
        bg="success"
        briefProps={{
          className: "text-center text-white",
        }}
        buttonProps={{
          className: `px-5 py-2 ${isNotMobile ? "w-auto" : "w-100"}`,
          color: "primary",
          variant: "light",
        }}
        buttonsProps={{
          className: "justify-content-center",
        }}
        className="mt-4"
        titleProps={{
          className: "text-center text-white",
          display: isNotMobile ? 5 : 7,
        }}
        {...beltProps}
      /> */}
    </Layout>
  );
};

export default AuHomePage;
