import React, { useState } from 'react';

export default function AUPolicyCN(){
	return (
		<div>
			<p><strong><u>Introduction</u></strong></p>
<p>Your privacy has always been important to us. At CLSA Premium Pty Limited ABN 28 101 829 467 (<strong>we</strong>,&nbsp;<strong>us</strong>,&nbsp;<strong>our</strong>) with its registered address at Level 35 Grosvenor Place, 225 George Street, Sydney NSW 2000, we are committed to respecting and protecting the confidentiality of information and the privacy of our clients. We are also bound by the Australian Privacy Principles and the Privacy Act 1988 (Cth) and will continuously ensure we comply with our requirements in our dealings with you. Our privacy notice will be reviewed regularly in correspondence with the changing environment such as regulation, legislation and technology. Any information we hold will be governed by the most current privacy notice displayed on our website <a href="http://www.clsapremium.com">www.clsapremium.com</a>. We also strive to provide an open and transparent environment for our clients and the information as to how we use personal information. Personal information is defined as information about an identifiable individual (a natural person). Where our documents or interactions with you ask for personal information, we will generally provide the purposes for its use and to whom it may be disclosed. The privacy policy sets out:</p>
<ul>
<li>The types of personal information we collect and hold</li>
<li>Unsolicited Information</li>
<li>How we collect and hold personal information</li>
<li>The purpose for which we collect, hold, use and disclose personal information</li>
<li>How clients may access personal information about themselves, that is held by us, and correction of such information</li>
<li>How to lodge a complaint about a breach of the APP by us, and how we will deal with such a complaint</li>
<li>Security and protecting your personal information</li>
</ul>
<p><strong><u>The types of personal information we collect and hold</u></strong></p>
<p>Personal information we collect about you includes but not limited to your full name; date of birth; residential and postal address; phone numbers; email address; tax file numbers; drivers license details; passport details; financial details; occupation; bank account details and any other information we may consider necessary to undertake our activities.</p>
<p>The records of all your transactions and activities on accounts you have with us, including information about products and services provided to you and trades conducted by you will also be collected and maintained by us.</p>
<p>We may record any communications, electronic, by telephone, in person or otherwise, that we have with you in relation to the services we provide to you and our relationship with you. These recordings will be our sole property and will constitute evidence of the communications between us. Such telephone conversations may be recorded without the use of a warning tone or any other further notice.</p>
<p>We will also collect statistical information about the use of our Website.&nbsp;Information about your computer and your visits to and use of the Website (such as your IP address, location, browser type, user name, documents downloaded and types of transactions conducted) may be recorded when you use our Website. &nbsp;This information may be used to identify you. &nbsp;We will treat this information as personal information.</p>
<p>You are not required to provide the personal information that we request but, if you choose not to do so, in many cases we will not be able to provide you with our products and services or respond to any queries you may have.</p>
<p><strong><u>Unsolicited Information</u></strong></p>
<p>If you provide us with personal information that we have not requested then we will endeavor to only retain the information that we are otherwise entitled to hold because of the products and services we provide. However, if this additional information is surplus to our requirements but is provided to us in a manner where it is combined with information that we are either required or entitled to retain then you acknowledge that this unsolicited information may be held by us in the same manner as the balance of your personal information.</p>
<p><strong><u>How we collect and hold personal information</u></strong></p>
<p>We will collect your personal information in the following circumstances:</p>
<ul>
<li>when you provide that personal information to us, including via the Website and any related services, through any registration process, through any contact with us (e.g. telephone call or email)</li>
<li>when we obtain information from third parties, subject to your consent and from publicly available information</li>
<li>when you buy or use our products and services, e.g. opening an account, conducting transactions through our platform</li>
<li>when we provide our products and services to you, e.g. opening an account for you, monitoring your transactions.</li>
</ul>
<p>Once we have collected your personal information, they will be stored in our internal CRM system, secured office onsite, or in a secured storage facility.</p>
<p><strong><u>The purpose for which we collect, hold, use and disclose personal information </u></strong></p>
<p>We collect, hold and use your personal information for the following purposes:</p>
<ul>
<li>to provide, manage and administer products and services we provide to you, including keeping you updated whilst you are a customer, including your margin obligations, and the available funds in your account</li>
<li>to verify your identity and other personal information you provide</li>
<li>to promote and market the information of our products and services that you might be interested in electronically (e.g. by text or email)</li>
<li>request for feedback and information from you regarding our product and services</li>
<li>to undertake credit checks on you (if necessary)</li>
<li>to bill you and to collect money that you owe us</li>
<li>to respond to communications from you</li>
<li>to conduct research and statistical analysis (on an anonymized basis)</li>
<li>to protect and/or enforce our legal rights and interests, including defending any claim</li>
<li>to comply with our obligations under applicable laws and requests from regulators for information such as customer identification requirements under the Anti-Money Laundering and Counter-Terrorism Financing Act 2006 (Cth) and compliance requirements under the Corporations Act 2001 (Cth) and other legislation governing the provision of financial services.</li>
<li>for any other purpose authorized by you or the Act.</li>
</ul>
<p>We may disclose your personal information to: &nbsp;</p>
<ul>
<li>another company within the group of companies of which we are party for the purpose of assisting us to provide products and services to you, and to comply with our obligations at law</li>
<li>any business that supports provision of our products and services, including any person that hosts or maintains any underlying IT system or data centre that we use to provide the Website or other products and services</li>
<li>a credit reference agency for the purpose of credit checking on you</li>
<li>other third parties (for anonymized statistical information)</li>
<li>a person who can require us to supply your personal information (e.g. a regulatory authority)</li>
<li>any new owners or re-organized entities in the event of a business restructuring, sale, purchase, or joint venture affecting our business</li>
<li>any other person authorized by the Act or another law (or otherwise on request from a regulator)</li>
<li>any other person authorized by you.</li>
</ul>
<p>A business that supports the provision of our products and services may be located outside Australia.&nbsp; This means that your personal information may be held and processed outside Australia.</p>
<p><strong><u>How clients may access personal information about themselves, that is held by us, and correction of such information</u></strong></p>
<p>Subject to certain grounds for refusal set out in the Act, you have the right to access your readily retrievable personal information that we hold and to request a correction to your personal information.&nbsp; We will need evidence to confirm that you are the individual to whom the personal information relates.</p>
<p>With respect to a request for correction, if we think the correction is reasonable and we are reasonably able to change the personal information, we will make the correction. &nbsp;If we do not make the correction, we will take reasonable steps to note on the personal information that you requested the correction.</p>
<p>If you want to exercise those rights please contact us using our contact details set out at the end of this document.&nbsp; You should provide evidence of who you are and set out the details of your request (e.g. the personal information, or the correction, that you are requesting).</p>
<p>We may charge you our reasonable costs of providing to you copies of your personal information or correcting that information.</p>
<p>If you follow a link on our Website to third party websites or applications, the owner of that website or application will have its own privacy policy relating to your personal information. We may use cookies to monitor your use of the website and collect the statistical information referred to above in addition to allowing you to access your account online.&nbsp; You may disable cookies by changing the settings on your browser, although this may mean that you cannot use all of the features of the Website.</p>
<p><strong><u>How to lodge a complaint about a breach of the APP by us, and how we will deal with such a complaint</u></strong></p>
<p>For any privacy related feedback, or complaints, please send your written request to: CLSA Premium Pty Limited<br /> Email: <a href="mailto:services@claspremium.com">services@claspremium.com</a><br /> Postal Address: Level 35 Grosvenor Place, 225 George Street, Sydney, NSW 2000 <br /> Phone: +61 2 8880 5588</p>
<p>If you are not satisfied with a response received from CLSA Premium in reference to a privacy complaint, or feel that CLSA Premium may have breached an Australian Privacy Principle, then you have the right to refer the complaint directly to the Office of the Australian Information Commissioner (OAIC), previously known as Office of the Privacy Commissioner. A complaint form may be lodged via the OAIC website, or via the details below.</p>
<p>For provisions of the Privacy Act, please refer to the website www.oaic.gov.au</p>
<p>Office of the Australian Information Commissioner<br /> Email: <a href="mailto:enquiries@oaic.gov.au">enquiries@oaic.gov.au</a><br /> Phone: 1300 363 992<br /> Address: GPO Box 5218 Sydney NSW 2001<br /> Fax: +61 2 9284 9666<br /> www.oaic.gov.au</p>
<p>As per CLSA Premium&rsquo;s IDR &ndash; complaints handling policy, if you lodged a written complaint with SCM, you should receive a response within 5 business days from the date CLSA Premium receives the written formal complaint. The written complaint will be dealt by the Compliance department and potentially the Account Executive managing the account. If additional time is required to resolve the complaint, the compliance department will inform you of this. CLSA Premium aims to resolve a complaint as soon as possible, and within 45 days.</p>
<p>If you are still not satisfied with CLSA Premium&rsquo;s response, you can then proceed to lodge a formal complaint with OAIC.</p>
<p><strong>Security and protecting your personal information</strong></p>
<p>We will take reasonable steps to protect your personal information from misuse, loss, unauthorized access or disclosure. The steps that have been undertaken are as follows:</p>
<ul>
<li>Written confidentiality and security policies that apply to all our employees;</li>
<li>Security measures for access to our systems and premises and for document and file storage;</li>
<li>By reasonably limiting internal access to clients&rsquo; personal information to staff who require to fulfil our obligations toward to and related matters; and</li>
<li>Firewall and data encryption measures protecting our website.</li>
</ul>
<p>You can also access other organization&rsquo;s websites through links on our website. Please note that we are not responsible for the privacy standards and processes of those organizations and external website. We cannot promise that the electronic communication, including the Website, email and text message, between you and us will be complete, confidential or secure.&nbsp;</p>
<p>If you are not located in Australia, the information we collect may be processed in and transferred between your location and Australia. Australia may not have equivalent data protection laws to those in force in your location.</p>
<p><strong>Contact details</strong></p>
<p>If you want to:</p>
<ul>
<li>Make a general enquiry about our privacy notice</li>
<li>Change your personal information</li>
<li>Access your personal information</li>
</ul>
<p>Please feel free to phone us on +61 2 8880 5588 or, email us at <a href="mailto:services@claspremium.com">services@claspremium.com</a> or write to us at</p>
<p>CLSA Premium Pty Limited<br /> Level 35 Grosvenor Place<br /> 225 George Street<br /> Sydney<br /> 2000</p>
<p>&nbsp;</p>
		</div>
	)
}