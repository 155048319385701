import React, { Children, cloneElement, useEffect } from "react";
import styled from "styled-components";
import useHeaderHeight from "../hooks/useHeaderHeight";
import useQuery from "../hooks/useQuery";
import Helmet from "./Helmet";
import Banner from "./NewBanner";
import Footer from "./NewFooter";
import Header from "./NewHeader";

const StyledDiv = styled.div`
  margin-top: ${({ margintop }) => `${margintop}px`};
`;

const Layout = ({
  bannerProps,
  children = <></>,
  fluid,
  footerProps,
  headerProps: { bg: headerBackground, ...headerProps } = {},
  helmetProps,
  showBanner = true,
  showFooter = true,
  showHeader = true,
  showHelmet = true,
  ...props
} = {}) => {
  const [headerHeight] = useHeaderHeight();
  const query = useQuery();

  const marginTop =
    headerBackground && headerBackground !== "transparent" ? headerHeight : 0;

  useEffect(() => {
    if (query.has("top")) {
      window.scrollTo(query.get("top") || 0, 0);
    }
  }, [query]);

  const inner = (
    <>
      {showHelmet && <Helmet {...helmetProps} />}
      {showHeader && (
        <Header bg={headerBackground} fluid={fluid} {...headerProps} />
      )}
      {showBanner && <Banner fluid={fluid} {...bannerProps} />}
      {Children.map(
        children,
        (child) =>
          child && cloneElement(child, { ...props, fluid, ...child.props })
      )}
      {showFooter && <Footer fluid={fluid} {...footerProps} />}
    </>
  );

  return (
    <StyledDiv className="wrapper overflow-hidden" margintop={marginTop}>
      {inner}
    </StyledDiv>
  );
};

export default Layout;
