import React, { useState } from 'react';

export default function NZMarginEN(){
	return(
		<div className="">
      <section className="mainSection">
        <div className="customContainer">
          <div className="bodyBox" name="pos_1">
            <div className="insideBox">
              <div className="kvbTitle">
                <h3>Deposit</h3>
              </div>
              <p>FX Margin Account Deposit (New Zealand)</p>
            </div>
          </div>
        </div>
      </section>
      <section className="depositSec first">
        <div className="customContainer">
          <div className="depositBox">
            <h3>1. Deposit</h3>
            <h4>Bank Wire / Telegraphic Transfer:</h4>
            <h5>Please provide the following information when deposit / transfer funds into your account:</h5>
            <ul className="list">
              <li>Your full name</li>
              <li>Your margin account number (if you have an account)</li>
              <li>Please remark the funds is for "FX Margin Trading" in your reference</li>
              <li>Once transfer / wire is successful, please email bank slip to customer service email: <a href="mailto:services@clsapremium.com">services@clsapremium.com</a></li>
              <li>CLSA Premium New Zealand Ltd  will credit the funds into your trading account in 1 business day after the receipt of the funds</li>
            </ul>
            <br />
            <h5>If customer wires / transfers funds is in a currency different than the default currency in the margin trading account:</h5>
            <ul className="list">
              <li>Customer should convert the funds with a bank first before the wire / transfer.</li>
              <li>Or, contact CLSA Premium New Zealand Ltd customer service representative to assist you to open a "Currency Conversion Account". Once we receive the funds, they will be converted to the default currency and placed into the customer's trading account.</li>
            </ul>
            <div className="noteBox">
              <div className="alertNote">Fees*: Bank wire / Telegraphic Transfer Charges: The intermediary bank or remittance bank may charge extra transfer fee. Please check with your bank before you transfer. CLSA Premium New Zealand Ltd does not charge any fees. If the actual amount received is less than the transferred amount, that is caused by the charges of the correspondent bank. This is out of KVB Kunlun's control.</div>
              <div className="alertNote"><span>Please note:</span></div>
              <ul className="noteList num">
                <li>CLSA Premium New Zealand Ltd does not accept cash deposit. All deposits must be made from customer's own bank account via electronic transfer.</li>
                <li>CLSA Premium New Zealand Ltd does not accept third party funding. All deposit / transfer must be made from the customer's own bank account. A third party deposit / transfer will be returned to the original account. The fees generated through this process will be borne by the customer.</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className="depositSec">
        <div className="customContainer">
          <div className="depositBox">
            <h3>2. Bank Wire Details</h3>
            <h5>For the bank wire details, please contact customer service.</h5>
            {/*<h4>Currency</h4>
            <h5 className="floatText"><span>AUD </span>
              <div className="content">Beneficiary Bank for FX Margin Trading Accounts using AUD as Settlement Currency</div>
            </h5>
            <h4>Beneficiary Bank Account</h4>
            <h5 className="floatText"><span>Beneficiary Bank</span>
              <div className="content">The Bank of New Zealand</div>
            </h5>
            <h5 className="floatText"><span>Beneficiary Bank Address</span>
              <div className="content">Bank of New Zealand, Wellington, New Zealand</div>
            </h5>
            <h5 className="floatText"><span>Beneficiary Bank Swift Code</span>
              <div className="content">BKNZNZ22</div>
            </h5>
            <h5 className="floatText"><span>Beneficiary</span>
              <div className="content">CLSA Premium New Zealand Ltd –Client Fund account</div>
            </h5>
            <h5 className="floatText"><span>Beneficiary Account</span>
              <div className="content">646570-0002</div>
            </h5>
            <div className="noteBox">
              <div className="alertNote">* If you want to remit other currencies but not AUD, please contact our customer services.</div>
            </div>*/}
          </div>
          {/*<div className="depositBox">
            
            <h4>Currency</h4>
            <h5 className="floatText"><span>USD </span>
              <div className="content">Beneficiary Bank for FX Margin Trading Accounts using USD as Settlement Currency</div>
            </h5>
            <h4>Beneficiary Bank Account</h4>
            <h5 className="floatText"><span>Beneficiary Bank</span>
              <div className="content">The Bank of New Zealand</div>
            </h5>
            <h5 className="floatText"><span>Beneficiary Bank Address</span>
              <div className="content">Bank of New Zealand, Wellington, New Zealand</div>
            </h5>
            <h5 className="floatText"><span>Beneficiary Bank Swift Code</span>
              <div className="content">BKNZNZ22</div>
            </h5>
            <h5 className="floatText"><span>Beneficiary</span>
              <div className="content">CLSA Premium New Zealand Ltd –Client Fund account</div>
            </h5>
            <h5 className="floatText"><span>Beneficiary Account</span>
              <div className="content">646570-0000</div>
            </h5>
            <div className="noteBox">
              <div className="alertNote">* If you want to remit other currencies but not USD, please contact our customer services.</div>
            </div>
          </div>
          <div className="depositBox">
            
            <h4>Currency</h4>
            <h5 className="floatText"><span>JPY </span>
              <div className="content">Beneficiary Bank for FX Margin Trading Accounts using JPY as Settlement Currency</div>
            </h5>
            <h4>Beneficiary Bank Account</h4>
            <h5 className="floatText"><span>Beneficiary Bank</span>
              <div className="content">The Bank of New Zealand</div>
            </h5>
            <h5 className="floatText"><span>Beneficiary Bank Address</span>
              <div className="content">Bank of New Zealand, Wellington, New Zealand</div>
            </h5>
            <h5 className="floatText"><span>Beneficiary Bank Swift Code</span>
              <div className="content">BKNZNZ22</div>
            </h5>
            <h5 className="floatText"><span>Beneficiary</span>
              <div className="content">CLSA Premium New Zealand Ltd –Client Fund account</div>
            </h5>
            <h5 className="floatText"><span>Beneficiary Account</span>
              <div className="content">646570-0001</div>
            </h5>
            <div className="noteBox">
              <div className="alertNote">* If you want to remit other currencies but not JPY, please contact our customer services.</div>
            </div>
          </div>
          <div className="depositBox">
            
            <h4>Currency</h4>
            <h5 className="floatText"><span>NZD </span>
              <div className="content">Beneficiary Bank for FX Margin Trading Accounts using NZD as Settlement Currency</div>
            </h5>
            <h4>Beneficiary Bank Account</h4>
            <h5 className="floatText"><span>Beneficiary Bank</span>
              <div className="content">The Bank of New Zealand</div>
            </h5>
            <h5 className="floatText"><span>Beneficiary Bank Address</span>
              <div className="content">Bank of New Zealand, Wellington, New Zealand</div>
            </h5>
            <h5 className="floatText"><span>Beneficiary Bank Swift Code</span>
              <div className="content">BKNZNZ22</div>
            </h5>
            <h5 className="floatText"><span>Beneficiary</span>
              <div className="content">CLSA Premium New Zealand Ltd –Client Fund account</div>
            </h5>
            <h5 className="floatText"><span>Beneficiary Account</span>
              <div className="content">02-0108-0385950-000</div>
            </h5>
            <div className="noteBox">
              <div className="alertNote">* If you want to remit other currencies but not NZD, please contact our customer services.</div>
            </div>
          </div>*/}
        </div>
      </section>
      <section className="mainSection inPage" name="pos_2">
        <div className="customContainer">
          <div className="bodyBox">
            <div className="insideBox">
              <div className="kvbTitle">
                <h3>Withdrawal</h3>
              </div>
              <p>FX Margin Account Withdrawal (New Zealand)</p>
            </div>
          </div>
          <div className="depositBox">
            <h3>1. Fund Withdrawal</h3>
            <h4>For customer to withdraw their funds, the request can be submitted in the following ways:</h4>
            <h5 className="floatText"><span>Email</span>
              <div className="content"><a href="mailto:services@clsapremium.com">services@clsapremium.com</a></div>
            </h5>
            <h4>Customer send the Withdrawal Request Form via the designated email address</h4>
          </div>
          <div className="depositBox">
            <h3>2. Withdrawal Procedures</h3>
            <ul className="list dot">
              <li>If the withdrawal request is submitted before 4:30pm from Monday to Friday during local business hours in New Zealand (excludes Public Holidays) (11:30 am Beijing Time - New Zealand Day Light Saving Time / 12:30 pm Beijing Time - New Zealand Standard Time), the withdrawal request will be processed on the same day. Otherwise, it will be processed on the next working day.</li>
              <li>After the withdrawal amount deducted from the clients MT5 trading account, the funds generally arrive the customer-designated bank account within 1 to 3 working days.</li>
              <li>Whether the withdrawal request can be processed promptly depending on (a) the availability of usable margin of the margin accounts; (b) the correctness of information on the withdrawal form is correct, and (c) whether the beneficiary account is the customer’s designated bank account.</li>
              <li>If the customer change the designated bank account, upon submitting the withdrawal form / request, the customer please also provide the new designated bank account information and the bank statement of the account, or the front side of the bank card. The proof of the bank account needs to be verified.</li>
            </ul>
          </div>
          <div className="depositBox">
            <h3>3. Withdrawal Methods</h3>
            <h4>Telegraphic transfer / bank wire (CLSA Premium New Zealand Ltd does not offer cash withdrawals)</h4>
          </div>
          <div className="depositBox">
            <h3>4. Withdrawal Fees</h3>
            <h4>CLSA Premium New Zealand Ltd does not charge fees for customer withdrawal; however there is a telegraphic transfer / bank wire fee of US$15.00 in average. The intermediary bank or beneficiary bank may charge extra fees. Such fees are not charged by CLSA Premium New Zealand Ltd and are out of our control. Please check with the beneficiary bank.</h4>
          </div>
        </div>
      </section>
    </div>
	)
}