import React, { useState } from 'react';

export default function HKEN(props) {

  const name =  props.sub === "bullion" ? "Margin Trading" : "FX Margin Trading";
  const email =  props.sub === "bullion" ? "services.bullion@clsapremium.com" : "services.hk@clsapremium.com";

	return(
    <div className="">
        {props.id === "deposit" && <>
          <section className="mainSection">
            <div className="customContainer">
              <div className="bodyBox" name="pos_1">
                <div className="insideBox">
                  <div className="kvbTitle">
                    <h3>Deposit</h3>
                  </div>
                  <p>Account Deposit (Hong Kong)</p>
                </div>
              </div>
            </div>
          </section>
          <section className="depositSec first">
            <div className="customContainer">
              <div className="depositBox">
                <h3>1. Deposit</h3>
                <h4>Bank Wire / Telegraphic Transfer:</h4>
                <h5>Please provide the following information when depositing/transfering funds into your account:</h5>
                <ul className="list">
                  <li>Your full name</li>
                  <li>Your margin account number (if you have an account)</li>
                  <li>Please remark the funds is for "{name}" in your reference</li>
                  <li>Once transfer / wire is successful, please email the bank slip to the customer service email: <a href={`mailto:${email}`}>{email}</a></li>
                  <li>CLSA Premium will credit the funds into your trading account in 1 business day after the receipt of the funds</li>
                  {/* <li>If you want to remit the currencies other than USD, please contact CLSA Premium Limited customer service representative to assist you.</li> */}
                </ul>
                <div className="noteBox">
                  <div className="alertNote">Fees*: Bank wire / Telegraphic Transfer Charges: The intermediary bank or remittance bank may charge extra transfer fee. Please check with your bank before you transfer. CLSA Premium does not charge any fees. If the actual amount received is less than the transferred amount, that is caused by the charges of the correspondent bank. This is out of CLSA Premium Limited's control.</div>
                  <div className="alertNote"><span>Please note:</span></div>
                  <ul className="noteList num">
                    <li>CLSA Premium does not accept cash deposit. All deposits must be made from client's own bank account via electronic transfer.</li>
                    <li>CLSA Premium does not accept third party funding. All deposits/transfers must be made from the client's own bank account. A third party deposit / transfer will be returned to the original account. The fees generated through this process will be borne by the client.</li>
                  </ul>
                </div>
              </div>
            </div>
          </section>
          <section className="depositSec">
            <div className="customContainer">
              <div className="depositBox">
                <h3>2. Bank Wire Details</h3>
                <h5>For the bank wire details, please contact customer service representative at <a href={`mailto:${email}`}>{email}</a>.</h5>
                {/*<h4>Currency</h4>
                <h5 className="floatText"><span>USD </span>
                  <div className="content">Beneficiary Bank for FX Margin Trading Accounts using USD as Settlement Currency</div>
                </h5>
                <h4>Beneficiary Bank Account</h4>
                <h5 className="floatText"><span>Beneficiary Bank</span>
                  <div className="content">Bank of China (Hong Kong)</div>
                </h5>
                <h5 className="floatText"><span>Beneficiary Bank Address</span>
                  <div className="content">Bank of China Tower, 1 Garden Road, Central, Hong Kong</div>
                </h5>
                <h5 className="floatText"><span>Beneficiary Bank Swift Code</span>
                  <div className="content">BKCHHKHHXXX</div>
                </h5>
                <h5 className="floatText"><span>Beneficiary</span>
                  <div className="content">CLSA Premium International (HK) Limited - Clients’ A/C</div>
                </h5>
                <h5 className="floatText"><span>Beneficiary Account</span>
                  <div className="content">012-875-9-263883-9</div>
                </h5>
                <div className="noteBox">
                  <div className="alertNote">* If you want to remit other currencies but not USD, please contact our customer services.</div>
                </div>*/}

              </div>
            </div>
          </section>
        </>}
        {props.id === "withdrawal" &&
          <section className="mainSection inPage" name="pos_2">
            <div className="customContainer">
            <div className="bodyBox">
              <div className="insideBox">
                <div className="kvbTitle">
                  <h3>Withdrawal</h3>
                </div>
                <p>Account Withdrawal (Hong Kong)</p>
              </div>
            </div>
            <div className="depositBox">
              <h3>1. Fund Withdrawal</h3>
              <h4>For customer to withdraw their funds, the request can be submitted in the following ways:</h4>
              <h5 className="floatText"><span>Email</span>
                <div className="content"><a href={`mailto:${email}`}>{email}</a></div>
              </h5>
              {/* <h4>Customer may submit the Withdrawal Request Form via email, and must sign on the withdrawal form.</h4> */}
            </div>
            <div className="depositBox">
              <h3>2. Withdrawal Procedures</h3>
              <ul className="list dot">
                <li>If the withdrawal request is submitted before 12:00 noon Monday to Friday during local business hours in Hong Kong (excludes Public Holidays), the withdrawal request will be processed on the same day. Otherwise, it will be processed on the next working day.</li>
                <li>After the withdrawal amount being deducted from the client's MT5 trading account, the funds generally arrive the customer-designated bank account on the same day or the next working day.
                {/* If the withdrawal is made by cheque, please contact our customer service representative in advance and the customer must collect the cheque in person on the same day. */}
                </li>
                <li>Whether the withdrawal request can be processed promptly depending on the availability of usable margin of the margin accounts, or whether the information on the Withdrawal Request Form is correct.</li>
              </ul>
            </div>
            <div className="depositBox">
              <h3>3. Withdrawal Methods</h3>
              <h4>Transfer or bank wire</h4>
            </div>
            <div className="depositBox">
              <h3>4. Withdrawal Fees</h3>
              <h4>CLSA Premium does not charge fees for customer withdrawal.</h4>
              {/* <table>
                <thead>
                  <tr>
                    <th>Withdrawal Method</th>
                    <th>Account Currency</th>
                    <th>Withdrawal Fees</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Transfer or bank wire</td>
                    <td>USD</td>
                      <td><span className="red">CLSA Premium International (HK) Limited does not charge fees for customer withdrawal. </span>
                        Withdrawal fee is dependent on where the receiving bank is, please contact our customer services for more information.
                      </td>
                  </tr>
                  <tr>
                    <td>Cheque</td>
                    <td>USD</td>
                    <td><span className="red">$0 </span>(Please contact our customer services in advance and the customer must collect the cheque in person on the same day.)</td>
                  </tr>
                </tbody>
              </table> */}
            </div>
          </div>
          </section>
        }
      </div>
	)
}