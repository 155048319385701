import _map from "lodash/map";
import React from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Text from "./Text";
import Title from "./Title";

const ListBelt = ({
  brief,
  className = "",
  colProps: { className: colClassName = "", ...colProps } = {},
  itemProps: {
    className: itemClassName = "",
    title: itemTitleProps,
    ...itemProps
  } = {},
  list,
  rowProps,
  title,
  titleProps,
  ...props
}) => (
  <Container className={`list-belt ${className}`} {...props}>
    <Row>
      <Container>
        <Row {...rowProps}>
          <Col className="mb-4" xs={12}>
            <Title as="h2" display={5} {...titleProps}>
              {title}
            </Title>
          </Col>
          {_map(list, ({ value, label }, index) => (
            <Col
              className={`mb-4 ${colClassName}`}
              key={index}
              lg={3}
              {...colProps}
            >
              <div className={`h-100 ${itemClassName}`} {...itemProps}>
                <Title as="h5" display={8} {...itemTitleProps}>
                  {label}
                </Title>
                <ul className="pl-4">
                  {_map(value, (value, key) => (
                    <Text as="li" key={key}>
                      {value}
                    </Text>
                  ))}
                </ul>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </Row>
  </Container>
);

export default ListBelt;
