import React from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import DescriptionList from "../components/DescriptionList";
import Layout from "../components/Layout";
import Paragraph from "../components/Paragraph";
import Title from "../components/Title";
import useResponsive from "../hooks/useResponsive";
import useTranslate from "../hooks/useTranslate";

const BullenHomePage = ({ className = "", ...props }) => {
  const { isNotMobile } = useResponsive();
  const {
    config: {
      banner: bannerProps,
      "contact-info": contactInfoConfig,
      helmet: helmetProps = {},
      intro: introConfig = {},
    },
  } = useTranslate({ configKey: "home" });

  return (
    <Layout
      bannerProps={bannerProps}
      headerProps={{ bg: "dark" }}
      helmetProps={helmetProps}
    >
      <Container
        className={`bullion-home-page mt-4 pt-5 ${className}`}
        {...props}
      >
        {introConfig &&
          (({ brief, title }) => (
            <Row className="intro my-5">
              <Col>
                <Title
                  as="h2"
                  className="font-weight-bold"
                  display={isNotMobile ? 6 : 8}
                >
                  {title}
                </Title>
                <Paragraph
                  className="text-justify"
                  font={{ size: isNotMobile ? "md" : "sm" }}
                >
                  {brief}
                </Paragraph>
              </Col>
            </Row>
          ))(introConfig)}
        {contactInfoConfig &&
          (({ list, title }) => (
            <Row className="intro my-5">
              <Col>
                <Title
                  as="h2"
                  className="font-weight-bold"
                  display={isNotMobile ? 6 : 8}
                >
                  {title}
                </Title>
                <DescriptionList
                  className="mb-3"
                  data={list}
                  labelProps={{
                    className: "font-weight-normal",
                  }}
                  valueProps={{
                    className: "font-weight-bold",
                  }}
                />
              </Col>
            </Row>
          ))(contactInfoConfig)}
      </Container>
    </Layout>
  );
};

export default BullenHomePage;
