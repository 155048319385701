import React from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";
import Row from "react-bootstrap/Row";
import styled from "styled-components";
import Annotation from "../components/Annotation";
import Belt from "../components/Belt";
import Layout from "../components/Layout";
import ListBelt from "../components/ListBelt";
import Paragraph from "../components/Paragraph";
import Sidebar from "../components/Sidebar";
import Table from "../components/Table";
import Title from "../components/Title";
import TitleSplitter from "../components/TitleSplitter";
import useResponsive from "../hooks/useResponsive";
import useTranslate from "../hooks/useTranslate";

const StyledCol = styled(Col)`
  &:last-child {
    border: none !important;
  }
`;

const StyledTitle = styled(Title)`
  p {
    display: inline;
    margin-bottom: auto;

    &:not(:first-child) {
      ::before {
        content: " ";
      }
    }

    @media (min-width: 768px) {
      display: block;
      ::before {
        content: none;
      }
    }
  }
`;

const StyledParagraph = styled(Paragraph)`
  table {
    white-space: nowrap;
    width: 100%;
    th {
      font-weight: normal;
    }
    td,
    th {
      border: ${({
        theme: { "black-900": color, "table-border-width": width },
      }) => `${width} solid ${color}`};
      &:not(:first-child) {
        width: 10%;
      }
    }
  }
`;

const RewardTable = ({
  brief = "",
  className = "",
  list,
  table: tableProps,
  title = "",
  ...props
}) => {
  const { isNotMobile } = useResponsive();
  return (
    <Container className={`reward-table p-0 ${className}`} {...props}>
      <Row>
        <Col>
          <Title as="h3">{title}</Title>
        </Col>
      </Row>
      <Row>
        <Col lg={9}>
          <Table headCellProps={{ className: "bg-light" }} {...tableProps} />
        </Col>
        <Col lg={3}>
          <Paragraph
            className={`font-weight-bold ${
              isNotMobile ? "" : "text-center"
            } text-justify text-success`}
            font={{ size: "lg" }}
          >
            {brief}
          </Paragraph>
        </Col>
      </Row>
    </Container>
  );
};

const WelcomeRewardPage = ({ className = "", ...props }) => {
  const { isNotMobile } = useResponsive();
  const { config } = useTranslate({ configKey: "welcome-rewards" });

  const {
    "account-open-reward": accountOpenRewardProps,
    annotation: annotationProps,
    banner: bannerProps,
    belt: beltConfig,
    campaign: campaignProps,
    helmet: helmetProps,
    "list-belt": listBeltConfig,
    intro: introConfig,
    sidebar: sidebarProps,
    term: termConfig,
    "trade-reward": tradeRewardProps,
  } = config || {};

  return (
    <Layout
      bannerProps={bannerProps}
      headerProps={{ bg: "dark" }}
      helmetProps={helmetProps}
    >
      {config ? (
        <Container
          className={`welcome-rewards-page py-5 ${className}`}
          {...props}
        >
          <Row>
            <Col lg={9}>
              <Image fluid className="mb-4" {...campaignProps} />
              {introConfig &&
                (({ brief, title }) => (
                  <>
                    <Title as="h2" display={isNotMobile ? 5 : 7}>
                      {title}
                    </Title>
                    <TitleSplitter className="mb-4" color="success" />
                    <Paragraph className="mb-4 text-justify">{brief}</Paragraph>
                  </>
                ))(introConfig)}
            </Col>
            <Col lg={3}>
              <Sidebar {...sidebarProps} />
            </Col>
          </Row>
          <Row>
            <Col>
              <RewardTable className="mb-4" {...accountOpenRewardProps} />
            </Col>
          </Row>
          <Row>
            <Col>
              <RewardTable className="mb-4" {...tradeRewardProps} />
            </Col>
          </Row>
          <Row>
            <Col>
              <Annotation className="mb-5" {...annotationProps} />
            </Col>
          </Row>
          <Row>
            <Col>
              <ListBelt
                colProps={{
                  as: StyledCol,
                  className: isNotMobile ? "border-right" : "border-bottom",
                  lg: true,
                  xs: 12,
                }}
                itemProps={{
                  className: "text-center",
                  title: {
                    as: StyledTitle,
                    font: {
                      family: "text",
                    },
                  },
                }}
                titleProps={{ className: "text-center" }}
                {...listBeltConfig}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Belt
                buttonProps={{
                  className: `px-5 py-3 ${
                    isNotMobile ? "mx-5 w-auto" : "w-100"
                  }`,
                }}
                className="bg-transparent pt-0"
                {...beltConfig}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              {termConfig &&
                (({ brief, title }) => (
                  <>
                    <Title as="h3">{title}</Title>
                    <StyledParagraph className="overflow-auto text-justify">
                      {brief}
                    </StyledParagraph>
                  </>
                ))(termConfig)}
            </Col>
          </Row>
        </Container>
      ) : null}
    </Layout>
  );
};

export default WelcomeRewardPage;
