import React, { useState } from 'react';

import { useTranslation, Trans } from "react-i18next";

export default function NoMatchPage({ location }){
	const { t, i18n } = useTranslation();

	return(
	  <div className='content'>
	    <section className='no-match'>
	      <h3>{t('no_match_for')}<code>{location.pathname}</code></h3>
	    </section>
	  </div>
	)
}

