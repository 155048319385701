import { library } from "@fortawesome/fontawesome-svg-core";
import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _map from "lodash/map";
import React from "react";
import BsAccordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import Title from "./Title";

library.add(faCaretDown, faCaretUp);

const Accordion = ({
  bodyProps,
  className = "",
  data = [],
  icon,
  iconPosition,
  iconProps: { ...iconProps } = {},
  toggleProps,
  titleProps,
  ...props
}) => (
  <BsAccordion className={`accordions ${className}`} {...props}>
    {_map(data, ({ label, value }, key) => (
      <Card className="bg-transparent border-0 rounded-0" key={key}>
        <BsAccordion.Toggle
          as={Card.Header}
          eventKey={`${key}`}
          {...toggleProps}
        >
          <Title as="h5" font={{ family: "text" }} {...titleProps}>
            {label}
            {iconPosition === "right" && (
              <FontAwesomeIcon className="ml-1" icon={icon} {...iconProps} />
            )}
          </Title>
          {iconPosition === "bottom" && (
            <FontAwesomeIcon icon={icon} size="lg" {...iconProps} />
          )}
        </BsAccordion.Toggle>
        <BsAccordion.Collapse eventKey={`${key}`}>
          <Card.Body {...bodyProps}>{value}</Card.Body>
        </BsAccordion.Collapse>
      </Card>
    ))}
  </BsAccordion>
);
export default Accordion;
