import React from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import useResponsive from "../hooks/useResponsive";
import useTranslate from "../hooks/useTranslate";
import DetailList from "./DetailList";
import Helmet from "./Helmet";
import Paragraph from "./Paragraph";
import ProductGuide from "./ProductGuide";
import ProductSidebar from "./ProductSidebar";
import ProductTradeTime from "./ProductTradeTime";
import Title from "./Title";
import TitleSplitter from "./TitleSplitter";

const PreciousMetals = ({ className = "", ...props }) => {
  const { isNotMobile } = useResponsive();
  const { config } = useTranslate({ configKey: "precious-metals" });

  const {
    helmet: helmetProps,
    intro: introConfig,
    product: productConfig,
    sidebar: sidebarProps,
    "swap-charge": swapChargeProps,
    "trade-time": tradetimeProps,
  } = config || {};

  return config ? (
    <Container className={`precious-metals ${className}`} {...props}>
      <Helmet {...helmetProps} />
      <Row className="py-5">
        <Col lg={9}>
          {introConfig && (
            <Row className="intro mb-5">
              <Col>
                <Title>{introConfig.title}</Title>
                <TitleSplitter className="mb-4" color="success" />
                <Paragraph>{introConfig.brief}</Paragraph>
              </Col>
            </Row>
          )}
          {productConfig && (
            <Row className="product mb-5" id="precious-metals-product">
              <Col>
                <Title as="h2" className="mb-3">
                  {productConfig.title}
                </Title>
                <DetailList
                  showBottomGutter
                  className={`shadow ${isNotMobile ? "px-4" : "px-2"}`}
                  data={productConfig.list}
                  labelProps={{
                    className: "text-nowrap",
                  }}
                  valueProps={{
                    className: "text-center text-nowrap",
                    textProps: {
                      font: {
                        size: "sm",
                      },
                    },
                  }}
                />
              </Col>
            </Row>
          )}
          <Row className="time mb-5" id="precious-metals-time">
            <Col>
              <ProductTradeTime {...tradetimeProps} />
            </Col>
          </Row>
          <Row className="swap mb-5" id="precious-metals-swap">
            <Col>
              <ProductGuide {...swapChargeProps} />
            </Col>
          </Row>
        </Col>
        <Col lg={3}>
          <ProductSidebar {...sidebarProps} />
        </Col>
      </Row>
    </Container>
  ) : null;
};

export default PreciousMetals;
