import React, { useState } from 'react';

export default function NZMarginCN(){
	return(
		<div className="">
      <section className="mainSection">
        <div className="customContainer">
          <div className="bodyBox" name="pos_1">
            <div className="insideBox">
              <div className="kvbTitle">
                <h3>存款</h3>
              </div>
              <p>外汇保证金入金须知(新西兰)</p>
            </div>
          </div>
        </div>
      </section>
      <section className="depositSec first">
        <div className="customContainer">
          <div className="depositBox">
            <h3>1. 入金</h3>
            <h4>银行转帐 / 电汇</h4>
            <h5>客户如需存款，请提供以下资料：</h5>
            <ul className="list">
              <li>客户全名</li>
              <li>保证金帐号（若已经开户）</li>
              <li>请在转帐/电汇单的附注一栏注明：汇款用途为外汇保证金业务（FX Margin Trading）</li>
              <li>成功汇款后，请将入金银行凭证电邮到客服邮箱：<a href="mailto:services@clsapremium.com">services@clsapremium.com</a>。</li>
              <li>当我们收到该笔款项后，通常在一个工作天内入帐到客户的交易帐户。</li>
            </ul>
            <br />
            <h5>如果客户的汇款币种不同于其保证金交易帐户的默认币种:</h5>
            <ul className="list">
              <li>客户请先自行在银行兑换成与保证金帐户币种相同的货币后汇出。</li>
              <li>或请先联系我们的客服人员，我们会帮助客户开立一个「外币兑换帐户」，当收到客户的汇款后再转换成相关货币入帐到客户的保证金交易帐户。</li>
            </ul>
            <div className="noteBox">
              <div className="alertNote">* 相关费用：银行转帐/电汇，中转银行或汇款银行或许额外加收转帐费，请向汇款银行查询。CLSA Premium New Zealand Ltd并不会收取任何费用，如实际到帐资金少于客户存入的金额，这是由于跨行/国际汇款的中转银行扣除了汇款手续费所致。</div>
              <div className="alertNote"><span>注意：</span></div>
              <ul className="noteList num">
                <li>CLSA Premium New Zealand Ltd并不接受任何形式的现金存款。所有存款必须通过客户名下的银行帐户以电子方式转帐予我们。</li>
                <li>CLSA Premium New Zealand Ltd并不接受第三方存款或汇款。所有款项必须由客户本人银行帐户汇出。由非客户本人银行帐号汇出的款项将被退回始发帐户。所产生之汇兑损失亦由客户本人承担。</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className="depositSec">
        <div className="customContainer">
          <div className="depositBox">
            <h3>2. 收款银行资料</h3>
            <h5>收款银行资料，请咨询客服</h5>
            {/*<h4>币种</h4>
            <h5 className="floatText"><span>AUD</span>
              <div className="content">以澳元为结算货币的外汇保证金帐户收款银行</div>
            </h5>
            <h4>收款银行资料</h4>
            <h5 className="floatText"><span>收款行名称</span>
              <div className="content">The Bank of New Zealand</div>
            </h5>
            <h5 className="floatText"><span>收款行地址</span>
              <div className="content">Bank of New Zealand, Wellington, New Zealand</div>
            </h5>
            <h5 className="floatText"><span>收款行的 Swift 代码</span>
              <div className="content">BKNZNZ22</div>
            </h5>
            <h5 className="floatText"><span>收款人</span>
              <div className="content">CLSA Premium New Zealand Ltd – Client Fund account</div>
            </h5>
            <h5 className="floatText"><span>收款人帐号</span>
              <div className="content">646570-0002</div>
            </h5>
            <div className="noteBox">
              <div className="alertNote">* 澳元结算帐户若以其它外币存入款项，请与我们的客户服务人员联络。</div>
            </div>*/}
          </div>
          {/*<div className="depositBox">
            <h4>币种</h4>
            <h5 className="floatText"><span>USD</span>
              <div className="content">以美元为结算货币的外汇保证金帐户收款银行</div>
            </h5>
            <h4>收款银行资料</h4>
            <h5 className="floatText"><span>收款行名称</span>
              <div className="content">The Bank of New Zealand</div>
            </h5>
            <h5 className="floatText"><span>收款行地址</span>
              <div className="content">Bank of New Zealand, Wellington, New Zealand</div>
            </h5>
            <h5 className="floatText"><span>收款行的 Swift 代码</span>
              <div className="content">BKNZNZ22</div>
            </h5>
            <h5 className="floatText"><span>收款人</span>
              <div className="content">CLSA Premium New Zealand Ltd – Client Fund account</div>
            </h5>
            <h5 className="floatText"><span>收款人帐号</span>
              <div className="content">646570-0000</div>
            </h5>
            <div className="noteBox">
              <div className="alertNote">* 美元结算帐户若以其它外币存入款项，请与我们的客户服务人员联络。</div>
            </div>
          </div>
          <div className="depositBox">
            <h4>币种</h4>
            <h5 className="floatText"><span>JPY</span>
              <div className="content">以日元为结算货币的外汇保证金帐户收款银行</div>
            </h5>
            <h4>收款银行资料</h4>
            <h5 className="floatText"><span>收款行名称</span>
              <div className="content">The Bank of New Zealand</div>
            </h5>
            <h5 className="floatText"><span>收款行地址</span>
              <div className="content">Bank of New Zealand, Wellington, New Zealand</div>
            </h5>
            <h5 className="floatText"><span>收款行的 Swift 代码</span>
              <div className="content">BKNZNZ22</div>
            </h5>
            <h5 className="floatText"><span>收款人</span>
              <div className="content">CLSA Premium New Zealand Ltd – Client Fund account</div>
            </h5>
            <h5 className="floatText"><span>收款人帐号</span>
              <div className="content">646570-0001</div>
            </h5>
            <div className="noteBox">
              <div className="alertNote">* 日元结算帐户若以其它外币存入款项，请与我们的客户服务人员联络。</div>
            </div>
          </div>
          <div className="depositBox">
            <h4>币种</h4>
            <h5 className="floatText"><span>NZD</span>
              <div className="content">以纽元为结算货币的外汇保证金帐户收款银行</div>
            </h5>
            <h4>收款银行资料</h4>
            <h5 className="floatText"><span>收款行名称</span>
              <div className="content">The Bank of New Zealand</div>
            </h5>
            <h5 className="floatText"><span>收款行地址</span>
              <div className="content">Bank of New Zealand, Wellington, New Zealand</div>
            </h5>
            <h5 className="floatText"><span>收款行的 Swift 代码</span>
              <div className="content">BKNZNZ22</div>
            </h5>
            <h5 className="floatText"><span>收款人</span>
              <div className="content">CLSA Premium New Zealand Ltd – Client Fund account</div>
            </h5>
            <h5 className="floatText"><span>收款人帐号</span>
              <div className="content">02-0108-0385950-000</div>
            </h5>
            <div className="noteBox">
              <div className="alertNote">* 纽元结算帐户若以其它外币存入款项，请与我们的客户服务人员联络。</div>
            </div>
          </div>*/}
        </div>
      </section>
      <section className="mainSection inPage" name="pos_2">
        <div className="customContainer">
          <div className="bodyBox">
            <div className="insideBox">
              <div className="kvbTitle">
                <h3>取款</h3>
              </div>
              <p>外汇保证金取款须知(新西兰)</p>
            </div>
          </div>
          <div className="depositBox">
            <h3>1. 取款</h3>
            <h4>客户如需取款，取款要求可通过以下方式提交：</h4>
            <h5 className="floatText"><span>电邮</span>
              <div className="content"><a href="mailto:services@clsapremium.com">services@clsapremium.com</a></div>
            </h5>
            <h4>客户通过绑定邮箱发送取款表格</h4>
          </div>
          <div className="depositBox">
            <h3>2. 取款流程</h3>
            <ul className="list dot">
              <li>如取款申请在新西兰当地营业时间星期一至星期五下午 4:30 前提交(公众假期除外)(北京时间早上 11:30 - 新西兰 夏令时间 / 北京时间下午 12:30 - 新西兰冬令时间)，取款要求将会在当天审理。否则，取款要求将会在第二个工作天 处理。</li>
              <li>取款在 MT5 交易帐户扣除后，资金一般可在 1-3 个工作天到达客户指定银行帐户。</li>
              <li>取款申请能否处理需视乎帐户的可用保证金是否足够;填写取款表格资料是否正确，及收款银行帐户是否为客户的绑定银行帐户。</li>
              <li>如客户需要更改绑定银行帐户资料，请在提交取款申请时一并提供新的绑定银行帐户资料及该帐户之银行对帐单或银行卡正面验证件。</li>
            </ul>
          </div>
          <div className="depositBox">
            <h3>3. 取款方式</h3>
            <h4>电汇/银行转帐(本公司不提供现金取款)</h4>
          </div>
          <div className="depositBox">
            <h3>4. 取款手续费</h3>
            <h4>CLSA Premium New Zealand Ltd 不收取客户取款手续费。但取款产生银行电汇费一般为每笔 15 美元。中转银 行或收款银行或许额外加收转帐费。此费用并不由 CLSA Premium New Zealand Ltd 收取，请向收款银行查询。</h4>
            
          </div>
        </div>
      </section>
    </div>
	)
}