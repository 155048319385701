import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _map from "lodash/map";
import React from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Paragraph from "./Paragraph";
import Title from "./Title";
import useResponsive from "../hooks/useResponsive";

const IconBelt = ({
  bg = "white",
  brief,
  briefProps,
  className = "",
  iconBriefProps,
  iconProps,
  iconTitleProps: { className: iconTitleClassName, ...iconTitleProps } = {},
  innerProps,
  list,
  title,
  titleProps: { className: titleClassName, ...titleProps } = {},
  ...props
}) => {
  const { isNotMobile } = useResponsive();
  return (
    <Container className={`icon-belt bg-${bg} ${className}`} {...props}>
      <Row>
        <Container {...innerProps}>
          <Row>
            <Col className={`${isNotMobile ? "mb-4" : ""} text-center`} xs={12}>
              <Title
                as="h2"
                className={`mb-3 ${titleClassName}`}
                display={isNotMobile ? 5 : 7}
                {...titleProps}
              >
                {title}
              </Title>
              <Paragraph {...briefProps}>{brief}</Paragraph>
            </Col>
            {_map(list, ({ brief, icon, title } = {}, index) => (
              <Col className="text-center my-5" key={index} lg={4} sm={6}>
                {icon && (
                  <FontAwesomeIcon icon={icon} size="4x" {...iconProps} />
                )}
                <Title
                  as="h5"
                  className={`mt-3 ${iconTitleClassName}`}
                  font={{ family: "text" }}
                  {...iconTitleProps}
                >
                  {title}
                </Title>
                <Paragraph {...iconBriefProps}>{brief}</Paragraph>
              </Col>
            ))}
          </Row>
        </Container>
      </Row>
    </Container>
  );
};

export default IconBelt;
