/** @format */

import { useParams } from "react-router-dom";

const useSubsidiary = () => {
  const {
    lang = "en",
    language: languageParam = "en",
    lng = "en",
    sub = "home",
    subsidiary: subsidiaryParam = "home",
  } = useParams();

  const language = lng || lang || languageParam || "en";
  const subsidiary = subsidiaryParam || sub || "home";

  localStorage.setItem("subsidiary", subsidiary);

  return { language, subsidiary };
};

export default useSubsidiary;
