import React, { useState } from 'react';
import { useTranslation, Trans } from "react-i18next";
import { Tab, Nav, Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { Helmet } from "react-helmet";

import Banner from '../components/Banner'
import ApiPopupBox from '../components/ApiPopupBox'
// import WarningPopupBox from '../components/WarningPopupBox'

export default function SummaryHKPage({match}) {
  // Declare a new state variable, which we'll call "count"
  const [lang, setLang] = useState('English');
  const { t, i18n } = useTranslation();

  const id = match.params.id;
  const i18nOptions = {
    keySeparator: false
  };

  return (
    <div className='content'>
      <Helmet>
        <title>{t("title_sub")}</title>
      </Helmet>
      <Banner title={`CLSA Premium ${id === "bullion" ? t("bullion") : t('hong_kong')}`} bg={'location'} subsidiary={match.url.split('/')[1]}/>
      <ApiPopupBox subsidiary={match.url.split('/')[1]}/>
      {/* <WarningPopupBox /> */}
      <section className='container location'>
        <div className='block'>
          <h2>{t('company_intro')}</h2>
          <div className='brief'>{t('summary1.1', { context: id, ...i18nOptions})}</div>
        </div>
        {id === "bullion" ? null : (<div className='block'>
        {/*<h2>{t('accreditation')}</h2>
          <article>
            <img src='/about-6_2.png' />
            <hr />
            <h1>Securities and Futures Commission of Hong Kong (SFC)uiiiioioi</h1>
            <p>{t('accreditation_2')} (No.<a href="https://www.sfc.hk/publicregWeb/corp/ALB893/details" target="_blank">ALB893ooo</a>）</p>
          </article>*/}
        </div>)}
        <div className='block'>
          <h2>{t('contact_information')}</h2>
          <div className='brief'>{t('customer_services')}: <b>{id === "bullion" ? "852 2600 8000" : "+852 2600 7688"}</b></div>
          <div className='brief'>{t('cs_email_address')}: {id === "bullion" ? (<b>services.bullion@clsapremium.com</b>) : (<b>services.hk@clsapremium.com</b>)}</div>
          <div className='brief'>{t('office_address')}: <b>{t('hk_address')}</b></div>
        </div>
        {/* <div className='block'>
          <h2>{t('product_service')}</h2>
          <div className='brief'>{t('forex_trading')}</div>
          <div className='brief'>{t('summary4.1_hk', i18nOptions)}</div>
          <div className='brief'>{t('summary_refer')}<Link to='/product_service/hk/forex'>{t('product_service')}</Link></div>
        </div>
        <div className='block'>
          <h2>{t('account_open')}</h2>
          <div className='brief'>{t('summary5.1', i18nOptions)}</div>
          <div className='brief'>{t('summary_refer')}<Link to='/account_open/hk'>{t('account_open')}</Link></div>
        </div>
        <div className='block'>
          <h2>{t('deposit_withdraw')}</h2>
          <div className='brief'>{t('summary_refer')}<Link to='/deposit_withdraw/hk/deposit'>{t('deposit_withdraw')}</Link></div>
        </div> */}
      </section>
    </div>
  );
}