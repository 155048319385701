import React, { useState } from 'react';

export default function NZMarginTC(){
	return (
		<div className="">
      <section className="mainSection">
        <div className="customContainer">
          <div className="bodyBox" name="pos_1">
            <div className="insideBox">
              <div className="kvbTitle">
                <h3>存款</h3>
              </div>
              <p>外匯保證金入金須知(紐西蘭)</p>
            </div>
          </div>
        </div>
      </section>
      <section className="depositSec first">
        <div className="customContainer">
          <div className="depositBox">
            <h3>1. 入金</h3>
            <h4>銀行轉帳 / 電匯</h4>
            <h5>客戶如需存款，請提供以下資料：</h5>
            <ul className="list">
              <li>客戶全名</li>
              <li>保證金帳號（若已經開戶）</li>
              <li>請在轉帳/電匯單的附註一欄註明：匯款用途為外匯保證金業務（FX Margin Trading）</li>
              <li>成功匯款後，請將入金銀行憑證電郵到客服郵箱：<a href="mailto:services@clsapremium.com">services@clsapremium.com</a>。</li>
              <li>當我們收到該筆款項後，通常在一個工作天內入帳到客戶的交易帳戶。</li>
            </ul>
            <br />
            <h5>如果客戶的匯款幣種不同於其保證金交易帳戶的預設幣種：</h5>
            <ul className="list">
              <li>客戶請先自行在銀行兌換成與保證金帳戶幣種相同的貨幣後匯出。</li>
              <li>或請先聯繫我們的客服人員，我們會説明客戶開立一個「外幣兌換帳戶」，當收到客戶的匯款後再轉換成相關貨幣入帳到客戶的保證金交易帳戶。</li>
            </ul>
            <div className="noteBox">
              <div className="alertNote">* 相關費用：銀行轉帳/電匯，中轉銀行或匯款銀行或許額外加收轉帳費，請向匯款銀行查詢。CLSA Premium New Zealand Ltd並不會收取任何費用，如實際到帳資金少於客戶存入的金額，這是由於跨行/國際匯款的中轉銀行扣除了匯款手續費所緻。</div>
              <div className="alertNote"><span>註意：</span></div>
              <ul className="noteList num">
                <li>CLSA Premium New Zealand Ltd並不接受任何形式的現金存款。所有存款必須通過客戶名下的銀行帳戶以電子方式轉帳予我們。</li>
                <li>CLSA Premium New Zealand Ltd並不接受第三方存款或匯款。所有款項必須由客戶本人銀行帳戶匯出。由非客戶本人銀行帳號匯出的款項將被退回始發帳戶。所產生之匯兌損失亦由客戶本人承擔。</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className="depositSec">
        <div className="customContainer">
          <div className="depositBox">
            <h3>2. 收款銀行資料</h3>
            <h5>收款銀行資料，請咨詢客服</h5>
            {/*<h4>幣種</h4>
            <h5 className="floatText"><span>AUD</span>
              <div className="content">以澳元為結算貨幣的外匯保證金帳戶收款銀行</div>
            </h5>
            <h4>收款銀行資料</h4>
            <h5 className="floatText"><span>收款行名稱</span>
              <div className="content">The Bank of New Zealand</div>
            </h5>
            <h5 className="floatText"><span>收款行地址</span>
              <div className="content">Bank of New Zealand, Wellington, New Zealand</div>
            </h5>
            <h5 className="floatText"><span>收款行的 Swift 代碼</span>
              <div className="content">BKNZNZ22</div>
            </h5>
            <h5 className="floatText"><span>收款人</span>
              <div className="content">CLSA Premium New Zealand Ltd – Client Fund account</div>
            </h5>
            <h5 className="floatText"><span>收款人帳號</span>
              <div className="content">646570-0002</div>
            </h5>
            <div className="noteBox">
              <div className="alertNote">* 澳元結算帳戶若以其它外幣存入款項，請與我們的客戶服務人員聯絡。</div>
            </div>*/}
          </div>
          {/*<div className="depositBox">
            <h4>幣種</h4>
            <h5 className="floatText"><span>USD</span>
              <div className="content">以美元為結算貨幣的外匯保證金帳戶收款銀行</div>
            </h5>
            <h4>收款銀行資料</h4>
            <h5 className="floatText"><span>收款行名稱</span>
              <div className="content">The Bank of New Zealand</div>
            </h5>
            <h5 className="floatText"><span>收款行地址</span>
              <div className="content">Bank of New Zealand, Wellington, New Zealand</div>
            </h5>
            <h5 className="floatText"><span>收款行的 Swift 代碼</span>
              <div className="content">BKNZNZ22</div>
            </h5>
            <h5 className="floatText"><span>收款人</span>
              <div className="content">CLSA Premium New Zealand Ltd – Client Fund account</div>
            </h5>
            <h5 className="floatText"><span>收款人帳號</span>
              <div className="content">646570-0000</div>
            </h5>
            <div className="noteBox">
              <div className="alertNote">* 美元結算帳戶若以其它外幣存入款項，請與我們的客戶服務人員聯絡。</div>
            </div>
          </div>
          <div className="depositBox">
            <h4>幣種</h4>
            <h5 className="floatText"><span>JPY</span>
              <div className="content">以日元為結算貨幣的外匯保證金帳戶收款銀行</div>
            </h5>
            <h4>收款銀行資料</h4>
            <h5 className="floatText"><span>收款行名稱</span>
              <div className="content">The Bank of New Zealand</div>
            </h5>
            <h5 className="floatText"><span>收款行地址</span>
              <div className="content">Bank of New Zealand, Wellington, New Zealand</div>
            </h5>
            <h5 className="floatText"><span>收款行的 Swift 代碼</span>
              <div className="content">BKNZNZ22</div>
            </h5>
            <h5 className="floatText"><span>收款人</span>
              <div className="content">CLSA Premium New Zealand Ltd – Client Fund account</div>
            </h5>
            <h5 className="floatText"><span>收款人帳號</span>
              <div className="content">646570-0001</div>
            </h5>
            <div className="noteBox">
              <div className="alertNote">* 日元結算帳戶若以其它外幣存入款項，請與我們的客戶服務人員聯絡。</div>
            </div>
          </div>
          <div className="depositBox">
            <h4>幣種</h4>
            <h5 className="floatText"><span>NZD</span>
              <div className="content">以紐元為結算貨幣的外匯保證金帳戶收款銀行</div>
            </h5>
            <h4>收款銀行資料</h4>
            <h5 className="floatText"><span>收款行名稱</span>
              <div className="content">The Bank of New Zealand</div>
            </h5>
            <h5 className="floatText"><span>收款行地址</span>
              <div className="content">Bank of New Zealand, Wellington, New Zealand</div>
            </h5>
            <h5 className="floatText"><span>收款行的 Swift 代碼</span>
              <div className="content">BKNZNZ22</div>
            </h5>
            <h5 className="floatText"><span>收款人</span>
              <div className="content">CLSA Premium New Zealand Ltd – Client Fund account</div>
            </h5>
            <h5 className="floatText"><span>收款人帳號</span>
              <div className="content">02-0108-0385950-000</div>
            </h5>
            <div className="noteBox">
              <div className="alertNote">* 紐元結算帳戶若以其它外幣存入款項，請與我們的客戶服務人員聯絡。</div>
            </div>
          </div>*/}
        </div>
      </section>
      <section className="mainSection inPage" name="pos_2">
        <div className="customContainer">
          <div className="bodyBox">
            <div className="insideBox">
              <div className="kvbTitle">
                <h3>取款</h3>
              </div>
              <p>外匯保證金取款須知(紐西蘭)</p>
            </div>
          </div>
          <div className="depositBox">
            <h3>1. 取款</h3>
            <h4>客戶如需取款，取款要求可通過以下方式提交：</h4>
            <h5 className="floatText"><span>電郵</span>
              <div className="content"><a href="mailto:services@clsapremium.com">services@clsapremium.com</a></div>
            </h5>
            <h4>客戶通過綁定郵箱發送取款表格</h4>
          </div>
          <div className="depositBox">
            <h3>2. 取款流程</h3>
            <ul className="list dot">
              <li>如取款申請在紐西蘭當地營業時間星期一至星期五下午 4:30 前提交(公眾假期除外)(北京時間早上 11:30 - 紐西蘭 夏令時間 / 北京時間下午 12:30 - 紐西蘭冬令時間)，取款要求將會在當天審理。否則，取款要求將會在第二個工作天 處理。</li>
              <li>取款在 MT5 交易帳戶扣除後，資金一般可在 1-3 個工作天到達客戶指定銀行帳戶。</li>
              <li>取款申請能否處理需視乎帳戶的可用保證金是否足夠;填寫取款表格資料是否正確，及收款銀行帳戶是否為客戶的綁定銀行帳戶。</li>
              <li>如客戶需要更改綁定銀行帳戶資料，請在提交取款申請時一併提供新的綁定銀行帳戶資料及該帳戶之銀行對帳單或銀行卡正面驗證件。</li>
            </ul>
          </div>
          <div className="depositBox">
            <h3>3. 取款方式</h3>
            <h4>電匯/銀行轉帳(本公司不提供現金取款)</h4>
          </div>
          <div className="depositBox">
            <h3>4. 取款手續費</h3>
            <h4>CLSA Premium New Zealand Ltd 不收取客戶取款手續費。但取款產生銀行電匯費一般為每筆 15 美元。中轉銀 行或收款銀行或許額外加收轉帳費。此費用並不由 CLSA Premium New Zealand Ltd 收取，請向收款銀行查詢。</h4>
            
          </div>
        </div>
      </section>
    </div>
	)
}