import React, { useState } from 'react';
import { useTranslation, Trans } from "react-i18next";
import { Tab, Nav, Row, Col } from 'react-bootstrap'
import { Helmet } from "react-helmet";

import Banner from '../components/Banner'



export default function GovernancePage() {

  const { t, i18n } = useTranslation();

  const pdfLangType = (i18n.language === 'cn' || i18n.language === 'tc') ? 'cn' : 'en';

  return (
    <div className='content'>
      <Helmet>
        <title>{`${t("corporate_governance")} | CLSA Premium`}</title>
      </Helmet>
      <Banner title={t('corporate_governance')} bg={'financial_reports'} />
      <section className='container governance'>
        <div className="containerRight">
          <ul className="relationList3 smargin w3-row">
            <li className="w3-col l3 m4">
              <a href={`/documents/AuditCommittee_${pdfLangType}.pdf`}>
                <ul>
                  <li>{t('audit_committee')}</li>
                  <li>{t('terms_of_ref')}</li>
                  <li><div className="cloud"></div></li>
                </ul>
              </a>
            </li>
            <li className="w3-col l3 m4">
              <a href={`/documents/RemunerationCommittee_${pdfLangType}.pdf`}>
                <ul>
                  <li>{t('remuneration_committee')}</li>
                  <li>{t('terms_of_ref')}</li>
                  <li><div className="cloud"></div></li>
                </ul>
              </a>
            </li>
            <li className="w3-col l3 m4">
              <a href={`/documents/NominationCommittee_${pdfLangType}.pdf`}>
                <ul>
                  <li>{t('nomination_committee')}</li>
                  <li>{t('terms_of_ref')}</li>
                  <li><div className="cloud"></div></li>
                </ul>
              </a>
            </li>
            <li className="w3-col l3 m4">
              <a href={`/documents/CorporateGovernanceCommittee_${pdfLangType}.pdf`}>
                <ul>
                  <li>{t('corporate_governance_committee')}</li>
                  <li>{t('terms_of_ref')}</li>
                  <li><div className="cloud"></div></li>
                </ul>
              </a>
            </li>
            <li className="w3-col l3 m4">
              <a href={`/documents/Memorandum_ArticlesofAssociation_${pdfLangType}.pdf`}>
                <ul>
                  <li>{t('moaaa')}</li>
                  <li></li>
                  <li><div className="cloud"></div></li>
                </ul>
              </a>
            </li>
            <li className="w3-col l3 m4">
              <a href={`/documents/ProceduresToProposeDirector_${pdfLangType}.pdf`}>
                <ul>
                  <li>{t('procedures_for_shareholder')}</li>
                  <li></li>
                  <li><div className="cloud"></div></li>
                </ul>
              </a>
            </li>
            <li className="w3-col l3 m4">
              <a href={`/documents/ShareholdersCommunicationPolicy_${pdfLangType}.pdf`}>
                <ul>
                  <li>{t('shareholders_policy')}</li>
                  <li></li>
                  <li><div className="cloud"></div></li>
                </ul>
              </a>
            </li>
            <li className="w3-col l3 m4">
              <a href={`/${i18n.language}/financial_reports`}>
                <ul>
                  <li>{t('financial_reports')}</li>
                  <li></li>
                  <li><div className="cloud"></div></li>
                </ul>
              </a>
            </li>
          </ul>
          <div className='clearfix'></div>
        </div>
      </section>
    </div>
  );
}