import React from "react";
import styled from "styled-components";

const StyledDiv = styled.div`
  border-style: solid;
  border-width: 2px;
  width: ${({ theme: { spacer }, width = 4 }) => `calc(${spacer} * ${width})`};
`;

const TitleSplitter = ({ color = "primary", className = "", ...props }) => (
  <StyledDiv
    className={`bg-${color} border-${color} ${className}`}
    {...props}
  />
);

export default TitleSplitter;
