/** @format */

import $ from "jquery";
import React, { useEffect, useRef, useState } from "react";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import { LinkContainer } from "react-router-bootstrap";
import styled from "styled-components";
import useHeaderHeight from "../hooks/useHeaderHeight";
import useResponsive from "../hooks/useResponsive";
import useSubsidiary from "../hooks/useSubsidiary";
import useTranslate from "../hooks/useTranslate";
import HeaderNav from "./HeaderNav";
import HeaderUserBtns from "./HeaderUserBtns";
import LanguageToggle from "./LanguageToggle";
import Logo from "./Logo";

const StyledNavbar = styled(Navbar)`
  min-height: ${({ minheight }) => `${minheight}px`};
`;

const Header = ({ bg, fluid = false, ...props }) => {
  const [headerHeight, setHeaderHeight] = useHeaderHeight();
  const { isDesktopOrLaptop } = useResponsive();
  const { subsidiary } = useSubsidiary();
  const { language } = useTranslate();
  const [atTop, setAtTop] = useState(window.scrollY === 0);
  const [background, setBackground] = useState(bg || "transparent");
  const [expanded, setExpanded] = useState(false);
  const navBarRef = useRef();

  useEffect(() => {
    setHeaderHeight($(navBarRef.current).innerHeight());
  }, [setHeaderHeight]);

  useEffect(() => {
    const handleAtTop = () => {
      setAtTop(window.scrollY === 0);
    };
    if (!bg) {
      document.addEventListener("scroll", handleAtTop);
    }
    return () => {
      document.removeEventListener("scroll", handleAtTop);
    };
  }, [bg]);

  useEffect(() => {
    setBackground(bg || (atTop && !expanded ? "transparent" : "dark"));
  }, [atTop, bg, expanded]);

  return (
    <header className="header" {...props}>
      <StyledNavbar
        bg={background}
        className={`${
          background === "dark" ? "" : "border-bottom border-secondary"
        } vw-100`}
        expand={isDesktopOrLaptop}
        fixed="top"
        minheight={headerHeight}
        ref={navBarRef}
        variant="dark"
        onToggle={(expanded) => {
          setExpanded(expanded);
        }}
      >
        <Container fluid={fluid}>
          <LinkContainer to={`/${language}/${subsidiary}`}>
            <Navbar.Brand>
              <Logo />
            </Navbar.Brand>
          </LinkContainer>
          <Navbar.Toggle />
          <Navbar.Collapse className="justify-content-end text-center">
            <HeaderNav className={isDesktopOrLaptop ? "mr-4" : "mb-5"} />
            <LanguageToggle />
            <HeaderUserBtns />
          </Navbar.Collapse>
        </Container>
      </StyledNavbar>
    </header>
  );
};

export default Header;
