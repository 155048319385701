import _map from "lodash/map";
import _size from "lodash/size";
import React from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import useResponsive from "../hooks/useResponsive";
import useTranslate from "../hooks/useTranslate";
import ConfirmModal from "./ConfirmModal";
import DetailList from "./DetailList";
import Helmet from "./Helmet";
import Paragraph from "./Paragraph";
import ProductGuide from "./ProductGuide";
import ProductSidebar from "./ProductSidebar";
import Table from "./Table";
import Title from "./Title";
import TitleSplitter from "./TitleSplitter";

const KetBenefits = ({ list = [] }) => {
  const len = _size(list);
  return (
    <>
      {_map(list, ({ label }, key) => (
        <Col
          key={key}
          md={{ span: 4, order: key + 1 }}
          xs={{ span: 12, order: key * 2 + 1 }}
        >
          <Title as="h5" className="text-success" font={{ family: "text" }}>
            {label}
          </Title>
        </Col>
      ))}
      {_map(list, ({ value }, key) => (
        <Col
          key={key}
          md={{ span: 4, order: key + 1 + len }}
          xs={{ span: 12, order: (key + 1) * 2 }}
        >
          <Paragraph font={{ size: "sm" }}>{value}</Paragraph>
        </Col>
      ))}
    </>
  );
};

const CreditSpreadCfds = ({ className = "", ...props }) => {
  const { isNotMobile } = useResponsive();
  const { config } = useTranslate({ configKey: "credit-spread-cfds" });

  const {
    confirm: confirmProps,
    helmet: helmetProps,
    intro: introConfig,
    "key-benefits": benefitConfig,
    "maturity-date-distribution-rate": guideProps,
    product: productConfig,
    sidebar: sidebarProps,
    "trade-time": tradeTimeConfig,
  } = config || {};

  return config ? (
    <Container className={`credit-spread-cfds ${className}`} {...props}>
      <Helmet {...helmetProps} />
      <Row className="py-5">
        <Col lg={9}>
          {introConfig && (
            <Row className="intro mb-5">
              <Col>
                <Title>{introConfig.title}</Title>
                <TitleSplitter className="mb-4" color="success" />
                <Paragraph>{introConfig.brief}</Paragraph>
              </Col>
            </Row>
          )}
          {benefitConfig && (
            <Row className="key-benefits mb-5" id="credit-spread-cfds-benefit">
              <Col className="mb-4" xs={12}>
                <Title as="h2">{benefitConfig.title}</Title>
              </Col>
              <KetBenefits {...benefitConfig} />
            </Row>
          )}
          {productConfig && (
            <Row className="product mb-5" id="credit-spread-cfds-product">
              <Col>
                <Title as="h2" className="mb-3">
                  {productConfig.title}
                </Title>
                <DetailList
                  showBottomGutter
                  className={`shadow ${isNotMobile ? "px-4" : "px-2"}`}
                  data={productConfig.list}
                  valueProps={{
                    className: "text-center text-nowrap",
                    textProps: {
                      font: {
                        size: "sm",
                      },
                    },
                  }}
                />
              </Col>
            </Row>
          )}
          {tradeTimeConfig && (
            <Row className="time mb-5" id="credit-spread-cfds-time">
              <Col>
                <Title as="h2">{tradeTimeConfig.title}</Title>
                <div className="mb-3 shadow">
                  <Table
                    bodyCellProps={{
                      className: "text-center",
                    }}
                    gutter={{
                      bottom: 3,
                      left: 5,
                      right: 5,
                    }}
                    headCellProps={{
                      className:
                        "bg-primary border-0 font-weight-normal text-center text-white",
                    }}
                    {...tradeTimeConfig.table}
                  />
                </div>
              </Col>
            </Row>
          )}
          <Row
            className="maturity-date-distribution-rate mb-5"
            id="credit-spread-cfds-maturity-date-distribution-rate"
          >
            <Col>
              <ProductGuide {...guideProps} />
            </Col>
          </Row>
        </Col>
        <Col lg={3}>
          <ProductSidebar {...sidebarProps} />
        </Col>
      </Row>
      <ConfirmModal
        show
        bodyProps={{ className: "px-5" }}
        footerProps={{ className: "border-0 pb-5 justify-content-center" }}
        headerProps={{ className: "border-0" }}
        size="lg"
        {...confirmProps}
      />
    </Container>
  ) : null;
};

export default CreditSpreadCfds;
