import React, { useState } from "react";

/**
 * @param props
 */
export default function HKCN(props) {
  const name =
    props.sub === "bullion"
      ? "保证金交易（Margin Trading）"
      : "外汇保证金业务（FX Margin Trading）";
  const email =
    props.sub === "bullion"
      ? "services.bullion@clsapremium.com"
      : "services.hk@clsapremium.com";

  return (
    <div className="">
      {props.id === "deposit" && (
        <>
          <section className="mainSection">
            <div className="customContainer">
              <div className="bodyBox" name="pos_1">
                <div className="insideBox">
                  <div className="kvbTitle">
                    <h3>存款</h3>
                  </div>
                  <p>香港入金须知</p>
                </div>
              </div>
            </div>
          </section>
          <section className="depositSec first">
            <div className="customContainer">
              <div className="depositBox w-75">
                <h3>1. 存款</h3>
                <h4>银行转帐 / 电汇</h4>
                <h5>客户如需存款，请提供以下资料：</h5>
                <ul className="list">
                  <li>客户全名</li>
                  <li>保证金帐号（若已经开户）</li>
                  <li>请在转帐/电汇单的附注一栏注明：汇款用途为{name}</li>
                  <li>
                    成功汇款后，请将入金银行凭证电邮到客服邮箱：
                    <a href={`mailto:${email}`}>{email}</a>。
                  </li>
                  <li>
                    当我们收到该笔款项后，通常在一个工作天内入帐到客户的交易帐户。
                  </li>
                  {/* <li>美元结算帐户若以其它外币存入款项，请与我们的客服人员联络。</li> */}
                </ul>
                <div className="noteBox">
                  <div className="alertNote">
                    *
                    相关费用：银行转帐/电汇，中转银行或汇款银行或许额外加收转帐费，请向汇款银行查询。CLSA
                    Premium
                    并不会收取任何费用，如实际到帐资金少于客户存入的金额，这是由于跨行/国际汇款的中转银行扣除了汇款手续费所致。
                  </div>
                  <div className="alertNote">
                    <span>请注意：</span>
                  </div>
                  <ul className="noteList num">
                    <li>
                      CLSA Premium
                      并不接受任何形式的现金存款。所有存款必须通过客户名下的银行帐户以电子方式转帐予我们。
                    </li>
                    <li>
                      CLSA Premium
                      并不接受第三方存款或汇款。所有款项必须由客户本人银行帐户汇出。由非客户本人银行帐号汇出的款项将被退回始发帐户。所产生之汇兑损失亦由客户本人承担。
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </section>
          <section className="depositSec">
            <div className="customContainer">
              <div className="depositBox w-75">
                <h3>2. 收款银行资料</h3>
                {/* <h5>
                  收款银行资料，请咨询客户服务代表{" "}
                  <a href={`mailto:${email}`}>{email}</a>。
                </h5> */}
                <table>
                  {/* <tr>
                    <td>受款人</td>
                    <td />
                    <td>CLSA Premium Bullion Limited</td>
                  </tr> */}
                  <tr>
                    <td>帐户名称</td>
                    <td />
                    <td>CLSA PREMIUM BULLION LIMITED - TRUST</td>
                  </tr>
                  <tr>
                    <td className="border-bottom-0">帐户号码</td>
                    <td className="border-bottom-0">港元 HKD</td>
                    <td className="border-bottom-0">735-1-37655-7-00</td>
                  </tr>
                  <tr>
                    <td />
                    <td>美元 USD</td>
                    <td>735-1-37655-7-01</td>
                  </tr>
                  <tr>
                    <td>银行名称</td>
                    <td />
                    <td>中信银行国际有限公司</td>
                  </tr>
                  <tr>
                    <td>银行地址</td>
                    <td />
                    <td>香港德辅道中61-65号</td>
                  </tr>
                  <tr>
                    <td>银行编号</td>
                    <td />
                    <td>018</td>
                  </tr>
                  <tr>
                    <td>国际代码</td>
                    <td>SWIFT Code</td>
                    <td>KWHKHKHH</td>
                  </tr>
                  <tr>
                    <td>受款人提示讯息</td>
                    <td />
                    <td>请填写您的英文全名及交易帐户号码。</td>
                  </tr>
                </table>
                {/* <h4>币种</h4>
            <h5 className="floatText"><span>USD</span>
              <div className="content">以美元为结算货币的外汇保证金帐户收款银行</div>
            </h5>
            <h4>收款银行资料</h4>
            <h5 className="floatText"><span>收款行名称</span>
              <div className="content">Bank of China (Hong Kong)</div>
            </h5>
            <h5 className="floatText"><span>收款行地址</span>
              <div className="content">Bank of China Tower, 1 Garden Road, Central, Hong Kong</div>
            </h5>
            <h5 className="floatText"><span>收款行的 Swift 代码</span>
              <div className="content">BKCHHKHHXXX</div>
            </h5>
            <h5 className="floatText"><span>收款人</span>
              <div className="content">CLSA Premium International (HK) Limited - Clients’ A/C</div>
            </h5>
            <h5 className="floatText"><span>收款人帐号</span>
              <div className="content">012-875-9-263883-9</div>
            </h5>
            <div className="noteBox">
              <div className="alertNote">* 美元结算帐户若以其它外币存入款项，请与我们的客户服务人员联络。</div>
            </div> */}
              </div>
            </div>
          </section>
        </>
      )}
      {props.id === "withdrawal" && (
        <section className="mainSection inPage" name="pos_2">
          <div className="customContainer">
            <div className="bodyBox">
              <div className="insideBox">
                <div className="kvbTitle">
                  <h3>取款</h3>
                </div>
                <p>香港取款须知</p>
              </div>
            </div>
            <div className="depositBox">
              <h3>1. 取款</h3>
              <h4>客户如需取款，可通过以下方式提交取款申请：</h4>
              <h5 className="floatText">
                <span>电邮</span>
                <div className="content">
                  <a href={`mailto:${email}`}>{email}</a>
                </div>
              </h5>
              {/* <h4>通过电邮提交提款申请表，客户必须在提款申请表亲笔签名。</h4> */}
            </div>
            <div className="depositBox">
              <h3>2. 取款流程</h3>
              <ul className="list dot">
                <li>
                  提款申请如在香港时间星期一至五中午12时前提交（公众假期除外）将会在当天处理，否则将会在下一个工作天处理。
                </li>
                <li>
                  如取款在MT5交易帐户扣除后，资金一般可在当天或下一个工作天到帐客户指定的本地银行户口
                  。
                </li>
                <li>
                  取款申请能否处理需视乎账户的可用保证金是否足够／客户银行户口是否正确。
                </li>
              </ul>
            </div>
            <div className="depositBox">
              <h3>3. 取款方式</h3>
              <h4>转账或汇款</h4>
            </div>
            <div className="depositBox">
              <h3>4. 取款手续费</h3>
              <h4>CLSA Premium不收取客户取款手续费。</h4>
              {/* <table>
              <thead>
                <tr>
                  <th>取款方式</th>
                  <th>帐户货币</th>
                  <th>取款手续费</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>转帐或汇款</td>
                  <td>美元</td>
                  <td><span className="red">CLSA Premium 不收取客户取款手续费</span>，银行取款手续费以收款银行所在地为准, 请与我们的客户服务人员联络了解详情。</td>
                </tr>
                <tr>
                  <td>支票</td>
                  <td>美元</td>
                  <td><span className="red">$0</span>（请先与我们的客户服务人员联络及客户必需当天亲身领取支票）</td>
                </tr>
              </tbody>
            </table> */}
            </div>
          </div>
        </section>
      )}
    </div>
  );
}
