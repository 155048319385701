import React, { useState } from 'react';
import { useTranslation, Trans } from "react-i18next";


export default function Commodities(props) {
  const { t, i18n } = useTranslation();
  const subsidiary = props.subsidiary
  const marginRequired = subsidiary === 'au' ? '5%' : '1%'
  const maxTrade = subsidiary === 'au' ? '20' : '10'

  return (
    <div className="commodities">
      {subsidiary !== 'au' && <div className='brief'>{t('commodities_brief')}</div>}
      {subsidiary !== 'au' ? (<div className='block'>
        <div className='title'><h2>{t('commodities_spec')}</h2></div>
        <table className='spec mt-5'>
          <tbody>
          <tr><td>{t('symbol')}</td><td>{'USCRUDE_SPOT'}</td></tr>
          <tr><td>{t('stand_contract_size')}</td><td>{t('stand_contract_size_value_oil')}</td></tr>
          <tr><td>{t('margin_required')}</td><td>{marginRequired}</td></tr>
          <tr><td>{t('target_spread')}</td><td>{'4'}</td></tr>
          <tr><td>{t('decimal_place')}</td><td>{'2'}</td></tr>
          <tr><td>{t('min_fluctuation')}</td><td>{'0.01'}</td></tr>
          <tr><td>{t('min_fluctuation_value')}</td><td>{`US$ 10/${t('lot')}`}</td></tr>
          <tr><td>{t('commission')}</td><td>{'NIL'}</td></tr>
          <tr><td>{t('min_tradable_lot')}</td><td>{t('min_tradable_lot_value')}</td></tr>
          <tr><td>{t('min_incremental_lot')}</td><td>{t('min_incremental_lot_value')}</td></tr>
          <tr><td>{t('max_trade')}</td><td>{`${maxTrade} ${t('lot')}`}</td></tr>
          <tr><td>{t('trading_hour')}</td><td>{t('trading_hour_cfd')}</td></tr>
          <tr><td>{t('contract_expiry')}</td><td>{t('contract_expiry_value')}</td></tr>
          <tr><td>{t('settlement')}</td><td>{t('settlement_value')}</td></tr>
          <tr><td>{t('order_duration')}</td><td>{t('order_duration_value')}</td></tr>
          </tbody>
        </table>
      </div>) : (
        <div className='block'>
          <div className='title'><h2>{t('commodities_spec_2')}</h2></div>
          <table className='spec mt-5'>
            <tbody>
              <tr><td>{t('symbol')}</td><td>{'USCRUDE_SPOT'}</td></tr>
              <tr><td>{t('stand_contract_size')}</td><td>{t('stand_contract_size_value_oil')}</td></tr>
              <tr><td>{t('margin_required')}</td><td>{'10%(10:1)'}</td></tr>
              <tr><td>{t('decimal_place')}</td><td>{'3'}</td></tr>
              <tr><td>{t('min_fluctuation')}</td><td>{'0.001'}</td></tr>
              <tr><td>{t('min_fluctuation_value')}</td><td>{`US$ 1/${t('lot')}`}</td></tr>
              <tr><td>{t('commission')}</td><td>{'NIL'}</td></tr>
              <tr><td>{t('min_tradable_lot')}</td><td>{t('min_tradable_lot_value')}</td></tr>
              <tr><td>{t('min_incremental_lot')}</td><td>{t('min_incremental_lot_value')}</td></tr>
              <tr><td>{t('max_trade')}</td><td>{`${maxTrade} ${t('lot')}`}</td></tr>
              <tr><td>{t('trading_hour')}</td><td>{t('trading_hour_cfd_au')}</td></tr>
              <tr><td>{t('trading_breaks')}</td><td>{t('trading_breaks_value_cfd_au')}</td></tr>
            </tbody>
          </table>
        </div>
      )}
    </div>
  )
}
