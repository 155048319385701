import React from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import useTranslate from "../hooks/useTranslate";
import Helmet from "./Helmet";
import Paragraph from "./Paragraph";
import ProductSidebar from "./ProductSidebar";
import Table from "./Table";
import TabTables from "./TabTables";
import Title from "./Title";
import TitleSplitter from "./TitleSplitter";

const ShareCfds = ({ className = "", ...props }) => {
  const { config } = useTranslate({ configKey: "share-cfds" });

  const {
    commission: commissionProps,
    helmet: helmetProps,
    intro: introConfig,
    product: productConfig,
    sidebar: sidebarProps,
    "trade-time": tradeTimeConfig,
  } = config || {};

  return config ? (
    <Container className={`share-cfds ${className}`} {...props}>
      <Helmet {...helmetProps} />
      <Row className="py-5">
        <Col lg={9}>
          {introConfig && (
            <Row className="intro mb-5">
              <Col>
                <Title>{introConfig.title}</Title>
                <TitleSplitter className="mb-4" color="success" />
                <Paragraph>{introConfig.brief}</Paragraph>
              </Col>
            </Row>
          )}
          {productConfig && (
            <Row className="product mb-5" id="cfds-product">
              <Col>
                <Title as="h2">{productConfig.title}</Title>
                <TabTables
                  tableProps={{
                    bodyCellProps: {
                      className: "text-center",
                    },
                    gutter: {
                      bottom: 3,
                      left: 5,
                      right: 5,
                    },
                    headCellProps: {
                      className:
                        "bg-primary border-0 font-weight-normal text-center text-white",
                    },
                  }}
                  tables={productConfig.tables}
                />
              </Col>
            </Row>
          )}
          {tradeTimeConfig && (
            <Row className="time mb-5" id="cfds-time">
              <Col>
                <Title as="h2">{tradeTimeConfig.title}</Title>
                <div className="mb-3 shadow">
                  <Table
                    bodyCellProps={{
                      className: "text-center",
                    }}
                    gutter={{
                      bottom: 3,
                      left: 5,
                      right: 5,
                    }}
                    headCellProps={{
                      className:
                        "bg-primary border-0 font-weight-normal text-center text-white",
                    }}
                    {...tradeTimeConfig.table}
                  />
                </div>
              </Col>
            </Row>
          )}
          {commissionProps && (
            <Row className="commission mb-5" id="cfds-commission">
              <Col>
                <Title as="h2">{commissionProps.title}</Title>
                <div className="mb-3 shadow">
                  <Table
                    bodyCellProps={{
                      className: "text-center",
                    }}
                    gutter={{
                      bottom: 3,
                      left: 5,
                      right: 5,
                    }}
                    headCellProps={{
                      className:
                        "bg-primary border-0 font-weight-normal text-center text-white",
                    }}
                    {...commissionProps.table}
                  />
                </div>
              </Col>
            </Row>
          )}
        </Col>
        <Col lg={3}>
          <ProductSidebar {...sidebarProps} />
        </Col>
      </Row>
    </Container>
  ) : null;
};

export default ShareCfds;
