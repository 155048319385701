import React, { useState } from 'react';
import { useTranslation, Trans } from "react-i18next";
import { Tabs, Tab } from 'react-bootstrap'

export default function AUDeposit(props){

  const [tab, setTab] = useState(props.id);
  const { t, i18n } = useTranslation();
  const handleTabSelect = (eventKey)=>{
    setTab(eventKey)
  }

  const i18nOptions = {
    keySeparator: false
  };

	return(
		<div className="">
      <Tabs defaultActiveKey={tab}
        onSelect={handleTabSelect}>
        <Tab eventKey="deposit" title={t('deposit')}>
          <div className="deposit">
            <h3>{t('deposit')}</h3>
            <h5>{t('deposit-1')}</h5>
            <p>{t('deposit-2')}</p>
            <ul className="list">
              <li>{t('deposit-3.1', i18nOptions)}</li>
              <li>{t('deposit-3.2', i18nOptions)}</li>
              <li>{t('deposit-3.3', i18nOptions)}</li>
              <li>{t('deposit-3.4', i18nOptions)} <a href="mailto:services@clsapremium.com">services@clsapremium.com</a></li>
              <li>{t('deposit-3.5', i18nOptions)}</li>
              <li>{t('deposit-3.6', i18nOptions)}</li>
            </ul>
            <p>{t('deposit-4')}</p>
            <ul className='note'>
              <li>{t('deposit-5.1', i18nOptions)}</li>
              <li>{t('deposit-5.2', i18nOptions)}</li>
            </ul>
            <h3>{t('deposit-6')}</h3>
            <div>{t('deposit-7')}</div>
          </div>
        </Tab>
        <Tab eventKey="withdrawal" title={t('withdrawal')}>
          <section className='container withdrawal'>
            <h3>{t('withdrawal-1')}</h3>
            <div>{t('withdrawal-2')} <a href="mailto:services@clsapremium.com">services@clsapremium.com</a></div>
            <h3>{t('withdrawal-3')}</h3>
            <p>{t('deposit-2')}</p>
            <ul className="list dot">
              <li>{t('withdrawal-4.1', i18nOptions)}</li>
              <li>{t('withdrawal-4.2', i18nOptions)}</li>
              <li>{t('withdrawal-4.3', i18nOptions)}</li>
              <li>{t('withdrawal-4.4', i18nOptions)}</li>
            </ul>
            <h3>{t('withdrawal-5')}</h3>
            <div>{t('withdrawal-6')}</div>
            <h3>{t('withdrawal-7')}</h3>
            <div>{t('withdrawal-8')}</div>
          </section>
        </Tab>
      </Tabs>
      
    </div>
	)
}