import React, { useState } from 'react';

import { useTranslation, Trans } from "react-i18next";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarkerAlt, faPhoneAlt,faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { Helmet } from "react-helmet";

import Banner from '../components/Banner'
export default function ContactPage() {
  // Declare a new state variable, which we'll call "count"
  const [lang, setLang] = useState('English');
  const { t, i18n } = useTranslation();

  return (
    <div className='content'>
      <Helmet>
        <title>{`${t("contact_us")} | CLSA Premium`}</title>
      </Helmet>
      <Banner title={t('contact_us')} bg={'about_us'} brief={t('float_box_contact')}/>
      <section className='container contact'>
        <div>
          <h3>{t('contact_information')}</h3>
          <div className='block'>

            <h4>{t('clsa_premium_limited')}</h4>
            <div><FontAwesomeIcon icon={faPhoneAlt} />{`${t('cs_tel')}: +852 2600 7670`}</div>
            <div><FontAwesomeIcon icon={faEnvelope} />{`${t('cs_email_address')}: enquiry@clsapremium.com`}</div>
            <div><FontAwesomeIcon icon={faMapMarkerAlt} />{`${t('cs_address')}: ${t('hk_address')}`}</div>
          </div>
        </div>
      </section>
    </div>
  );
}