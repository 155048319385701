import React, { useState } from 'react';
import { useTranslation, Trans } from "react-i18next";
import { Tab, Nav, Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { Helmet } from "react-helmet";

import Banner from '../components/Banner'
import ApiPopupBox from '../components/ApiPopupBox'
import WarningPopupBox from '../components/WarningPopupBox'

export default function SummaryNZPage({match}) {
  // Declare a new state variable, which we'll call "count"
  const [lang, setLang] = useState('English');
  const { t, i18n } = useTranslation();

  const i18nOptions = {
    keySeparator: false
  };

  return (
    <div className='content'>
      <Helmet>
        <title>{t("title_sub", { param: t("new_zealand") })}</title>
      </Helmet>
      <Banner title={`CLSA Premium ${t('new_zealand')}`} bg={'location'} subsidiary={match.url.split('/')[1]}/>
      <ApiPopupBox subsidiary={match.url.split('/')[1]} />
      <WarningPopupBox body={[t("warning_body_1_nz"), t("warning_body_2_nz"), t("warning_body_3_nz")]} buttonText="OK" title={t("warning_title_nz")} />
      <section className='container location'>
        <div className='block'>
          <h2>{t('company_intro')}</h2>
          <div className='brief'>{t('summary1.1_nz', i18nOptions)}</div>
        </div>
        {/*<div className='block'>
          <h2>{t('accreditation')}</h2>
          <article><img src="/about-6_4.png" /><hr /><h1>Financial Service Provider Register (FSPR)</h1><p>Registered Financial Service Provider (No.<a href="https://app.companiesoffice.govt.nz/fsp/app/ui/fsp/version/searchSummaryCompanyFSP/FSP1762.do" target="_blank">FSP1762</a>）</p></article>
        </div>*/}
        <div className='block'>
          <h2>{t('contact_information')}</h2>

          <div className='brief'>{t('cs_email_address')}: services@clsapremium.com</div>

        </div>
        {/*<div className='block'>
          <h2>{t('product_service')}</h2>
          <div className='brief'>{t('forex_trading')}</div>
          <div className='brief'>{t('summary4.1_au')}</div>
          <div className='brief'>{t('summary_refer')}<Link to='/product_service/nz'>{t('product_service')}</Link></div>
        </div>
        <div className='block'>
          <h2>{t('account_open')}</h2>
          <div className='brief'>{t('summary5.1')}</div>
          <div className='brief'>{t('summary_refer')}<Link to='/account_open/nz'>{t('account_open')}</Link></div>
        </div>
        <div className='block'>
          <h2>{t('deposit_withdraw')}</h2>
          <div className='brief'>{t('summary_refer')}<Link to='/deposit_withdraw/nz'>{t('deposit_withdraw')}</Link></div>
        </div>*/}
      </section>
    </div>
  );
}