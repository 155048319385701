import _map from "lodash/map";
import React from "react";
import ListGroup from "react-bootstrap/ListGroup";
import styled from "styled-components";
import useResponsive from "../hooks/useResponsive";
import useTranslate from "../hooks/useTranslate";
import FooterLink from "./FooterLink";
import Text from "./Text";

const StyledListGroupItem = styled(ListGroup.Item)`
  ::before {
    border-left: 1px solid ${({ theme: { secondary } }) => secondary};
    content: "";
  }
`;

const ListGroupItemsVariantLayout = ({
  className = "",
  copyright,
  documents,
}) => {
  const { isDesktopOrLaptop } = useResponsive();
  return (
    <>
      {_map(documents, ({ label, value }, key) => (
        <ListGroup.Item
          className={`bg-transparent border-0 rounded-0 pl-0 text-center ${className}`}
          key={key}
        >
          <FooterLink target="_blank" to={value}>
            <Text className="text-secondary" font={{ size: "sm" }}>
              {label}
            </Text>
          </FooterLink>
        </ListGroup.Item>
      ))}
      <ListGroup.Item
        className={`bg-transparent border-0 ${
          isDesktopOrLaptop ? "ml-auto" : ""
        } pl-0 rounded-0 text-center text-white ${className}`}
      >
        <Text className="text-secondary" font={{ size: "sm" }}>
          {copyright}
        </Text>
      </ListGroup.Item>
    </>
  );
};

const ListGroupItemsDefaultLayout = ({
  className = "",
  copyright,
  documents,
}) => (
  <>
    <ListGroup.Item
      className={`bg-transparent border-0 px-0 rounded-0 ${className}`}
    >
      <Text
        className="text-center text-secondary text-white "
        font={{ size: "sm" }}
      >
        {copyright}
      </Text>
    </ListGroup.Item>
    {_map(documents, ({ label, value }, key) => (
      <StyledListGroupItem
        className={`bg-transparent border-0 pr-0 rounded-0 ${className}`}
        key={key}
      >
        <FooterLink scrollToTop as="span" className="pl-3" to={value}>
          <Text className="text-secondary" font={{ size: "sm" }}>
            {label}
          </Text>
        </FooterLink>
      </StyledListGroupItem>
    ))}
  </>
);

const Copyright = ({
  className = "",
  listGroupItemsProps = {},
  variant = false,
  ...props
}) => {
  const { isDesktopOrLaptop, isTabletOrMobile } = useResponsive();
  const { config } = useTranslate({ configKey: "copyright" });
  const { variant: variantConfig = variant, ...listGroupItemsConfig } =
    config || {};

  return config ? (
    <ListGroup
      className={`copyright justify-content-center ${className}`}
      horizontal={isDesktopOrLaptop}
      variant={isTabletOrMobile && "flush"}
      {...props}
    >
      {isDesktopOrLaptop && !variantConfig ? (
        <ListGroupItemsDefaultLayout
          {...listGroupItemsConfig}
          {...listGroupItemsProps}
        />
      ) : (
        <ListGroupItemsVariantLayout
          {...listGroupItemsConfig}
          {...listGroupItemsProps}
        />
      )}
    </ListGroup>
  ) : null;
};

export default Copyright;
