/** @format */

import _map from "lodash/map";
import _replace from "lodash/replace";
import React from "react";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import useTranslate from "../hooks/useTranslate";

const StyledButton = styled(Button)`
  font-family: ${({ theme: { "font-family-button": fontFamily } }) =>
    fontFamily};
  &:hover,
  &:active,
  &:focus {
    box-shadow: none;
    text-decoration: none;
  }
  &:not(:last-child) {
    padding-right: 0;
    ::after {
      border-right: 2px solid white;
      content: "";
      padding-right: 0.5rem;
    }
  }
`;

const LanguageToggle = ({ className = "", ...props }) => {
  const { pathname } = useLocation();
  const { push } = useHistory();
  const { config, language, i18n } = useTranslate({
    configKey: "language-toggle",
  });

  return (
    <ButtonGroup
      toggle
      className={`language-toggle ${className}`}
      size="sm"
      {...props}
    >
      {_map(config, ({ label, value }, key) => (
        <StyledButton
          className={`${value}-language text-white`}
          key={key}
          variant="link"
          onClick={() => {
            i18n.changeLanguage(value);
            push(_replace(pathname, `/${language}/`, `/${value}/`));
          }}
        >
          {label}
        </StyledButton>
      ))}
    </ButtonGroup>
  );
};

export default LanguageToggle;
