import React from "react";
import { ThemeProvider } from "styled-components";
import variables from "../variables.scss";

const theme = { ...variables };

const Theme = ({ children = <></> }) => (
  <ThemeProvider theme={theme}>{children}</ThemeProvider>
);

export default Theme;
