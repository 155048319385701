import React, { useState } from 'react';
import { useTranslation, Trans } from "react-i18next";


export default function PDSPage({match}) {
  
  const { t, i18n } = useTranslation();

  return (
    <div className='content'>
      
      <section className='container pds'>
        {match.params.id === 'au' && 
          <div></div>
        }
      </section>
    </div>
  );
}