import React, { useState } from 'react';

import { useTranslation, Trans } from "react-i18next";

export default function Banner(props) {

  const { t, i18n } = useTranslation();
  const { title, subsidiary, bg, showBrief } = props
  const brief = subsidiary ? 'float_box_sub' : 'float_box_universal'
  const param = t('sub_' + subsidiary)
  return (
    
    <section className='banner-template'>
      <div className={`banner ${bg}`}>
        <div className='floatBox'>
          <div className='title'><h2>{title}</h2></div>
          {showBrief && 
            <div className='brief'>
              <div>{t(brief, {param1: subsidiary ? param : '', 
                param2: subsidiary === 'hk' ? t('float_box_param2_hk') : t('float_box_param2_au')})}
              </div>
              <div>{t('float_box_universal2')}</div>
              
            </div>
          }
        </div>
      </div>
    </section>
    
  );
}