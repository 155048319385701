import React from "react";
import useTranslate from "../hooks/useTranslate";
import Sidebar from "./Sidebar";

const ProductSidebar = ({ ...props }) => {
  const { config } = useTranslate({ configKey: "product-sidebar" });
  return <Sidebar {...{ ...config, ...props }} />;
};

export default ProductSidebar;
