import React, { useState } from 'react';

import { useTranslation, Trans } from "react-i18next";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarkerAlt, faPhoneAlt,faEnvelope } from '@fortawesome/free-solid-svg-icons'

import { Link } from 'react-router-dom'
import Banner from '../components/Banner'
export default function IntermediatePage({match}) {
  // Declare a new state variable, which we'll call "count"
  const [lang, setLang] = useState('English');
  const { t, i18n } = useTranslation();

  return (
    <div className='content'>
      
      <section className='container intermediate'>
        <h2>{t("welcome", { context: match.params.id })}</h2>
        <a href={`/${match.params.id}`}><h1>{t('enter')}</h1></a>
      </section>
    </div>
  );
}