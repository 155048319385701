import React, { useState } from "react";

/**
 * @param props
 */
export default function HKTC(props) {
  const name =
    props.sub === "bullion"
      ? "保證金交易（Margin Trading）"
      : "外匯保證金業務（FX Margin Trading）";
  const email =
    props.sub === "bullion"
      ? "services.bullion@clsapremium.com"
      : "services.hk@clsapremium.com";

  return (
    <div className="">
      {props.id === "deposit" && (
        <>
          <section className="mainSection">
            <div className="customContainer">
              <div className="bodyBox" name="pos_1">
                <div className="insideBox">
                  <div className="kvbTitle">
                    <h3>存款</h3>
                  </div>
                  <p>香港入金須知</p>
                </div>
              </div>
            </div>
          </section>
          <section className="depositSec first">
            <div className="customContainer">
              <div className="depositBox w-75">
                <h3>1. 存款</h3>
                <h4>銀行轉帳 / 電匯</h4>
                <h5>客戶如需存款，請提供以下資料：</h5>
                <ul className="list">
                  <li>客戶全名</li>
                  <li>交易帳號（若已經開戶）</li>
                  <li>請在轉帳/電匯單的附註一欄註明：匯款用途為{name}</li>
                  <li>
                    成功匯款後，請將入金銀行憑證電郵到客服郵箱：
                    <a href={`mailto:${email}`}>{email}</a>。
                  </li>
                  <li>
                    當我們收到該筆款項後，通常在一個工作天內入帳到客戶的交易帳戶。
                  </li>
                  {/* <li>美元結算帳戶若以其它外幣存入款項，請與我們的客服人員聯絡。</li> */}
                </ul>
                <div className="noteBox">
                  <div className="alertNote">
                    *
                    相關費用：銀行轉帳/電匯，中轉銀行或匯款銀行或許額外加收轉帳費，請向匯款銀行查詢。CLSA
                    Premium
                    並不會收取任何費用，如實際到帳資金少於客戶存入的金額，這是由於跨行/國際匯款的中轉銀行扣除了匯款手續費所緻。
                  </div>
                  <div className="alertNote">
                    <span>請注意：</span>
                  </div>
                  <ul className="noteList num">
                    <li>
                      CLSA Premium
                      並不接受任何形式的現金存款。所有存款必須通過客戶名下的銀行帳戶以電子方式轉帳予我們。
                    </li>
                    <li>
                      CLSA Premium
                      並不接受第三方存款或匯款。所有款項必須由客戶本人銀行帳戶匯出。由非客戶本人銀行帳號匯出的款項將被退回始發帳戶。所產生之匯兌損失亦由客戶本人承擔。
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </section>
          <section className="depositSec">
            <div className="customContainer">
              <div className="depositBox w-75">
                <h3>2. 收款銀行資料</h3>
                {/* <h5>
                  收款銀行資料，請咨詢客戶服務代表{" "}
                  <a href={`mailto:${email}`}>{email}</a>。
                </h5> */}
                <table>
                  {/* <tr>
                    <td>受款人</td>
                    <td />
                    <td>CLSA Premium Bullion Limited</td>
                  </tr> */}
                  <tr>
                    <td>帳戶名稱</td>
                    <td />
                    <td>CLSA PREMIUM BULLION LIMITED - TRUST</td>
                  </tr>
                  <tr>
                    <td className="border-bottom-0">帳戶號碼</td>
                    <td className="border-bottom-0">港元 HKD</td>
                    <td className="border-bottom-0">735-1-37655-7-00</td>
                  </tr>
                  <tr>
                    <td />
                    <td>美元 USD</td>
                    <td>735-1-37655-7-01</td>
                  </tr>
                  <tr>
                    <td>銀行名稱</td>
                    <td />
                    <td>中信銀行國際有限公司</td>
                  </tr>
                  <tr>
                    <td>銀行地址</td>
                    <td />
                    <td>香港德輔道中61-65號</td>
                  </tr>
                  <tr>
                    <td>銀行編號</td>
                    <td />
                    <td>018</td>
                  </tr>
                  <tr>
                    <td>國際代碼</td>
                    <td>SWIFT Code</td>
                    <td>KWHKHKHH</td>
                  </tr>
                  <tr>
                    <td>受款人提示訊息</td>
                    <td />
                    <td>請填寫您的英文全名及交易帳戶號碼。</td>
                  </tr>
                </table>
                {/* <h4>幣種</h4>
            <h5 className="floatText"><span>USD</span>
              <div className="content">以美元為結算貨幣的外匯保證金帳戶收款銀行</div>
            </h5>
            <h4>收款銀行資料</h4>
            <h5 className="floatText"><span>收款行名稱</span>
              <div className="content">Bank of China (Hong Kong)</div>
            </h5>
            <h5 className="floatText"><span>收款行地址</span>
              <div className="content">Bank of China Tower, 1 Garden Road, Central, Hong Kong</div>
            </h5>
            <h5 className="floatText"><span>收款行的 Swift 代碼</span>
              <div className="content">BKCHHKHHXXX</div>
            </h5>
            <h5 className="floatText"><span>收款人</span>
              <div className="content">KVB Kunlun International (HK) Limited - Clients’ A/C</div>
            </h5>
            <h5 className="floatText"><span>收款人帳號</span>
              <div className="content">012-875-9-263883-9</div>
            </h5>
            <div className="noteBox">
              <div className="alertNote">* 美元結算帳戶若以其它外幣存入款項，請與我們的客戶服務人員聯絡。</div>
            </div> */}
              </div>
            </div>
          </section>
        </>
      )}
      {props.id === "withdrawal" && (
        <section className="mainSection inPage" name="pos_2">
          <div className="customContainer">
            <div className="bodyBox">
              <div className="insideBox">
                <div className="kvbTitle">
                  <h3>取款</h3>
                </div>
                <p>香港取款須知</p>
              </div>
            </div>
            <div className="depositBox">
              <h3>1. 取款</h3>
              <h4>客戶如需取款，可通過以下方式提交取款申請：</h4>
              <h5 className="floatText">
                <span>電郵</span>
                <div className="content">
                  <a href={`mailto:${email}`}>{email}</a>
                </div>
              </h5>
              {/* <h4>通過電郵提交提款申請表，客戶必須在提款申請表親筆簽名。</h4> */}
            </div>
            <div className="depositBox">
              <h3>2. 取款流程</h3>
              <ul className="list dot">
                <li>
                  提款申請如在香港時間星期一至五中午12時前提交（公衆假期除外）將會在當天處理，否則將會在下一個工作天處理。
                </li>
                <li>
                  如取款在MT5交易帳戶扣除後，資金一般可在當天或下一個工作天到帳客戶指定的本地銀行戶口
                  。
                </li>
                <li>
                  取款申請能否處理需視乎帳戶的可用保證金是否足夠／客戶銀行戶口是否正確。
                </li>
              </ul>
            </div>
            <div className="depositBox">
              <h3>3. 取款方式</h3>
              <h4>轉帳或匯款</h4>
            </div>
            <div className="depositBox">
              <h3>4. 取款手續費</h3>
              <h4>CLSA Premium不收取客戶取款手續費。</h4>
              {/* <table>
              <thead>
                <tr>
                  <th>取款方式</th>
                  <th>帳戶貨幣</th>
                  <th>取款手續費</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>轉帳或匯款</td>
                  <td>美元</td>
                  <td><span className="red">CLSA Premium 不收取客戶取款手續費</span>，銀行取款手續費以收款銀行所在地為準, 請與我們的客戶服務人員聯絡了解詳情。</td>
                </tr>
                <tr>
                  <td>支票</td>
                  <td>美元</td>
                  <td><span className="red">$0</span>（請先與我們的客戶服務人員聯絡及客戶必需當天親身領取支票）</td>
                </tr>
              </tbody>
            </table> */}
            </div>
          </div>
        </section>
      )}
    </div>
  );
}
