import React from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Accordion from "./Accordion";

const AccordionBelt = ({ accordionProps, className = "", ...props }) => (
  <Container fluid className={`accordion-belt p-0 ${className}`} {...props}>
    <Row>
      <Col>
        <Container>
          <Accordion
            icon="caret-down"
            iconPosition="bottom"
            {...accordionProps}
          />
        </Container>
      </Col>
    </Row>
  </Container>
);

export default AccordionBelt;
