import React from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Buttons from "./Buttons";
import Paragraph from "./Paragraph";
import ScaleImage from "./ScaleImage";
import Title from "./Title";

const LeftBelt = ({
  bg = "white",
  brief = "",
  briefProps,
  buttons = [],
  buttonsProps,
  buttonProps,
  className = "",
  colProps,
  leftColProps,
  rightColProps,
  image,
  imageProps,
  title,
  titleProps,
  ...props
}) => (
  <Container className={`left-belt bg-${bg} p-0 ${className}`} {...props}>
    <Row noGutters>
      <Col lg={6} {...colProps} {...leftColProps}>
        <ScaleImage
          fluid
          alt={image}
          className="w-100"
          src={image}
          {...imageProps}
        />
      </Col>
      <Col lg={6} {...colProps} {...rightColProps}>
        <div
          className={`bg-${bg} d-flex flex-column h-100 justify-content-center px-4 py-3`}
        >
          <div className="mx-0 mx-xl-5 px-0 px-xl-5">
            <Title as="h2" {...titleProps}>
              {title}
            </Title>
            <Paragraph {...briefProps}>{brief}</Paragraph>
            <Buttons
              buttonProps={buttonProps}
              config={buttons}
              {...buttonsProps}
            />
          </div>
        </div>
      </Col>
    </Row>
  </Container>
);

export default LeftBelt;
