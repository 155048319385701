import _get from "lodash/get";
import _isNumber from "lodash/isNumber";
import _isPlainObject from "lodash/isPlainObject";
import _map from "lodash/map";
import _size from "lodash/size";
import _slice from "lodash/slice";
import numeral from "numeral";
import React from "react";
import BsTable from "react-bootstrap/Table";
import styled from "styled-components";
import useResponsive from "../hooks/useResponsive";
import Text from "./Text";

const StyledTable = styled(BsTable)`
  border-collapse: collapse;
`;

const StyledFirstTD = styled.td`
  @media (min-width: 768px) {
    border-left: ${({ border: { width = 0 }, theme: { spacer, white } }) =>
      `calc(${spacer} * ${width}) solid ${white}`};
  }
`;

const StyledLastTD = styled.td`
  @media (min-width: 768px) {
    border-right: ${({ border: { width = 0 }, theme: { spacer, white } }) =>
      `calc(${spacer} * ${width}) solid ${white}`};
  }
`;

const Table = ({
  body = [],
  bodyCellProps: { className: bodyCellClassName = "", ...bodyCellProps } = {},
  className = "",
  gutter: {
    bottom: bottomGutter = "0",
    left: leftGutter = "auto",
    right: rightGutter = "auto",
  } = {},
  head = [],
  headCellProps: { className: headCellClassName = "", ...headCellProps } = {},
  numberFormat = "0.00",
  page = 1,
  pagesize,
  ...props
}) => {
  const { isTabletOrMobile, isNotMobile } = useResponsive();
  const values = _map(head, "value");
  const length = _size(values) - 1;
  return (
    <StyledTable
      className={`overflow-hidden ${className}`}
      responsive={isTabletOrMobile}
      {...props}
    >
      <thead>
        <tr>
          {_map(head, ({ label, value }, key) => {
            const { col, ...props } = _isPlainObject(value)
              ? value
              : { col: value };
            return (
              <th
                className={`${key}-index-${col}-col ${
                  isNotMobile
                    ? key === 0
                      ? `pl-${leftGutter}`
                      : key === length
                      ? `pr-${rightGutter}`
                      : ""
                    : ""
                } ${headCellClassName}`}
                key={key}
                {...headCellProps}
                {...props}
              >
                <Text>{label}</Text>
              </th>
            );
          })}
        </tr>
      </thead>
      <tbody>
        {_map(
          pagesize
            ? _slice(body, (page - 1) * pagesize, page * pagesize)
            : body,
          (row, rowIndex) => (
            <tr className={`${rowIndex}-index-row`} key={rowIndex}>
              {_map(values, (value, key) => {
                const { col, ...props } = _isPlainObject(value)
                  ? value
                  : { col: value };
                const Tag =
                  key === 0
                    ? StyledFirstTD
                    : key === length
                    ? StyledLastTD
                    : "td";
                return (
                  <Tag
                    className={`${key}-${col}-col ${
                      rowIndex === 0 ? "border-top-0 pt-4" : ""
                    } ${bodyCellClassName}`}
                    key={key}
                    {...(key === 0
                      ? { border: { width: leftGutter } }
                      : key === length
                      ? { border: { width: rightGutter } }
                      : {})}
                    {...bodyCellProps}
                    {...props}
                  >
                    <Text font={{ size: "sm" }}>
                      {((value) =>
                        _isNumber(value)
                          ? numeral(value).format(numberFormat)
                          : value)(_get(row, col))}
                    </Text>
                  </Tag>
                );
              })}
            </tr>
          )
        )}
        {/* Placeholder to let bootstrap add border-bottom to :last-child */}
        <tr className="bottom-row">
          <td
            className={`bottom-row-col p-${bottomGutter} ${bodyCellClassName}`}
            colSpan={999}
            {...bodyCellProps}
          />
        </tr>
      </tbody>
    </StyledTable>
  );
};

export default Table;
