import _has from "lodash/has";
import _isString from "lodash/isString";
import Markdown from "markdown-to-jsx";
import React from "react";
import styled from "styled-components";

const StyledSpan = styled.span`
  color: ${({ color, theme }) => theme[color]};
  font-family: ${({ font: { family = "text" } = {}, theme }) =>
    _has(theme, `font-family-${family}`)
      ? theme[`font-family-${family}`]
      : theme["font-family-text"]};
  font-size: ${({ as, font: { size } = {}, theme }) =>
    as && _has(theme, `${as}-font-size`)
      ? theme[`${as}-font-size`]
      : size && _has(theme, `font-size-${size}`)
      ? theme[`font-size-${size}`]
      : theme["font-size-base"]};
  opacity: ${({ opacity }) => opacity || 1};
`;

const StyledA = styled.a`
  text-decoration: underline;
`;

const Text = ({ className = "", children = "", ...props }) => (
  <StyledSpan className={`text ${className}`} {...props}>
    {_isString(children) ? (
      <Markdown
        options={{
          overrides: {
            a: {
              component: StyledA,
              props: { className },
            },
          },
        }}
      >
        {children || ""}
      </Markdown>
    ) : (
      children
    )}
  </StyledSpan>
);

export default Text;
