import React, { useState } from 'react';

export default function HKPolicyEN(){
	return (
	<div className="text-justify">


<h1> Privacy Policy </h1>

<p><strong><u>PRIVACY POLICY FOR CLSA PREMIUM INTERNATIONAL (HK) LIMITED</u></strong></p>
<p>&nbsp;</p>
<p>CLSA Premium International (HK) Limited (&ldquo;CLSA Premium HK&rdquo;) may from time to time collect personal information from you as client or as prospective client. &nbsp;&nbsp;</p>
<p>&nbsp;</p>
<p>Protecting your privacy is important to us. We ask that you read this privacy policy carefully as it contains important information about what to expect when we collect personal data about you and how we will use your personal data. Your provision of personal data is voluntary.</p>
<p>&nbsp;</p>
<p>However, if you do not provide us with your personal data, we may not be able to provide you with the services and products requested.</p>
<p>&nbsp;</p>
<p><strong>Types of Personal Data</strong></p>
<p><strong>&nbsp;</strong></p>
<p>Generally, we may collect personal data that is linked to an individual. For example, an individual&rsquo;s name, address, phone number and email address. This is not an exhaustive list and we may collect other types of personal data from you.</p>
<p>&nbsp;</p>
<p>Apart from personal data, we may collect other types of information which is not linked to an individual and which is anonymous. For example, the number of website visitors and the number of website users using a particular service. In this way, we hope to improve our customer services.</p>
<p>&nbsp;</p>
<p><strong>Purpose for Collection and Use of Personal Data</strong></p>
<p><strong>&nbsp;</strong></p>
<p>Generally, personal data collected will be used for the following purposes:</p>
<p>&nbsp;</p>
<ol>
<li>account opening, account maintenance and operations relating to your account including closing your account;</li>
<li>providing services and products to you from time to time;</li>
<li>administration and/or managing the relationship between us and / or your account;</li>
<li>conducting identity and/or credit checks;</li>
<li>assisting other financial institutions to conduct credit checks and other group companies within CLSA Premium Group;</li>
<li>internal audit and planning;</li>
<li>determining the amount of indebtedness owned to or by customers;</li>
<li>collection of amounts outstanding from customers and those providing security for customers&rsquo; obligations;</li>
<li>in connection with any system or facility for payment by electronic means or via other communication media in which CLSA Premium Group participates;</li>
<li>in connection with any verification or exchange of information;</li>
<li>developing new services and/or products;</li>
<li>providing you with marketing, advertising and promotional information, materials and/or documents relating to our products and/or services;</li>
<li>complying with any present or future requirements or commitment (whether statutory, contractual or otherwise) of any legal, regulatory, governmental, tax, law enforcement or other authorities within or outside Hong Kong;</li>
<li>updating you on our products and services from time to time;</li>
<li>processing any applications or requests for new services and / or products made by you;</li>
<li>enforcing our legal and/or contractual rights against you; and</li>
<li>all other incidental and associated purposes relating to the above and any other purpose which a customer may from time to time agree.</li>
</ol>
<p>&nbsp;</p>
<p>&ldquo;CLSA Premium Group&rdquo; means for the purpose of this privacy policy, any entity controlled, directly or indirectly by CLSA Premium HK, any entity that controls, directly or indirectly CLSA Premium HK or any entity directly or indirectly under common control with CLSA Premium HK. For this purpose, &ldquo;control&rdquo; of any entity or person means ownership of a majority of the voting power of the entity or person.</p>
<p>&nbsp;</p>
<p><strong><u>Disclosure of Personal Data</u></strong></p>
<p>We may disclose your personal data to other group companies of CLSA Premium Group, third party service providers (including those providing administrative, telecommunications, computer, financial intermediary, payment or securities clearing or other services to CLSA Premium HK in connection with the operation of its business), financial institutions with which you have or propose to have dealings, any actual or proposed assignee of CLSA Premium HK or participant or sub-participant or transferee of CLSA Premium HK&rsquo;s rights in respect of the customer, any potential investor in or purchaser of any interest in any group companies of CLSA Premium Group or in its business in connection with any potential reorganisation, merger or disposal, credit reference agencies, debt collection agencies, charity or charitable organisation, our professional advisers and agents and legal, regulatory, governmental, tax, law enforcement or other authorities pf applicable jurisdictions for the purposes stated above.</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<p>In addition, there are certain circumstances under applicable laws where we are permitted to collect, use and disclose your personal data without your consent. You may obtain more information by contacting us.</p>
<p>&nbsp;</p>
<p>We will not sell, rent or otherwise disclose your personal data to any third party not referred to above without your consent except in the following circumstances:</p>
<p>&nbsp;</p>
<ol>
<li>we may share non-personal, non-individual information in aggregate form with third parties for business purposes, for example, we may tell our business partners the number of customers in certain demographic groups who purchased certain products or who carried out certain transactions.</li>
<li>as permitted under the laws of Hong Kong.</li>
</ol>
<p>&nbsp;</p>
<p>&nbsp;</p>
<p><strong><u>Transfer Of Personal Data Overseas</u></strong></p>
<p>To the extent that we may need to transfer personal data outside of an applicable jurisdiction, whether to the permissible parties set out above or simply to data storage facilities, we shall do so in accordance with the obligations required under the Personal Data Privacy Ordinance (&ldquo;PDPO&rdquo;).&nbsp;</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<p><strong><u>Consent</u></strong></p>
<p>In some circumstances and as required by applicable laws, we will seek your express consent when collecting your personal data. For example, we will seek your consent where there are new purposes for the use of your personal data or where we use and transfer your personal data for direct marketing purposes. In addition, you are able to withdraw your consent at any time by contacting us.</p>
<p>&nbsp;</p>
<p><strong><u>Use and Transfer of Personal Data in Direct Marketing</u></strong></p>
<p>&nbsp;</p>
<p>Where in circumstances where we may share your personal data with any other companies within the group companies of CLSA Premium Group or our business partners who provide the same products and services for direct marketing purposes. Your consent is required before we do so.</p>
<p>&nbsp;</p>
<p><strong><u>Access And Correction</u></strong></p>
<p>You are entitled to certain access and correction rights to your personal data under the PDPO. In exceptional circumstances, we reserve the right to deny you access to your personal data and may provide an explanation as required under the PDPO.</p>
<p>&nbsp;</p>
<p><strong><u>Retention</u></strong></p>
<p>Personal data will be held for as long as it is necessary to fulfil the purpose for which it was collected, or as required or permitted by applicable laws. We shall cease to retain personal data, or remove the means by which the personal data can be associated with particular individuals, as soon as it is reasonable to conclude that the purpose for which that personal data was collected is no longer being served by retention of the personal data and retention is no longer necessary for legal or business purposes.</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<p><strong><u>Accuracy</u></strong></p>
<p>In order to ensure that your personal data is current, complete and accurate, please update us by contacting us.</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<p><strong><u>Security Safeguards</u></strong></p>
<p><strong>&nbsp;</strong></p>
<p>We have put in place adequate security procedures and technical and organisational measures to safeguard your personal data. We will use all reasonable efforts to safeguard your personal data. However, you should be aware that the use of the internet is not entirely secure and for this reason we cannot guarantee the security or integrity of any personal data which is transferred from you or to you via the internet.</p>
<p>&nbsp;</p>
<p><strong><u>Updates To The Privacy Policy</u></strong></p>
<p>&nbsp;</p>
<p>We keep our privacy policy under regular review. If we change our privacy policy, we will post the changes on our website. Such changes to our privacy policy shall apply from the time that we post the same on this website. We will seek your fresh consent if we wish to use or disclose your personal data for new purposes.</p>
<p>&nbsp;</p>
<p><strong><u>Cookies</u></strong></p>
<p>A cookie is an alphanumeric identifier which we transfer to your hard drive through your web browser when you visit our website. It enables our own system to recognise you when you visit our website again and improve our service to you. The information is used to track visitor use of the website and to compile statistical reports on website activity. For further information about cookies visit www.aboutcookies.org or www.allaboutcookies.org. Cookies may also be used to compile aggregate information about areas of our website that are visited most frequently. This traffic information can be used to enhance the content of our website and make your use of it easier. If you wish to reject our cookie, you can configure your browser to do so. However, in a few cases some of our website features may not function if you remove cookies from your browser.</p>
<p><strong><u>Contact Us</u></strong></p>
<p>&nbsp;</p>
<p>If you any comments or questions about this Privacy Policy, please contact us in writing at the address below referencing &lsquo;Privacy Policy&rsquo;:</p>
<p>&nbsp;</p>
<p>Compliance Officer<br /> Suite 804, Level 8,
<br />One Pacific Place,
<br />88 Queensway,
<br />Hong Kong<br /> Tel: + 852 2600 7688</p>
<p>&nbsp;</p>
<p><strong><u>Disclaimer</u></strong></p>
<p><strong>&nbsp;</strong></p>
<p>CLSA Premium HK Web Site may contain links to other websites. Please note that CLSA Premium HK is not responsible for the privacy practices of such other websites and you are advised to read the privacy statement of each website you visit which may collect your personal data.</p>
</div>
)
}