import React, { useState,useEffect } from 'react';
import { useTranslation, Trans } from "react-i18next";
import { Tabs, Tab } from 'react-bootstrap'
import { Helmet } from "react-helmet";

import Banner from '../components/Banner'

import HKEN from '../components/depositAndWithdrawal/HKEN'
import HKCN from '../components/depositAndWithdrawal/HKCN'
import HKTC from '../components/depositAndWithdrawal/HKTC'

import BullionEN from '../components/depositAndWithdrawal/BullionEN'
import BullionCN from '../components/depositAndWithdrawal/BullionCN'
import BullionTC from '../components/depositAndWithdrawal/BullionTC'

import AUEN from '../components/depositAndWithdrawal/AUEN'
import AUCN from '../components/depositAndWithdrawal/AUCN'
import AUTC from '../components/depositAndWithdrawal/AUTC'

import NZMarginEN from '../components/depositAndWithdrawal/NZMarginEN'
import NZMarginCN from '../components/depositAndWithdrawal/NZMarginCN'
import NZMarginTC from '../components/depositAndWithdrawal/NZMarginTC'

import NZSecuritiesEN from '../components/depositAndWithdrawal/NZSecuritiesEN'
import NZSecuritiesCN from '../components/depositAndWithdrawal/NZSecuritiesCN'
import NZSecuritiesTC from '../components/depositAndWithdrawal/NZSecuritiesTC'

import AUDeposit from '../components/depositAndWithdrawal/AUDeposit'

export default function DepositAndWithdrawalPage({match}) {

  const [lang, setLang] = useState('English');
  const { t, i18n } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className='content'>
      <Helmet>
        <title>{t("dw_title", {
          context: match.params.sub,
          param: t(
            match.params.sub === "au"
              ? "australia"
              : match.params.sub === "nz"
              ? "new_zealand"
              : null
          )
        })}</title>
        {match.params.sub === 'au' &&
          <meta name="description" content={t("dw_description_au")}></meta>
        }
      </Helmet>
      <Banner title={t('deposit_withdraw')} bg={'deposit_withdraw'} />
      <section className='container deposit-withdrawal'>
        <Tabs defaultActiveKey={match.params.sub} id="uncontrolled-tab-example" className='subsidiary'>
          <Tab eventKey="bullion" title={t('hong_kong', { context: match.params.sub })}>
            {i18n.language === 'cn' ? (
              <BullionCN id={match.params.id} sub={match.params.sub} />
              ) : (
              i18n.language === 'tc' ? (
                <BullionTC id={match.params.id} sub={match.params.sub} />
                ) : (
                <BullionEN id={match.params.id} sub={match.params.sub} />
              )
            )}
          </Tab>
          <Tab eventKey="hk" title={t('hong_kong')} >
            {i18n.language === 'cn' ? (
              <HKCN id={match.params.id} />
              ) : (
              i18n.language === 'tc' ? (
                <HKTC id={match.params.id} />
                ) : (
                <HKEN id={match.params.id} />
              )
            )}
          </Tab>
          <Tab eventKey="au" title={t('australia')}>
            <AUDeposit id={match.params.id}/>
          </Tab>
          <Tab eventKey="nz" title={t('nz_margin')}>
            {i18n.language === 'cn' ? (
              <NZMarginCN />
              ) : (
              i18n.language === 'tc' ? (
                <NZMarginTC />
                ) : (
                <NZMarginEN />
              )
            )}

          </Tab>
        </Tabs>
      </section>
    </div>
  );
}