import React from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import useTranslate from "../hooks/useTranslate";
import Annotation from "./Annotation";
import Helmet from "./Helmet";
import Paragraph from "./Paragraph";
import ProductGuide from "./ProductGuide";
import ProductSidebar from "./ProductSidebar";
import Table from "./Table";
import Title from "./Title";
import TitleSplitter from "./TitleSplitter";

const Indices = ({ className = "", ...props }) => {
  const { config } = useTranslate({ configKey: "indices" });

  const {
    helmet: helmetProps,
    indices: indicesConfig,
    intro: introConfig,
    sidebar: sidebarProps,
    "swap-charge": swapChargeProps,
    "trade-time": tradeTimeConfig,
  } = config || {};

  return config ? (
    <Container className={`indices ${className}`} {...props}>
      <Helmet {...helmetProps} />
      <Row className="py-5">
        <Col lg={9}>
          {introConfig && (
            <Row className="intro mb-5">
              <Col>
                <Title>{introConfig.title}</Title>
                <TitleSplitter className="mb-4" color="success" />
                <Paragraph>{introConfig.brief}</Paragraph>
              </Col>
            </Row>
          )}
          {indicesConfig && (
            <Row className="indices mb-5" id="indices-indices">
              <Col>
                <Title as="h2" className="mb-4">
                  {indicesConfig.title}
                </Title>
                <div className="mb-5 shadow">
                  <Table
                    bodyCellProps={{
                      className: "text-center",
                    }}
                    gutter={{
                      bottom: 3,
                      left: 5,
                      right: 5,
                    }}
                    headCellProps={{
                      className:
                        "bg-primary border-0 font-weight-normal text-center text-white",
                    }}
                    numberFormat="0.000"
                    {...indicesConfig.table}
                  />
                </div>
                <Annotation
                  listProps={{
                    labelProps: { font: { size: "sm" } },
                    valueProps: { font: { size: "sm" } },
                  }}
                  titleProps={{ className: "text-primary" }}
                  {...indicesConfig.annotation}
                />
              </Col>
            </Row>
          )}
          {tradeTimeConfig && (
            <Row className="time mb-5" id="indices-time">
              <Col>
                <Title as="h2">{tradeTimeConfig.title}</Title>
                <div className="mb-3 shadow">
                  <Table
                    bodyCellProps={{
                      className: "text-center",
                    }}
                    gutter={{
                      bottom: 3,
                      left: 5,
                      right: 5,
                    }}
                    headCellProps={{
                      className:
                        "bg-primary border-0 font-weight-normal text-center text-white",
                    }}
                    {...tradeTimeConfig.table}
                  />
                </div>
              </Col>
            </Row>
          )}
          <Row className="swap mb-5" id="indices-swap">
            <Col>
              <ProductGuide {...swapChargeProps} />
            </Col>
          </Row>
        </Col>
        <Col lg={3}>
          <ProductSidebar {...sidebarProps} />
        </Col>
      </Row>
    </Container>
  ) : null;
};

export default Indices;
