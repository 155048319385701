import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faBookReader,
  faCheck,
  faCoins,
  faCubes,
  faDollarSign,
  faLandmark,
  faStopwatch,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import React from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import IconBelt from "../components/IconBelt";
import Layout from "../components/Layout";
import Paragraph from "../components/Paragraph";
import Title from "../components/Title";
import ContactForm from "../components/ContactForm";
import TitleSplitter from "../components/TitleSplitter";
import useResponsive from "../hooks/useResponsive";
import useTranslate from "../hooks/useTranslate";

library.add(
  faBookReader,
  faCheck,
  faCoins,
  faCubes,
  faDollarSign,
  faLandmark,
  faStopwatch,
  faTimes
);

const IBProgramPage = ({ className = "", ...props }) => {
  const { isNotMobile } = useResponsive();
  const { config } = useTranslate({ configKey: "ib-program" });

  const {
    banner: bannerProps,
    form: formProps,
    helmet: helmetProps,
    "icon-belt": IconBeltConfig,
    intro: introConfig,
  } = config || {};

  return (
    <Layout
      bannerProps={bannerProps}
      headerProps={{ bg: "dark" }}
      helmetProps={helmetProps}
    >
      {config ? (
        <Container className={`ib-program-page py-5 ${className}`} {...props}>
          <Row>
            <Col lg={8}>
              {introConfig &&
                (({ brief, title }) => (
                  <Row className="intro">
                    <Col>
                      <Title as="h2" display={isNotMobile ? 5 : 7}>
                        {title}
                      </Title>
                      <TitleSplitter className="mb-4" color="success" />
                      <Paragraph className="text-justify">{brief}</Paragraph>
                    </Col>
                  </Row>
                ))(introConfig)}
              {IconBeltConfig && (
                <IconBelt
                  bg="light"
                  iconBriefProps={{ className: "text-justify" }}
                  {...IconBeltConfig}
                />
              )}
            </Col>
            <Col lg={4}>
              <ContactForm {...formProps} />
            </Col>
          </Row>
        </Container>
      ) : null}
    </Layout>
  );
};

export default IBProgramPage;
