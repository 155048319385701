import React from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import useTranslate from "../hooks/useTranslate";
import Annotation from "./Annotation";
import DetailList from "./DetailList";
import Helmet from "./Helmet";
import Table from "./Table";
import Title from "./Title";

const PreciousMetals = ({ className = "", ...props }) => {
  const { config } = useTranslate({ configKey: "precious-metals" });

  const {
    detail: detailConfig,
    helmet: helmetProps,
    spec: specConfig,
  } = config || {};

  return config ? (
    <Container fluid className={`precious-metals ${className}`} {...props}>
      <Helmet {...helmetProps} />
      <Row>
        <Col>
          {(({ list, title }) => (
            <Row className="product-spec py-5">
              <Container>
                <Row>
                  <Col>
                    <Title as="h2" className="font-weight-bold mb-3">
                      {title}
                    </Title>
                    <DetailList
                      className="px-0"
                      data={list}
                      labelProps={{
                        className: "border-0 font-weight-bold pl-0 text-info",
                      }}
                      valueProps={{
                        active: false,
                        className: "border-0 font-weight-bold shadow-none",
                      }}
                    />
                  </Col>
                </Row>
              </Container>
            </Row>
          ))(specConfig)}
          {(({ annotation, table, title }) => (
            <Row className="product-detail bg-light py-5">
              <Container>
                <Row>
                  <Col>
                    <Title as="h2" className="font-weight-bold mb-4">
                      {title}
                    </Title>
                    <div className="mb-5">
                      <Table
                        bodyCellProps={{
                          className: "border-0",
                        }}
                        headCellProps={{
                          className: "border-0",
                        }}
                        numberFormat="0.00"
                        {...table}
                      />
                    </div>
                    <Annotation
                      listProps={{
                        labelProps: { font: { size: "sm" } },
                        lineProps: { className: "mb-4" },
                        valueProps: { font: { size: "sm" } },
                      }}
                      titleProps={{ className: "text-primary" }}
                      {...annotation}
                    />
                  </Col>
                </Row>
              </Container>
            </Row>
          ))(detailConfig)}
        </Col>
      </Row>
    </Container>
  ) : null;
};

export default PreciousMetals;
