/** @format */

import _isPlainObject from "lodash/isPlainObject";
import _map from "lodash/map";
import React from "react";
import useLink from "../hooks/useLink";
import useSubsidiary from "../hooks/useSubsidiary";
import useTranslate from "../hooks/useTranslate";
import Button from "./Button";
import ScaleImage from "./ScaleImage";

const Buttons = ({
  buttonProps: { className: buttonClassName = "", ...buttonProps } = {},
  className = "",
  config = [],
  data = [],
  ...props
}) => {
  const { isExternal } = useLink();
  const { subsidiary } = useSubsidiary();
  const { language } = useTranslate();
  return (
    <div className={`btns d-flex flex-wrap ${className}`} {...props}>
      {_map(config || data, ({ label, value }, key) => {
        const { image, link } = _isPlainObject(value) ? value : { link: value };
        return (
          <Button
            className={`${key}-btn m-1 ${buttonClassName}`}
            href={
              isExternal(link) ? link : `/${language}/${subsidiary}/${link}`
            }
            key={key}
            {...buttonProps}
          >
            {image ? (
              <ScaleImage alt="label" className="w-100" src={image} />
            ) : (
              label
            )}
          </Button>
        );
      })}
    </div>
  );
};

export default Buttons;
