import _map from "lodash/map";
import React from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Annotation from "../components/Annotation";
import Buttons from "../components/Buttons";
import DescriptionList from "../components/DescriptionList";
import Layout from "../components/Layout";
import Paragraph from "../components/Paragraph";
import Text from "../components/Text";
import Title from "../components/Title";
import useResponsive from "../hooks/useResponsive";
import useTranslate from "../hooks/useTranslate";

const AccountOpeningPage = ({ className = "", ...props }) => {
  const { isNotMobile } = useResponsive();
  const { config } = useTranslate({ configKey: "account-open" });

  const {
    banner: bannerProps,
    contact: contactConfig,
    helmet: helmetProps,
    intro: introConfig,
    individual: individualConfig,
    company: companyConfig,
  } = config || {};

  return (
    <Layout
      bannerProps={bannerProps}
      headerProps={{ bg: "dark" }}
      helmetProps={helmetProps}
    >
      {config ? (
        <Container className={`account-open-page ${className}`} {...props}>
          {introConfig &&
            (({ title }) => (
              <Row className="intro mt-5">
                <Col>
                  <Title
                    as="h2"
                    className="font-weight-bold"
                    display={isNotMobile ? 6 : 8}
                  >
                    {title}
                  </Title>
                </Col>
              </Row>
            ))(introConfig)}
          {individualConfig &&
            (({ list, title }) => (
              <div className="individual-account mb-3">
                <Title
                  as="h3"
                  className="font-italic font-weight-bold text-info"
                  display={8}
                >
                  {title}
                </Title>
                {_map(
                  list,
                  ({ annotation, brief, buttons, list, title }, key) => (
                    <div className="mb-4" key={key}>
                      <Title
                        as="h6"
                        className="font-weight-bold"
                        font={{ family: "text" }}
                      >
                        {`${key + 1}. ${title}`}
                      </Title>
                      <Paragraph>{brief}</Paragraph>
                      <ol>
                        {_map(list, (value, key) => (
                          <li>
                            <Text key={key}>{value}</Text>
                          </li>
                        ))}
                      </ol>
                      <Annotation {...annotation} />
                      <Buttons
                        buttonProps={{
                          className: `px-5 py-3 ${
                            isNotMobile ? "w-auto" : "w-100"
                          }`,
                        }}
                        className="ml-4 mt-4"
                        config={buttons}
                      />
                    </div>
                  )
                )}
              </div>
            ))(individualConfig)}
          {companyConfig &&
            (({ brief, buttons, title }) => (
              <div className="company-account mb-3">
                <Title as="h3" className="font-italic text-info" display={8}>
                  {title}
                </Title>
                <Paragraph>{brief}</Paragraph>
                <Buttons
                  buttonProps={{
                    className: `px-5 py-3 ${isNotMobile ? "w-auto" : "w-100"}`,
                  }}
                  className="ml-4 mt-4"
                  config={buttons}
                />
              </div>
            ))(companyConfig)}
          {contactConfig &&
            (({ list, title }) => (
              <Row className="intro my-5">
                <Col>
                  <Title
                    as="h2"
                    className="font-weight-bold"
                    display={isNotMobile ? 6 : 8}
                  >
                    {title}
                  </Title>
                  <DescriptionList
                    className="mb-3"
                    data={list}
                    labelProps={{
                      className: "font-weight-normal",
                    }}
                    valueProps={{
                      className: "font-weight-bold",
                    }}
                  />
                </Col>
              </Row>
            ))(contactConfig)}
        </Container>
      ) : null}
    </Layout>
  );
};

export default AccountOpeningPage;
