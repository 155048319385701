import _map from "lodash/map";
import React from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Annotation from "../components/Annotation";
import Layout from "../components/Layout";
import Paragraph from "../components/Paragraph";
import Text from "../components/Text";
import Title from "../components/Title";
import TitleSplitter from "../components/TitleSplitter";
import useResponsive from "../hooks/useResponsive";
import useTranslate from "../hooks/useTranslate";

const LegalDocPage = ({ className = "", ...props }) => {
  const { isNotMobile } = useResponsive();
  const { config } = useTranslate({ configKey: "legal-doc" });

  const {
    annotation: annotationConfig,
    banner: bannerProps,
    documents: documentsConfig,
    helmet: helmetProps,
    intro: introConfig,
    notice: noticeConfig,
  } = config || {};

  return (
    <Layout
      bannerProps={bannerProps}
      headerProps={{ bg: "dark" }}
      helmetProps={helmetProps}
    >
      {config && (
        <Container className={`legal-doc-page py-5 ${className}`} {...props}>
          {introConfig &&
            (({ brief, title }) => (
              <Row className="intro mb-3">
                <Col className="text-center">
                  <Title as="h2" display={isNotMobile ? 4 : 6}>
                    {title}
                  </Title>
                  <Paragraph>{brief}</Paragraph>
                  <TitleSplitter className="mx-auto" color="success" />
                </Col>
              </Row>
            ))(introConfig)}
          {noticeConfig &&
            (({ brief, list }) => (
              <Row className="notice mb-3">
                <Col>
                  <Text>{brief}</Text>
                  <ol className="pl-3">
                    {_map(list, (value, key) => (
                      <Text as="li" key={key}>
                        {value}
                      </Text>
                    ))}
                  </ol>
                </Col>
              </Row>
            ))(noticeConfig)}
          {documentsConfig && (
            <Row className="documents mb-3">
              <Col>
                <ul>
                  {_map(documentsConfig, ({ label, value }, key) => (
                    <li key={key}>
                      <a href={value} rel="noopener noreferrer" target="_blank">
                        <Text display={isNotMobile ? 7 : 8}>{label}</Text>
                      </a>
                    </li>
                  ))}
                </ul>
              </Col>
            </Row>
          )}
          {annotationConfig && (
            <Row className="annotation mb-5">
              <Col className="text-center">
                <Annotation {...annotationConfig} />
              </Col>
            </Row>
          )}
        </Container>
      )}
    </Layout>
  );
};

export default LegalDocPage;
