/** @format */

import React, { useEffect, useState } from "react";
import Paragraph from "./Paragraph";
import PopupBox from "./PopupBox";
import Title from "./Title";

const WarningPopupBox = ({
  className = "",
  body,
  isShowOnce = false,
  title,
  ...props
}) => {
  const [status, setStatus] = useState("on");

  // Only show popup once
  useEffect(() => {
    if (isShowOnce) {
      const status = localStorage.getItem("warning_popup_status");
      if (!status) {
        localStorage.setItem("warning_popup_status", "on");
        setStatus("on");
      }
    }
  }, [isShowOnce, status]);

  return (
    <PopupBox
      className={`warning-popup ${className}`}
      status={status}
      onClose={() => setStatus("off")}
      {...props}
    >
      <Title
        as="h6"
        className="text-center text-dark text-weight-bold"
        font={{ family: "text" }}
      >
        {title}
      </Title>
      <Paragraph className="text-dark text-decoration-none">{body}</Paragraph>
    </PopupBox>
  );
};

export default WarningPopupBox;
