import React, { useState } from 'react';
import { useTranslation, Trans } from "react-i18next";
import { Helmet } from "react-helmet";


export default function DisclaimerPage({match}) {
  
  const { t, i18n } = useTranslation();

  return (
    <div className='content'>
      <Helmet>
        <title>{t("title")}</title>
      </Helmet>
      <section className='container disclaimer'>
        {match.params.id === 'au' && 
          <div>
<p><strong>RISK WARINING AND DISCLAIMER FOR CLSA PREMIUM PTY LIMITED</strong></p>
<p>&nbsp;</p>
<p>CLSA Premium Pty Limited (ABN: 28 101 829 467) is registered in Australia at Grosvenor Place, Level 35, 225 George Street, Sydney NSW 2000, and holds an Australian Financial Services License issued by the Australian Securities and Investments Commission (AFSL No: 226602) to carry on a financial services business in Australia.</p>
<p>&nbsp;</p>
<p>This website is owned and operated by CLSA Premium Pty Limited (&ldquo;<strong>CLSA Premium</strong>&rdquo;). The information contained on this website is general in nature and does not constitute advice or a recommendation to act upon the information or an offer. The information on this website does not take into account your personal objectives, circumstances, financial situations or needs. CLSA Premium provides only general advice and execution-only services. You are strongly recommended to seek independent professional advice before opening an account with us and/or acquiring our services/products.</p>
<p>&nbsp;</p>
<p>CLSA Premium does not accept applications from residents of Japan or the United States of America. This website is intended for persons located within Australia only and is not directed at any person in countries or jurisdictions where the offer of such financial products is not permitted or is unlawful.</p>
<p>&nbsp;</p>
<p>Before you decide whether or not to invest any products referred to on this website, being over the counter (OTC) derivatives, it is important for you to read and consider our Financial Services Guide (FSG), Product Disclosure Statement (PDS) and Client Agreement, and ensure that you fully understand the risks involved. Fees, charges and commissions apply.</p>
<p>&nbsp;</p>
<p>OTC derivatives, including margin foreign exchange contracts and contract for differences are leveraged products that carry a high level of risk to your capital. Trading is not suitable to everyone. You may incur losses that are substantially greater than your initial investment. You do not own, or have any rights to, the underlying assets which the OTC derivative is referable to. You should only trade with money you can afford to lose. There are also risks associated with online trading including, but not limited to, hardware and/or software failures, and disruptions to communication systems and internet connectivity.</p>
          </div>
        }
        {match.params.id === 'list' &&
          <div>
            <h3>
              CLSA Premium Disclaimer
            </h3>
            <h3>
              Legal and Regulatory Notices (Electronic Communications)
            </h3>
            <div className='text'>
            © 2020 CLSA Premium Limited. This communication is only for the use of the intended recipient and may contain information which is confidential, privileged or subject to copyright. If you receive this communication and are not the intended recipient, please contact the sender immediately. Thank you. <br /><br />

Electronic communications may contain computer viruses, and may also be intercepted, corrupted or delayed. CLSA Premium group of companies (“CLSA Premium”) does not accept any liability for electronic communications that have been altered in the course of delivery or that have not been delivered in a timely manner. By communicating with CLSA Premium through electronic communications you are taken to have accepted these risks.<br /><br />

This communication is subject to and incorporates the terms and conditions of use set out on the www.clsapremium.com website, and neither the communication nor any portion hereof may be reprinted, sold, resold, copied, reproduced, distributed, redistributed, published, republished, displayed, posted or transmitted in any form or media or by any means without the written consent of CLSA Premium.<br /><br />

The information, opinions and estimates herein are not directed at, or intended for distribution to or use by, any person or entity in any jurisdiction where doing so would be contrary to law or regulation or which would subject CLSA Premium to any additional registration or licensing requirement within such jurisdiction.<br /><br />

The information and statistical data herein have been obtained from sources we believe to be reliable. Such information has not been independently verified and we make no representation or warranty as to its accuracy, completeness or correctness. Any opinions or estimates herein reflect the judgment of CLSA Premium at the date of this communication and are subject to change at any time without notice.<br /><br />

This is not a solicitation or any offer to buy or sell. The information contained in this communication is for information purposes only and does not constitute any recommendation, representation, warranty or guarantee of performance. This is not intended to provide professional, investment or any other type of advice or recommendation and does not take into account the particular investment objectives, financial situation or needs of individual recipients. Before acting on any information in this communication, you should consider whether it is suitable for your particular circumstances and, if appropriate, seek professional advice, including tax advice. CLSA Premium does not accept any responsibility and cannot be held liable for any person's use of or reliance on the information and opinions contained herein. To the extent permitted by applicable securities laws and regulations, CLSA Premium accepts no liability whatsoever for any direct or consequential loss arising from the use of this publication/communication or its contents. 

            </div>
          </div>
        }
      </section>
    </div>
  );
}