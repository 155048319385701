import React, { useState } from 'react';

export default function GroupTermCN(){
	return (
	<div>
	

<h1> Terms and Conditions of Use </h1>

<p><strong> <u> Website Terms of Use </u> </strong></p>
<p>This web site is operated by CLSA Premium Limited (&ldquo; <strong> CLSA Premium </strong> &rdquo;) (Stock Code: 6877.HK), a company incorporated in Cayman Island and its licensed subsidiaries (collectively &ldquo; <strong> CLSA Premium Group of Companies </strong> &rdquo;) under the domain name "www.clsapremium.com"</p>
<p><strong> <u> Terms variations </u> </strong></p>
<p>By accessing the CLSA Premium Web Site, you agree you will be bound by the Terms of Use, notice, disclaimers and other notices on the CLSA Premium Web Site. The Terms of Use may be changed, revised or amended without any notice given to you and at sole discretion of CLSA Premium.</p>
<p>&nbsp;</p>
<p><strong> <u> Third Party website </u> </strong></p>
<p>CLSA Premium Web Site may contain links to other web sites operated by third parties. CLSA Premium assumes no responsibility for the content or maintenance of any such third party site and makes no warranties, express or implied, with respect to its content or otherwise. CLSA Premium does not endorse, or approve of the operators of such third Party web sites, or the content, information, graphics and material on those third party websites. Using information in the third party website is at your own risk.</p>
<p>&nbsp;</p>
<p><strong> <u> Not recommendation/solicitation </u> </strong> &nbsp;</p>
<p>Nothing herein shall constitutes or shall be deemed to constitute as an invitation or recommendation to acquire or dispose of or otherwise deal in shares of CLSA Premium. No information on the CLSA Premium website shall be construed as an offer to sell or the solicitation of an offer to buy shares of CLSA Premium.</p>
<p>Prior to making any investment, you should make your independent assessment as to whether a particular investment product is suitable for your investment needs. If unsure, you should seek independent legal, tax and financial advice before deciding to proceed on making any investment.</p>
<p><strong> <u> Privacy Policy </u> </strong></p>
<p>Your personal information may be collected by CLSA Premium by visiting the CLSA Premium Web Site. To the extent that you have provided CLSA Premium with any personal information on CLSA Premium Web Site, CLSA Premium will only use these personal data in accordance with the purposes set forth in the privacy policy [link to privacy policy ] and is committed to safeguarding the personal information collected.</p>
<p>&nbsp;</p>
<p><strong> <u> Copyright </u> </strong></p>
<p>All text, graphics, icons, photographs, videos, images, illustrations, compilations and other material on the CLSA Premium website are the copyright of CLSA Premium Group of Companies.</p>
<p>&nbsp;</p>
<p>Reproduction or other use of works retrieved from CLSA Premium Web Site in all forms, media and technologies now existing or hereafter developed is subject to the Copyright Ordinance (Chapter 528, Laws of Hong Kong) and equivalent legislation around the world. A single copy of material on the CLSA Premium Web Site may be downloaded and/or reprinted for non-commercial and personal use only and may not be distributed to third parties. Any downloaded or reprinted material may not be altered in any way and must contain the following copyright notice: &ldquo;&copy; CLSA Premium 2019 All rights reserved&rdquo; and or any other copyright notice contained on the downloaded material.</p>
<p>&nbsp;</p>
<p>No other permission is granted to you, or anyone acting on your behalf, to print, copy, reproduce, distribute, transmit, upload, download, store, display in public, alter, or modify the content of CLSA Premium Web Site. Any unauthorised downloading, re-transmission or other copying or modification of the content of the CLSA Premium Web Site or any other use than that described above is strictly prohibited by law.</p>
<p>&nbsp;</p>
<p>The name and the logo of CLSA Premium are proprietary trademarks of CLSA Premium and our licensors, the use of which without express written permission is strictly prohibited.</p>
<p>&nbsp;</p>
<p><strong> <u> Disclaimer of liability </u> </strong></p>
<p>The information and content contained in the Site have been produced and collated by CLSA Premium in accordance with its current practices and policies and with the benefit of information currently available to it. All reasonable efforts have been made to ensure the accuracy of the contents of the pages of the CLSA Premium Web Site at the time of preparation.</p>
<p>&nbsp;</p>
<p>The information publish on the CLSA Premium Web Site are for general information only and nothing on the CLSA Premium Website constitutes professional advice or any binding commitment upon CLSA Premium Group of Companies &nbsp;in relation to the availability of services provided by CLSA Premium Group of Companies.</p>
<p>&nbsp;</p>
<p>Notwithstanding the efforts made by CLSA Premium to ensure the accuracy of the CLSA Premium Web Site, CLSA Premium disclaims any responsibility or liability in respect to any use or reference to the CLSA Premium Web Site, and CLSA Premium does not warrant or guarantee the adequacy, accuracy or completeness of any information herein or that such information will be delivered in a timely or uninterrupted form. <strong> &nbsp; </strong></p>
<p><strong> &nbsp; </strong></p>
<p><strong> CLSA PREMIUM MAKES NO REPRESENTATION OR WARRANTY, WHETHER EXPRESS OR IMPLIED, OF ANY KIND WITH RESPECT TO THE SITE, ITS OPERATION AND PUBLICATIONS ON THIS SITE ARE PROVIDED &ldquo;AS IS&rdquo;. CLSA PREMIUM EXPRESSLY DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED, OF ANY KIND RELATING TO THE CLSA PREMIUM WEB SITE, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE, AND ANY OBLIGATION TO UPDATE ANY INFORMATION HEREIN AND ANY LIABILITY FOR INACCURACIES AND OMISSIONS IN SUCH INFORMATION. CLSA PREMIUM ACCEPTS NO LIABILITY FOR LOSSES OR DAMAGES WHICH MAY BE DIRECTLY OR INDIRECTLY SUSTAINED BY ANY VISITOR TO THE SITE OR OTHER PERSON WHO OBTAINS ACCESS TO THE PUBLICATIONS ON THE SITE, OR FOR ANY CLAIM BY ANY THIRD PARTY. </strong> <strong> &nbsp; </strong></p>
<p><strong> &nbsp; </strong></p>
<p>You agree that this exclusion of liability is comprehensive and applies to all damages of any kind including without limitation direct, indirect, compensatory, special, multiple, incidental, punitive and consequential.</p>
<p>&nbsp;</p>

<p><strong> <u> Severability </u> </strong></p>
<p>In the event where any provisions of the Terms of Use is held unenforceable then that provision or, if permissible, the relevant part of it, will be severed in respect of that jurisdiction. The remainder of the Terms of Use will have full force and effect and the validity or enforceability of that provision in any other jurisdiction is not affected.</p>
<p><strong> <u> Governing law and jurisdiction </u> </strong></p>
<p>By accessing the CLSA Premium Web Site and any of its content, you agree that any disputes shall be governed by the Laws of Hong Kong and to the non-exclusive jurisdiction of the Courts of Hong Kong in connection with any suit, action or proceeding arising out of or in connection with the use of the CLSA Premium Web Site.</p>

<p>&nbsp;</p>
<p><strong> <u> &nbsp; </u> </strong></p>

</div>
)
}