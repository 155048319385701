import React, { useState } from 'react';
import { useTranslation, Trans } from "react-i18next";


export default function BoardInfo() {
  const { t, i18n } = useTranslation();

  return (
  	<div className='board-info'>
      {/*<h1>{t('board_of_directors')}</h1>*/}
      <div className='block'>
        <div className='title'>{t('executive_directors')}</div>
        <div className='name'>{t('name1')}</div>
        <div className='intro text-justify'>{t('intro1')}</div>
        <div className='name'>{t('name2')}</div>
        <div className='intro text-justify'>{t('intro2')}</div>
      </div>
      <div className='block'>
        <div className='title'>{t('non_executive_directors')}</div>
        <div className='name'>{t('name3')}</div>
        <div className='intro text-justify'>{t('intro3')}</div>
        {/* <div className='name'>{t('name4')}</div>
        <div className='intro text-justify'>{t('intro4')}</div> */}
        <div className='name'>{t('name5')}</div>
        <div className='intro text-justify'>{t('intro5')}</div>
      </div>
      <div className='block'>
        <div className='title'>{t('inde_non_executive_directors')}</div>
        <div className='name'>{t('name6')}</div>
        <div className='intro text-justify'>{t('intro6')}</div>
        <div className='name'>{t('name7')}</div>
        <div className='intro text-justify'>{t('intro7')}</div>
        <div className='name'>{t('name8')}</div>
        <div className='intro text-justify'>{t('intro8')}</div>
      </div>
      <div className='block'>
      <div className='name'>{t('namedisc')}</div>
        <div className='intro text-justify'>{t('introdisc')}</div>
      </div>
      {/* <div className='block'>
        <div className='title'>{t('company_secretary')}</div>
        <div className='name'>{t('name9')}</div>
        <div className='intro text-justify'>{t('intro9')}</div>
      </div> */}
      {/*<div className='block'>
        <div className='title'>{t('senior_management')}</div>
        <div className='name'>{t('name10')}</div>
        <div className='intro text-justify'>{t('intro10')}</div>
      </div>*/}
    </div>
  )
}