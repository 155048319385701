import _join from "lodash/join";
import _map from "lodash/map";
import _replace from "lodash/replace";
import _slice from "lodash/slice";
import _split from "lodash/split";
import React from "react";
import BsBreadcrumb from "react-bootstrap/Breadcrumb";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import useTranslate from "../hooks/useTranslate";
import Text from "./Text";

const StyledBreadcrumb = styled(BsBreadcrumb)`
  .breadcrumb {
    background-color: unset;
    padding-left: 0;
  }
`;

const StyledBreadcrumbItem = styled(BsBreadcrumb.Item)`
  a:hover {
    text-decoration: none;
  }
  &:last-child {
    a {
      display: none;
    }
  }
`;

const Breadcrumb = ({ className = "", ...props }) => {
  const { pathname = "" } = useLocation();
  const { translate } = useTranslate();
  return (
    <StyledBreadcrumb className={className} {...props}>
      {_map(_split(pathname, "/"), (value, key, pathnames) =>
        value && !/^(cn|en|tc|au|bullion|hk|home|nz)$/.test(value) ? (
          <StyledBreadcrumbItem
            className={`${value}-breadcrumb-item`}
            href={_join(_slice(pathnames, 0, key + 1), "/") || "#"}
            key={key}
          >
            <Text
              className="text-capitalize text-white"
              font={{ family: "button" }}
              opacity={0.6}
            >
              {_replace(translate(value), /_/g, " ")}
            </Text>
          </StyledBreadcrumbItem>
        ) : null
      )}
    </StyledBreadcrumb>
  );
};

export default Breadcrumb;
