import React, { useState } from 'react';
import { useTranslation, Trans } from "react-i18next";


export default function Forex(props) {
  const { t, i18n } = useTranslation();

  const subsidiary = props.subsidiary
  const subParam = {param1: subsidiary === 'au' ? t('sub_au') : (subsidiary === 'hk' ? t('sub_hk') : t('sub_nz'))}

  return (
    <div className="forex">

      <div className='block'>
        <div className='title'><h2>{t('forex_spec')}</h2></div>
        <table className='spec mt-5'>
          <tbody>
          <tr><td>{t('contract_size', { context: subsidiary })}</td><td>{'100,000'}</td></tr>
          <tr><td>{t('min_tradable_lot')}</td><td>{t('min_tradable_lot_value')}</td></tr>
          {subsidiary === 'au' &&
            <tr><td>{t('max_tradable_lot')}</td><td>{t('max_tradable_lot_value')}</td></tr>
          }
          <tr><td>{t('min_incremental_lot')}</td><td>{t('min_incremental_lot_value')}</td></tr>
          {
            subsidiary === 'hk' ? (
              <tr><td>{t('required_margin')}</td><td>{t('margin_required_value_hk')}</td></tr>
            ) : subsidiary === 'au' ? (
              <tr><td>{t('margin_required')}</td><td>{t('margin_required_value_from_15th_march_onward')}</td></tr>
            ) : (
              <tr><td>{t('margin_required')}</td><td>{t('margin_required_value')}</td></tr>
            )
          }
          <tr><td>{t('nearest_level')}</td><td>{t('nearest_level_value')}</td></tr>
          <tr><td>{t('profit_loss_cal', { context: subsidiary })}</td><td>{t('profit_loss_cal_value')}</td></tr>

          <tr><td>{t('roll_over_swap', { context: subsidiary })}</td><td>{t('roll_over_swap_value', { context: subsidiary, ...subParam })}</td></tr>

          {subsidiary === 'au' ? (
              <tr><td>{t('trading_hour')}</td><td>{t('trading_hour_value_au')}</td></tr>
            ) : (
              <tr><td>{t('trading_hour')}</td><td>{t('trading_hour_value', { context: subsidiary })}</td></tr>
            )
          }
          </tbody>
        </table>
        {subsidiary === 'au' ? (
            <div className='comment mt-5'>
              <p><b>{t('forex_spec_comment_key_1')}</b> {t('forex_spec_comment_1')}</p>
              <p><b>{t('forex_spec_comment_key_2')}</b> {t('forex_spec_comment_2')}</p>
            </div>
          ) : null
        }
      </div>
      {subsidiary === 'au' ? (
        <div className='block fluc'>
          {/* <div className='title'><h2>{t('min_fluctuation_title')}</h2></div> */}
          <div className='row'>
            <div className='col-md-12 overflow-auto mt-5' style={{ height: "60vh"}}>
              <table className='spread'>
                <thead>
                  <tr>
                    <th className="pr-5 py-4 text-nowrap sticky-top">{t('currency_pair')}</th>
                    <th className="pr-5 py-4 text-nowrap sticky-top">{t('margin_required')}</th>
                    <th className="pr-5 py-4 text-nowrap sticky-top">{t('min_spread')}</th>
                    <th className="pr-5 py-4 text-nowrap sticky-top">{t('ave_spread')}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr><td className="pr-5">{'AUDCAD'}</td><td className="pr-5">{'3.33%'}</td><td className="pr-5">{'1.00'}</td><td className="pr-5">{'1.70'}</td></tr>
                  <tr><td className="pr-5">{'AUDCHF'}</td><td className="pr-5">{'3.33%'}</td><td className="pr-5">{'1.00'}</td><td className="pr-5">{'1.70'}</td></tr>
                  {/* <tr><td className="pr-5">{'AUDCNH'}</td><td className="pr-5">{'5%'}</td><td className="pr-5">{'16.00'}</td><td className="pr-5">{'20.20'}</td></tr> */}
                  <tr><td className="pr-5">{'AUDJPY'}</td><td className="pr-5">{'3.33%'}</td><td className="pr-5">{'1.00'}</td><td className="pr-5">{'1.70'}</td></tr>
                  <tr><td className="pr-5">{'AUDNZD'}</td><td className="pr-5">{'5%'}</td><td className="pr-5">{'1.00'}</td><td className="pr-5">{'2.00'}</td></tr>
                  <tr><td className="pr-5">{'AUDUSD'}</td><td className="pr-5">{'3.33%'}</td><td className="pr-5">{'0.60'}</td><td className="pr-5">{'0.90'}</td></tr>
                  <tr><td className="pr-5">{'CADJPY'}</td><td className="pr-5">{'3.33%'}</td><td className="pr-5">{'1.00'}</td><td className="pr-5">{'1.70'}</td></tr>
                  <tr><td className="pr-5">{'CHFJPY'}</td><td className="pr-5">{'3.33%'}</td><td className="pr-5">{'1.00'}</td><td className="pr-5">{'1.90'}</td></tr>
                  <tr><td className="pr-5">{'EURAUD'}</td><td className="pr-5">{'3.33%'}</td><td className="pr-5">{'1.00'}</td><td className="pr-5">{'1.80'}</td></tr>
                  <tr><td className="pr-5">{'EURCAD'}</td><td className="pr-5">{'3.33%'}</td><td className="pr-5">{'1.00'}</td><td className="pr-5">{'1.90'}</td></tr>
                  <tr><td className="pr-5">{'EURCHF'}</td><td className="pr-5">{'3.33%'}</td><td className="pr-5">{'1.00'}</td><td className="pr-5">{'1.60'}</td></tr>
                  <tr><td className="pr-5">{'EURGBP'}</td><td className="pr-5">{'3.33%'}</td><td className="pr-5">{'1.00'}</td><td className="pr-5">{'1.50'}</td></tr>
                  <tr><td className="pr-5">{'EURJPY'}</td><td className="pr-5">{'3.33%'}</td><td className="pr-5">{'1.00'}</td><td className="pr-5">{'1.40'}</td></tr>
                  <tr><td className="pr-5">{'EURNZD'}</td><td className="pr-5">{'5%'}</td><td className="pr-5">{'1.00'}</td><td className="pr-5">{'2.70'}</td></tr>
                  <tr><td className="pr-5">{'EURUSD'}</td><td className="pr-5">{'3.33%'}</td><td className="pr-5">{'0.60'}</td><td className="pr-5">{'0.90'}</td></tr>

                  <tr><td className="pr-5">{'GBPAUD'}</td><td className="pr-5">{'3.33%'}</td><td className="pr-5">{'1.00'}</td><td className="pr-5">{'2.50'}</td></tr>
                  <tr><td className="pr-5">{'GBPCAD'}</td><td className="pr-5">{'3.33%'}</td><td className="pr-5">{'1.40'}</td><td className="pr-5">{'2.80'}</td></tr>
                  <tr><td className="pr-5">{'GBPCHF'}</td><td className="pr-5">{'3.33%'}</td><td className="pr-5">{'1.00'}</td><td className="pr-5">{'2.30'}</td></tr>
                  <tr><td className="pr-5">{'GBPJPY'}</td><td className="pr-5">{'3.33%'}</td><td className="pr-5">{'1.00'}</td><td className="pr-5">{'2.00'}</td></tr>
                  <tr><td className="pr-5">{'GBPNZD'}</td><td className="pr-5">{'5%'}</td><td className="pr-5">{'1.00'}</td><td className="pr-5">{'3.40'}</td></tr>
                  <tr><td className="pr-5">{'GBPUSD'}</td><td className="pr-5">{'3.33%'}</td><td className="pr-5">{'0.80'}</td><td className="pr-5">{'1.20'}</td></tr>
                  <tr><td className="pr-5">{'NZDCAD'}</td><td className="pr-5">{'5%'}</td><td className="pr-5">{'1.00'}</td><td className="pr-5">{'1.80'}</td></tr>
                  <tr><td className="pr-5">{'NZDCHF'}</td><td className="pr-5">{'5%'}</td><td className="pr-5">{'1.00'}</td><td className="pr-5">{'1.70'}</td></tr>
                  <tr><td className="pr-5">{'NZDJPY'}</td><td className="pr-5">{'5%'}</td><td className="pr-5">{'1.00'}</td><td className="pr-5">{'1.60'}</td></tr>
                  <tr><td className="pr-5">{'NZDUSD'}</td><td className="pr-5">{'5%'}</td><td className="pr-5">{'0.60'}</td><td className="pr-5">{'1.00'}</td></tr>
                  <tr><td className="pr-5">{'USDCAD'}</td><td className="pr-5">{'3.33%'}</td><td className="pr-5">{'0.60'}</td><td className="pr-5">{'1.10'}</td></tr>
                  <tr><td className="pr-5">{'USDCHF'}</td><td className="pr-5">{'3.33%'}</td><td className="pr-5">{'0.60'}</td><td className="pr-5">{'1.10'}</td></tr>
                  <tr><td className="pr-5">{'USDCNH'}</td><td className="pr-5">{'5%'}</td><td className="pr-5">{'2.40'}</td><td className="pr-5">{'5.70'}</td></tr>
                  <tr><td className="pr-5">{'USDJPY'}</td><td className="pr-5">{'3.33%'}</td><td className="pr-5">{'0.60'}</td><td className="pr-5">{'0.80'}</td></tr>

                  <tr><td className="pr-5">{'USDSGD'}</td><td className="pr-5">{'5%'}</td><td className="pr-5">{'1.60'}</td><td className="pr-5">{'2.80'}</td></tr>
                  <tr><td className="pr-5">{'AUDSGD'}</td><td className="pr-5">{'5%'}</td><td className="pr-5">{'1.00'}</td><td className="pr-5">{'2.30'}</td></tr>
                  <tr><td className="pr-5">{'CADCHF'}</td><td className="pr-5">{'3.33%'}</td><td className="pr-5">{'1.00'}</td><td className="pr-5">{'1.80'}</td></tr>
                  <tr><td className="pr-5">{'EURSGD'}</td><td className="pr-5">{'5%'}</td><td className="pr-5">{'1.00'}</td><td className="pr-5">{'2.90'}</td></tr>
                  <tr><td className="pr-5">{'GBPSGD'}</td><td className="pr-5">{'5%'}</td><td className="pr-5">{'1.40'}</td><td className="pr-5">{'4.70'}</td></tr>
                  <tr><td className="pr-5">{'EURMXN'}</td><td className="pr-5">{'5%'}</td><td className="pr-5">{'18.00'}</td><td className="pr-5">{'102.00'}</td></tr>
                  <tr><td className="pr-5">{'EURNOK'}</td><td className="pr-5">{'5%'}</td><td className="pr-5">{'8.00'}</td><td className="pr-5">{'45.00'}</td></tr>
                  <tr><td className="pr-5">{'EURSEK'}</td><td className="pr-5">{'5%'}</td><td className="pr-5">{'14.00'}</td><td className="pr-5">{'40.00'}</td></tr>
                  <tr><td className="pr-5">{'EURTRY'}</td><td className="pr-5">{'10%'}</td><td className="pr-5">{'12.00'}</td><td className="pr-5">{'129.00'}</td></tr>
                  <tr><td className="pr-5">{'USDNOK'}</td><td className="pr-5">{'5%'}</td><td className="pr-5">{'4.00'}</td><td className="pr-5">{'37.00'}</td></tr>
                  <tr><td className="pr-5">{'USDMXN'}</td><td className="pr-5">{'5%'}</td><td className="pr-5">{'24.00'}</td><td className="pr-5">{'63.00'}</td></tr>
                  <tr><td className="pr-5">{'USDSEK'}</td><td className="pr-5">{'5%'}</td><td className="pr-5">{'4.00'}</td><td className="pr-5">{'26.00'}</td></tr>
                  <tr><td className="pr-5">{'USDTRY'}</td><td className="pr-5">{'10%'}</td><td className="pr-5">{'3.00'}</td><td className="pr-5">{'66.00'}</td></tr>
                  <tr><td className="pr-5">{'USDZAR'}</td><td className="pr-5">{'5%'}</td><td className="pr-5">{'18.00'}</td><td className="pr-5">{'92.00'}</td></tr>
                </tbody>
              </table>
            </div>
          </div>

        </div>
      ) : (
        <div className='block fluc'>
          <div className='title'><h2>{t('product_detail')}</h2></div>
          <div className='row'>
            <div className='col-md-12 overflow-auto mt-5' style={{ height: "60vh"}}>
              <table className='spread'>
                <thead>
                  <tr>
                    <th className="pr-5 py-4 text-nowrap sticky-top">{subsidiary === "hk" ? t("symbol") : t('currency_pair')}</th>
                    <th className="pr-5 py-4 text-nowrap sticky-top">{subsidiary === "hk" ? t("min_spread_star") : t('lowest_spread')}</th>
                    <th className="pr-5 py-4 text-nowrap sticky-top">{t('min_fluctuation')}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr><td>{'AUDCAD'}</td><td>{'2.20'}</td><td>{'0.00001'}</td></tr>
                  <tr><td>{'AUDCHF'}</td><td>{'2.00'}</td><td>{'0.00001'}</td></tr>
                  <tr><td>{'AUDJPY'}</td><td>{'1.80'}</td><td>{'0.001'}</td></tr>
                  <tr><td>{'AUDNZD'}</td><td>{'2.00'}</td><td>{'0.00001'}</td></tr>
                  <tr><td>{'AUDUSD'}</td><td>{'1.70'}</td><td>{'0.00001'}</td></tr>
                  <tr><td>{'CADJPY'}</td><td>{'1.60'}</td><td>{'0.001'}</td></tr>
                  <tr><td>{'CHFJPY'}</td><td>{'2.10'}</td><td>{'0.001'}</td></tr>
                  <tr><td>{'EURAUD'}</td><td>{'2.50'}</td><td>{'0.00001'}</td></tr>
                  <tr><td>{'EURCAD'}</td><td>{'2.20'}</td><td>{'0.00001'}</td></tr>
                  <tr><td>{'EURCHF'}</td><td>{'2.00'}</td><td>{'0.00001'}</td></tr>
                  <tr><td>{'EURGBP'}</td><td>{'1.60'}</td><td>{'0.00001'}</td></tr>
                  <tr><td>{'EURJPY'}</td><td>{'1.80'}</td><td>{'0.001'}</td></tr>
                  <tr><td>{'EURNZD'}</td><td>{'3.20'}</td><td>{'0.00001'}</td></tr>
                  <tr><td>{'EURUSD'}</td><td>{'1.30'}</td><td>{'0.00001'}</td></tr>
                  <tr><td>{'GBPAUD'}</td><td>{'2.70'}</td><td>{'0.00001'}</td></tr>
                  <tr><td>{'GBPCAD'}</td><td>{'2.70'}</td><td>{'0.00001'}</td></tr>
                  <tr><td>{'GBPCHF'}</td><td>{'2.60'}</td><td>{'0.00001'}</td></tr>
                  <tr><td>{'GBPJPY'}</td><td>{'1.60'}</td><td>{'0.001'}</td></tr>
                  <tr><td>{'GBPNZD'}</td><td>{'4.20'}</td><td>{'0.00001'}</td></tr>
                  <tr><td>{'GBPUSD'}</td><td>{'1.50'}</td><td>{'0.00001'}</td></tr>
                  <tr><td>{'NZDCAD'}</td><td>{'2.70'}</td><td>{'0.00001'}</td></tr>
                  <tr><td>{'NZDCHF'}</td><td>{'2.60'}</td><td>{'0.00001'}</td></tr>
                  <tr><td>{'NZDJPY'}</td><td>{'2.40'}</td><td>{'0.001'}</td></tr>
                  <tr><td>{'NZDUSD'}</td><td>{'2.10'}</td><td>{'0.00001'}</td></tr>
                  <tr><td>{'USDCAD'}</td><td>{'1.60'}</td><td>{'0.00001'}</td></tr>
                  <tr><td>{'USDCHF'}</td><td>{'1.70'}</td><td>{'0.00001'}</td></tr>
                  <tr><td>{'USDCNH'}</td><td>{'22'}</td><td>{'0.00001'}</td></tr>
                  <tr><td>{'USDJPY'}</td><td>{'1.20'}</td><td>{'0.001'}</td></tr>
                  <tr><td>{'USDSGD'}</td><td>{'2.00'}</td><td>{'0.00001'}</td></tr>
                  <tr><td>{'AUDSGD'}</td><td>{'4.2'}</td><td>{'0.00001'}</td></tr>
                  <tr><td>{'CADCHF'}</td><td>{'3.1'}</td><td>{'0.00001'}</td></tr>
                  <tr><td>{'EURSGD'}</td><td>{'4.5'}</td><td>{'0.00001'}</td></tr>
                  <tr><td>{'GBPSGD'}</td><td>{'4.6'}</td><td>{'0.00001'}</td></tr>
                  <tr><td>{'EURMXN'}</td><td>{'45.2'}</td><td>{'0.00001'}</td></tr>
                  <tr><td>{'EURNOK'}</td><td>{'10.6'}</td><td>{'0.00001'}</td></tr>
                  <tr><td>{'EURSEK'}</td><td>{'13.3'}</td><td>{'0.00001'}</td></tr>
                  <tr><td>{'EURTRY'}</td><td>{'45'}</td><td>{'0.00001'}</td></tr>
                  <tr><td>{'USDNOK'}</td><td>{'14.1'}</td><td>{'0.00001'}</td></tr>
                  <tr><td>{'USDMXN'}</td><td>{'31.8'}</td><td>{'0.00001'}</td></tr>
                  <tr><td>{'USDSEK'}</td><td>{'18.7'}</td><td>{'0.00001'}</td></tr>
                  <tr><td>{'USDTRY'}</td><td>{'27.8'}</td><td>{'0.00001'}</td></tr>
                  <tr><td>{'USDZAR'}</td><td>{'52.8'}</td><td>{'0.00001'}</td></tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}


    </div>
  )
}
