import React, { useState } from 'react';
import { useTranslation, Trans } from "react-i18next";
import { Link } from 'react-router-dom'
import { Helmet } from "react-helmet";

import Banner from '../components/Banner'

export default function LegalDocPage({match}) {

  const { t, i18n } = useTranslation();
  const subsidiary = match.params.id
  return (
    <div className='content'>
      <Helmet>
        <title>{t("ld_title", {
          param: t(
            subsidiary === "au"
              ? "australia"
              : subsidiary === "nz"
              ? "new_zealand"
              : null
          )
        })}</title>
        <meta name="description" content={t("ld_description_au")}></meta>
      </Helmet>
      <Banner bg={'legal'} title={t('legal_documents')} />
      <section className='container fsg'>
        {subsidiary === 'bullion' &&
          <div>
            <ul>
              <li><a href="/download/legal-doc/bullion/CLSAP_Bullion_Client_Services_Agreement_202105.pdf" target="_blank">{t('ld_csa')}</a></li>
            </ul>
          </div>
        }
        {subsidiary === 'hk' &&
          <div>
            <ul>
              <li><a href="/download/legal-doc/hk/CLSAPHK-Client_Services_Agreement_20220501.pdf" target="_blank">{t('ld_csa')}</a></li>
            </ul>
          </div>
        }
        {subsidiary === 'au' &&
          <div>
            <ul>
              <li><a href="/download/legal-doc/au/CLSA Premium Privacy Statement (040820).pdf" target="_blank">{t('privacy_statement')}</a></li>
              <li><a href="/download/legal-doc/au/CLSA Premium FSG.pdf" target="_blank">{t('financial_service_guide')}</a></li>
              <li><a href="/download/legal-doc/au/CLSA Premium PDS (050820).pdf" target="_blank">{t('product_disclosure_statement')}</a></li>
              <li><a href="/download/legal-doc/au/CLSA T&C (221020).pdf" target="_blank">{t('terms_of_use_au')}</a></li>
              <li><a href="/download/legal-doc/au/CLSA Premium Hedging Counterparties Policy (040820).pdf" target="_blank">{t('hedge_counterparty_policy')}</a></li>
            </ul>
          </div>
        }
        {subsidiary === 'nz' &&
          <div>
            <h2>{t('ld_terms_of_use')}</h2>
            <span>{t('ld_terms_of_use_nz_1')}</span>
            <ul>
              <li>
                <a href="/download/legal-doc/nz/07_Trading Star Facility Terms and Conditions.pdf" target="_blank">{t('ld_terms_of_use_nz_2')}</a>
              </li>

            </ul>

            <h2>{t('ld_other_nz')}</h2>
            <span>{t('ld_other_nz_1')}</span>
            <ul>
              <li><a href="/download/legal-doc/nz/05_Complaints Handling and Dispute Resolution Policy.pdf" target="_blank">{t('complaints_policy')}</a></li>
              <li><a href="/download/legal-doc/nz/06_Privacy Policy.pdf" target="_blank">{t('privacy_policy')}</a></li>
            </ul>

            <span>
              {t('ld_contact_us_nz_1')}
              <a href="mailto:services@clsapremium.com">services@clsapremium.com</a>
              {t('ld_contact_us_nz_2')}
            </span>
          </div>
        }
      </section>
    </div>
  );
}